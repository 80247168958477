import { CIVETWEB_ENTRYPOINT, PHP_ENTRYPOINT } from "../config/backend";
import { ARTECO_CAM_CODE } from '../actions/types';
import { timeoutPromise, getLanAddress } from "./network";
import { convertUTCToLocal, getCurrentServerTime, intervalOperations, serverTimeDiff, subtractInterval } from "./timeHelpers";
import { peripheralIsPresent } from "../reducers/layoutsReducer";
import { forceLogin } from "../actions/serverActions";

import { info, logger } from "./logger";
import { isCredentialEnabled } from "../reducers/serversReducer";
import { AuditingUserTracking } from "./serverCredentials";
import { INTEGRATIONS_PER_SUBTYPE_LIST } from "../components/dashboard/DeviceList/DeviceInfoVariables";

export function getServerCodenameByArtecoid(artecoId) {
  let codenameParts = artecoId.split('_');
  return codenameParts[0] || ''
}

export function addCapabilities(serverData, capabilities) {
  const updatedServerData = { ...serverData };
  updatedServerData.root.server.capabilities = capabilities;

  return updatedServerData;
}

export const SUPPORTED_ENCODERS = [2];
// const PROFILES_NAMES = {
//   0: "MAIN_PROFILE",
//   1: "SUB_PROFILE",
//   2: "THIRD_PROFILE"
// }

function orderByQuality(a, b) {
  if (a.quality < b.quality) {
    return -1;
  }
  if (a.quality > b.quality) {
    return 1;
  }
  return 0;
}

export const CHANNEL_PROFILES = (streams) => {
  const nSources = streams.length;

  let profiles = [];

  for (let i = 0; i < nSources; i++) {

    const currentStream = streams[i];

    const activeProfile = currentStream.activeProfile;

    if (activeProfile !== undefined) {

      const source = currentStream.profiles.find(profile => profile.id === activeProfile);

      if (source && SUPPORTED_ENCODERS.includes(source.encoder)) {
        profiles.push(
          {
            // name: PROFILES_NAMES[i],
            name: `${source.width}x${source.height}`,
            id: i,
            rtspStreamUrl: `streamrtsp${i + 1}`,
            codec: source.encoder,
            width: source.width,
            height: source.height,
            quality: source.width * source.height
          }
        )
      }
    }

  }

  const sortedProfiles = profiles.sort(orderByQuality).map((profile, index) => {
    return {
      ...profile,
      id: index
    }
  });
  return sortedProfiles;
}

export const MOCKED_DOMES_INFO = () => {
  const presets = [
    {
      "id": 1,
      "name": "Street-1",
      "call_preset": "&chId=0&action=preset&preset_idx=1"
    },
    {
      "id": 2,
      "name": "Blue Car",
      "call_preset": "&chId=0&action=preset&preset_idx=2"
    },
    {
      "id": 3,
      "name": "Roof",
      "call_preset": "&chId=0&action=preset&preset_idx=3"
    }
  ];

  const sequences = [
    {
      "id": 1,
      "name": "Sequence Luca",
      "start_action": "&chId=0&action=start_sequence&sequence_idx=1",
      "stop_action": "&chId=0&action=stop_sequence"
    }
  ];

  return (
    {
      presets,
      sequences
    }
  )
}

export function addProfiles(serverdata) {
  const processedChannels = serverdata.root.server.channels.channel.map(channel => {

    //add fake info for domes, if needed
    let shouldAddFakeDomeInfo = false;
    if (channel["is-dome"] && (!channel.presets || !channel.sequences)) {
      shouldAddFakeDomeInfo = true;
    }

    return {
      ...channel,
      sequences: shouldAddFakeDomeInfo ? MOCKED_DOMES_INFO().sequences : channel.sequences,
      presets: shouldAddFakeDomeInfo ? MOCKED_DOMES_INFO().presets : channel.presets,
      ptzStatus: channel.activeSequenceId ? {
        "ctx": "ptzInfoSequence",
        "sequenceStatus": 2,
        "sequenceId": channel.activeSequenceId
      } : undefined,
      profiles: CHANNEL_PROFILES(channel.streams)
    }
  })

  const serverdataWithProfiles = { ...serverdata };

  serverdataWithProfiles.root.server.channels.channel = processedChannels;

  return serverdataWithProfiles;

}

export async function getCapabilities(API_capabilities) {
  const default_capabilities = {
    "ptz": 0, // controllo delle ptz
    "hlsLive": 1, // visualizzazione live hls
    "hlsrec": 0, // visualizzazione rec hls
    "viewOpenConnector": 0, // visualizzazione del connector
    "canControlPeripheral": 0, // supporto periferiche
    "loginType": "DEFAULT", //metodo di autenticazione S.U.E.D
    "canManageCameras": 0, // supporto periferiche
    "email" :0, // configurazione delle email per invio eventi
    "canControlAuditing": 0,
  }

  const REQ_TIMEOUT = 5000;
  return timeoutPromise(REQ_TIMEOUT, fetch(`${API_capabilities}`), new Error("search fetch timeout"))
    .then(resp => resp.json())
    .then(jsondata_capabilities => {
      //emulate rawStream not available
      //jsondata_capabilities.root.capabilities.rawStream = 0;

      return (jsondata_capabilities.root.capabilities);
    })
    .catch(err => {
      return null;
    });
}

export function getMeta(request) {
  const REQ_TIMEOUT = 60000;
  const isDebug = false; // Imposta questa variabile a true per attivare la modalità debug

  const { server } = request;

  if (!server.nodeServer) {
    const errorResponse = {
      root: {
        result: {
          code: 500,
          msg: 'FETCH_META_NOT_AVAILABLE'
        }
      }
    };

    return errorResponse;
  }

  const V = `${PHP_ENTRYPOINT}/metaonvif-rec`;
  const API = `${request.serverProtocol}://${request.serverIp}:${request.serverPort}/${V}`;
  const APICall = `${API}?outfmt=json&SessionId=${request.sessionId}&chId=${request.chId}&from=${request.from}&to=${request.to}`;

  let config = (server && server.nodeServer) ? {
    headers: {
      'Authorization': 'Bearer ' + server.access_token
    }
  } : {};

  if(request.method === 'post') {
    config.method = 'POST'
  }

  if(request.query) {
    config.body = JSON.stringify(request.query)
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json'
    }
  }

  if(request.aggregation) {
    config.body = JSON.stringify(request.aggregation)
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json'
    }
  }

  const fetchData = isDebug
    ? fetch('/sample/Onvif3Min.json')
    : fetch(`${APICall}`, config);

  return timeoutPromise(REQ_TIMEOUT, fetchData, new Error("meta fetch timeout"))
    .then((response) => {
      return response.json();
    })
    .then(meta => {
      //error handling
      if (server.nodeServer && !meta.root && meta.statusCode === 401) {
        return {
          ...meta,
          root: {
            result: {
              code: meta.statusCode,
              msg: 'NO_USER_LOGGED_IN_SESSION_IS_NOT_VALID'
            }
          }
        }
      }

      return meta;
    })
    .catch(err => {
      const errorResponse = {
        root: {
          result: {
            code: 500,
            msg: 'FETCH_PLAYLIST_TIMEOUT'
          }
        }
      };

      return errorResponse;
    });
}

export function roundToNearestThreeOrZero(str) {
  // estrae i secondi
  let seconds = str.slice(-2);

  // converte la stringa in numero
  let num = parseInt(seconds);

  // controlla se è già un multiplo di 3
  if (num % 3 === 0) {
      return str;
  } else if (num % 3 <= 1) {
      // se il resto è minore o uguale a 1, arrotonda per difetto
      num = num - (num % 3);
  } else {
      // altrimenti, arrotonda per eccesso
      num = num + (3 - (num % 3));
  }

  // se il numero è 60, lo arrotonda a 0
  if (num === 60) {
      num = 0;
  }

  // converti il numero in stringa, aggiungi uno zero se è un numero a una cifra
  let newSeconds = num.toString().padStart(2, '0');

  // sostituisci i secondi originali con i nuovi secondi
  return str.slice(0, -2) + newSeconds;
}

export function getMostRecentThumb(request) {
  const { server, deviceId } = request; 
  
  if(server.timeMismatch){
      return null;
  }

  const currentTime = getCurrentServerTime();
  const timeAgo = subtractInterval(currentTime, '00:00:10');

  return getClosestThumb({
    server, deviceId, time: timeAgo
  })
}

export function getClosestThumb(request) {
  const { server, deviceId, time, shouldAutoLogin } = request;

  const mode = request.mode || 'nearest';
  let refTime = time;

  if(mode === 'safe') {
    refTime = subtractInterval(refTime, '00:00:03');
  }

  if(!server) return '';
  if(deviceId === null || deviceId === undefined) return '';

  if(!server.timezone || (shouldAutoLogin && !isConnected(server))) {
    forceLogin({ _id: server._id });
    return '';
  }

  if(!server.capabilities.thumbnailTrack) return '';

  const localTime = convertUTCToLocal(refTime, server.timezone);

  const nextThumbHour = localTime.slice(0, -4)+"0000_JPEG";
  const nextThumbTime = roundToNearestThreeOrZero(localTime);

  const nextThumbnailUri = server.nodeServer ? `${server.protocol}://${server.ip}:${server.port}/thumbnails/${deviceId.toString().padStart(2,'0')}/Tracks/${nextThumbHour}/${nextThumbTime}.jpg` : '';

  const lanIp = getLanAddress(nextThumbnailUri);
  const MediaPath = (server.isLocal) ? lanIp : nextThumbnailUri;

  //ready for Nginx
  return server.nodeServer ? `${MediaPath}` : `${MediaPath}?MediaSecret=${server.MediaSecret}`;  
}

export function getPlaylist(request) {
  const REQ_TIMEOUT = 20000;
  const DVR = request.dvr || 1;

  const { server } = request;

  const V = server.nodeServer ? `${PHP_ENTRYPOINT}/play-rec` : `${CIVETWEB_ENTRYPOINT}/playRec`
  const API = `${request.serverProtocol}://${request.serverIp}:${request.serverPort}/${V}`;
  const APICall = `${API}?outfmt=json&SessionId=${request.sessionId}&chId=${request.chId}&dvr=${DVR}&from=${request.from}&to=${request.to}`;

  let config = (server && server.nodeServer) ? {
    headers: {
      'Authorization': 'Bearer ' + server.access_token
    }
  } : {};

  return timeoutPromise(REQ_TIMEOUT, fetch(`${APICall}`, config), new Error("playlist fetch timeout"))
    .then(resp => resp.json())
    .then(recordings => {
      //error handling
      if (server.nodeServer && !recordings.root && recordings.statusCode === 401) {
        return {
          ...recordings,
          root: {
            result: {
              code: recordings.statusCode,
              msg: 'NO_USER_LOGGED_IN_SESSION_IS_NOT_VALID'
            }
          }
        }
      }

      return recordings;
    })
    .catch(err => {
      const errorResponse =
      {
        root: {
          result: {
            code: 500,
            msg: 'FETCH_PLAYLIST_TIMEOUT'
          }
        }
      }

      return errorResponse
    });
}

export function getRecordingData(request) {
  const REQ_TIMEOUT = 20000;

  const serverIp = request.server.isLocal ? getLanAddress(request.server.ip) : request.server.ip;
  const API = `${request.server.protocol}://${serverIp}:${request.server.port}/${PHP_ENTRYPOINT}/sessions-events`
  const APIUrl = (request.eventId) ? `${API}?eventId=${request.eventId}` : `${API}?from=${request.startTime}&to=${request.endTime}&SearchMode=lastUpdate`;


  //MOCK
  //const ThumbServiceAPI = `${process.env.REACT_APP_THUMBS_SERVICE_API}/query-snapshot`;
  //const ThumbQueryUrl = ThumbServiceAPI && `${ThumbServiceAPI}?from=${request.startTime}&to=${request.endTime}`;
  const ThumbServiceAPI = undefined;
  const ThumbQueryUrl = undefined;

  let config = request.server.nodeServer ? {
    headers: {
      'Authorization': 'Bearer ' + request.server.access_token
    }
  } : {};

  

  return timeoutPromise(REQ_TIMEOUT, fetch(`${APIUrl}`, config), new Error("search fetch timeout"))
    .then(resp => resp.json())
    .then(recordingsData => {
      if(ThumbServiceAPI) {
        return timeoutPromise(REQ_TIMEOUT, fetch(`${ThumbQueryUrl}`, config), new Error("thumbs query fetch timeout"))  
        .then(resp => {
          return resp.json()
        })
        .then(thumbsData => {
          const thumbs = thumbsData.length > 0 ? thumbsData.map(thumb => {
            return {
              artecoId: `${request.server.codeName}_${ARTECO_CAM_CODE}_${thumb.channelId}`,
              timestamp:  thumb.timestamp,
              thumbnailUri: `${process.env.REACT_APP_THUMBS_SERVICE_API}/path/${thumb.path}`,
              filename: thumb.filename
            }
          }) : [];
          return {
            ...recordingsData.root,
            thumbsData: thumbs
          }
        })
      } else {
        return recordingsData.root;
      }
    })
    .catch(err => {
      return err;
    });
}

export function getAuditingData(request) {
  const REQ_TIMEOUT = 50000;

  const serverIp = request.server.isLocal
    ? getLanAddress(request.server.ip)
    : request.server.ip;
  const API = `${request.server.protocol}://${serverIp}:${request.server.port}/${PHP_ENTRYPOINT}/auditings`;
  const APIUrl = `${API}?from=${request.startTime}&to=${request.endTime}`;

  let config = request.server.nodeServer
    ? {
        headers: {
          Authorization: "Bearer " + request.server.access_token,
        },
      }
    : {};

  return timeoutPromise(
    REQ_TIMEOUT,
    fetch(`${APIUrl}`, config),
    new Error("search fetch timeout")
  )
    .then((resp) => resp.json())
    .then((jsondata) => {
      return jsondata;
    })
    .catch((err) => {
      return err;
    });
}


export function postAuditingData(server, requestBody) {
  if (server.capabilities?.canControlAuditing !== 1|| !isCredentialEnabled(AuditingUserTracking, server)) {
    return false;
  }

  const requiredProperties = [
    "eventTime",
    "serverCodename",
    "omniaUserName",
    "serverUserName",
    "action",
    "description"
  ];

  const missingProperties = requiredProperties.filter(prop => !(prop in requestBody));

  if (missingProperties.length > 0) {
    return Promise.reject(new Error(`Missing properties: ${missingProperties.join(", ")}`));
  }

  const REQ_TIMEOUT = 20000;

  const serverIp = server.isLocal ? getLanAddress(server.ip) : server.ip;
  const API = `${server.protocol}://${serverIp}:${server.port}/${PHP_ENTRYPOINT}/auditings`;

  let config = server.nodeServer ? {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + server.access_token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  } : {};

  return timeoutPromise(REQ_TIMEOUT, fetch(API, config), new Error("search fetch timeout"))
    .then(resp => resp.json())
    .then(jsondata => {
      return jsondata.root;
    })
    .catch(err => {
      return err;
    });
}

export function getOnvifData(request) {
  const REQ_TIMEOUT = 20000;

  const serverIp = request.server.isLocal ? getLanAddress(request.server.ip) : request.server.ip;
  const API = `${request.server.protocol}://${serverIp}:${request.server.port}/${PHP_ENTRYPOINT}/onvif-edge-rec`;
  const deviceData = request.deviceData;
  const fixIndex = deviceData.sourceIndex - 1;

  const APIUrl = `${API}?ip=${deviceData["ip-address"]}&username=${deviceData.username}&password=${deviceData.password}&onvifPort=${deviceData.port}&recIndex=${fixIndex}`;

  let config = request.server.nodeServer ? {
    headers: {
      'Authorization': 'Bearer ' + request.server.access_token
    }
  } : {};

  return timeoutPromise(REQ_TIMEOUT, fetch(`${APIUrl}`, config), new Error("search fetch timeout"))
    .then(resp => resp.json())
    .then(onvifInfo => {
      return onvifInfo;
    })
    .catch(err => {
      return err;
    });
}


export function updateDeviceConfig(device) {
  const REQ_TIMEOUT = 20000;

  let latitudeOfDevice = parseFloat(device.lat);
  let longitudeOfDevice = parseFloat(device.long);

  let isCamera = (device.type === ARTECO_CAM_CODE);
  let manageWhat = (isCamera) ? "manageCamera" : "managePeripheral";

  const server = device.parentServer;

  const serverIp = server.isLocal ? getLanAddress(server.ip) : server.ip;
  const API = `${server.protocol}://${serverIp}:${server.port}/${CIVETWEB_ENTRYPOINT}/${manageWhat}`;
  const APICall = `${API}?outfmt=json&SessionId=${server.sessionId}`;

  const DeviceParams = {
    id: device.id,
    action: "modify",
    lat: latitudeOfDevice,
    long: longitudeOfDevice
  }

  const config = {
    method: 'POST',
    body: JSON.stringify(DeviceParams),
    headers: { 'Content-Type': 'application/json' }
  };

  return timeoutPromise(REQ_TIMEOUT, fetch(APICall, config), new Error("configuration fetch timeout"))
    .then(resp => resp.json())
    .then(jsondata => {
      return jsondata;
    })
    .catch(err => {
      return err;
    });

}

export function getFrom(server, userPrefs) {
  //setting 'from' for events' query
  let serverLastTag = server.lastEventTimestamp;
  let from = 0;
  let currentServerTime = getCurrentServerTime();

  //0 || undefined
  if (!serverLastTag) {
    //no timestamp set: get events -> last (timeOfRetrieval) hours.          
    from = intervalOperations(currentServerTime, `${Math.floor(userPrefs.timeOfRetrieval).toString().padStart(2, '0')}:00:00`, 'subtract');
  } else {

    //if server las tag is too old, limit query
    let lastEventQueryAge = serverTimeDiff(serverLastTag, currentServerTime);

    if (lastEventQueryAge > userPrefs.timeOfRetrieval) {
      //last tag is too old
      from = intervalOperations(currentServerTime, `${Math.floor(userPrefs.timeOfRetrieval).toString().padStart(2, '0')}:00:00`, 'subtract');
    } else {
      from = serverLastTag;
    }

  }

  return from;
}

export function isArtecoSerial(serialCandidate) {
  if (typeof (serialCandidate) !== 'string') return false;

  if (serialCandidate.length !== 9) return false;

  const match = serialCandidate.match(/(V|v)\d{8}/);

  return !!match;
}

export async function loadImageWithToken(mediaUri, token) {
  try {
    const response = await fetch(mediaUri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Could not load image');
    }

    const imageBlob = await response.blob();
    const imageUrl = URL.createObjectURL(imageBlob);

    return imageUrl;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export async function loadImageB64WithToken(mediaUri, token) {
  try {
    const response = await fetch(mediaUri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Could not load image');
    }

    const imageBlob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // Questo è il tuo Base64
      reader.onerror = () => reject('Error in reading image blob');
      reader.readAsDataURL(imageBlob);
    });
  } catch (error) {
    console.error('Error in loadImageB64WithToken:', error);
    return null;
  }
}

export async function loadImageAndConvertToBase64(mediaUri, token) {
  try {
      const response = await fetch(mediaUri, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });

      if (!response.ok) {
          throw new Error('Could not load image');
      }

      const imageBlob = await response.blob();

      return new Promise((resolve, reject) => {
          let img = new Image();
          img.onload = () => {
              let canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              let ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, img.width, img.height);
              let canvasBase64 = canvas.toDataURL('image/jpeg').split(',')[1];
              resolve({ base64: canvasBase64, width: img.width, height: img.height });
          };
          img.onerror = () => {
              reject(new Error("Errore nel caricamento dell'immagine"));
          };
          let reader = new FileReader();
          reader.onloadend = () => {
              img.src = reader.result;
          };
          reader.onerror = () => {
              reject(new Error("Errore nella lettura del blob dell'immagine"));
          };
          reader.readAsDataURL(imageBlob);
      });
  } catch (error) {
      console.error('Errore in loadImageAndConvertToBase64:', error);
      return null;
  }
}




export const onlyPresentPeripherals = (peripherals, serverCodenames, servers, group = false) => {
  const verifiedPeripherals = peripherals.filter(peripheral => peripheralIsPresent(serverCodenames, servers, peripheral));

  return verifiedPeripherals;
}

export const groupDevicesByCodename = (devices) => {
  const groupedDevices = devices.reduce((groups, element) => {
    const serverCodename = element.serverCodename;
    groups[serverCodename] = groups[serverCodename] || [];
    groups[serverCodename].push(element);
    return groups;
  }, {});

  return groupedDevices;
}

export async function loadSnap(url, access_token) {
  let delta = 0;
  let numAttempts = 0;
  const maxAttempts = 4;
  while (numAttempts < maxAttempts) {

    const timestamp = url.match(/snap_(\d{14})\.jpg/)[1];
    const newTimestamp = intervalOperations(timestamp, `00:00:${delta.toString().padStart(2, '0')}`, 'add');
    const img_url = url.replace(timestamp, newTimestamp);

    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    try {
      const response = await fetch(img_url, { headers });
      if (!response.ok) {
        delta++;
        numAttempts++;
        //console.clear();
      } else {
        return { img_url, delta };
      }
    } catch (error) {
      delta++;
      numAttempts++;
    }
  }
  return { img_url: null, delta: 0 };
}

export const getSnapshotAtTimestamp = (mediaUri, token, timestamp, server) => {  
  logger(info, "server", `getSnapshotAtTimestamp called with: ${mediaUri} ${token} ${timestamp} ${server}`)
  return loadSnap(mediaUri, token, timestamp)
    .then(({ img_url, delta }) => {      
      if (img_url) {        
        return { img_url, delta };
      } else {        
        return { img_url: null, delta: 0 };
      }
    })
    .catch((error) => {
      //console.error('loadSnap rejected with error:', error);
      return { img_url: null, delta: 0 };
    });
}

export const isConnected = (server) => {
  //console.log(">>>>>> Server : " + server.name + " - isConnected > " + (server.connectionStatus !== 'not-connected' && server.access_token !== 'expired'));
  return server.connectionStatus !== 'not-connected' && server.access_token !== 'expired';
}

export function cleanIntegrationVirtualPeripherals(serverdata) {
  if(!serverdata.root.server.peripherals || !serverdata.root.server.peripherals.peripheral) {
    return serverdata;
  }

  const excludedPerpheralsType = INTEGRATIONS_PER_SUBTYPE_LIST;
  const processedPeripherals = serverdata.root.server.peripherals.peripheral.filter(peripheral => {
    return !excludedPerpheralsType.includes(peripheral.subType);
  })  

  const serverdataWithCleanIntegrations = { ...serverdata };

  serverdataWithCleanIntegrations.root.server.peripherals.peripheral = processedPeripherals;

  return serverdataWithCleanIntegrations;

}