import React from "react";
import { connect } from "react-redux";
import gtag from "ga-gtag";

import { getEventsNumForDevice } from '../../reducers/eventsReducer';
import { getPlaylistError, getExportStatus, getExportPath, getMetaPending } from '../../reducers/playlistsReducer';
import { updatePlaylistStatus, exportVideo } from '../../actions/playlistActions';
import { deviceShouldZoom } from '../../reducers/mapsReducer';
import { zoomDone } from '../../actions/mapsActions';
import { setChannelMode, setLayoutZoomAndPan, updateChannel, updateLayout, setCameraInFullscreen} from "../../actions/layoutActions";
import { getActiveLayout, getChannelMetadataShown, getChannelMode, getChannelPrivacyShown, getChannelZoomAndPan,getIsAudioActiveByArtecoId,getChannel } from "../../reducers/layoutsReducer";
import { getFile, updateDownloadList } from '../../actions/downloadsActions';
import { error, info, logger } from "../../helpers/logger";
import { customEvents } from "../../config/eventsAndStorage";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { serverDateToFormattedDate, addInterval, getCurrentServerTime, intervalOperations, serverDateToUnixDate} from '../../helpers/timeHelpers';
import { isWebGLSupported } from 'webgl-detector';
import { isChrome, isIOS, isMobileOnly } from 'react-device-detect';
import { getUserPrefs, isExportPrivacySupported, isTimestampAndWatermarkSupported } from '../../reducers/userPrefsReducer';
import { withTranslation } from 'react-i18next';
import { appPaths } from '../../config/paths';
import Spinner from "../Spinner/Spinner";

import Clappr from "clappr";
import { Events } from 'clappr';
import ClapprNerdStats from "./ClapprNerdStats";

import ArtecoPlugins from './ArtecoPlugins';
import Video360 from "../dashboard/Stage/Video360";

import ArtecoPlayerTimeBadge from "./ArtecoPlayerTimeBadge";
import { getParentServerByArtecoId, getPrivacyData, isCredentialEnabled, OmniaLight, serverIsLoggingIn } from "../../reducers/serversReducer";
import PrivacyDisplay from "./PrivacyDisplay";
import OnvifMetaDisplay from "./OnvifMetaDisplay";
import ResizableContainer from "./ResizableContainer";
import { showExportFeaturesPopup } from "../../actions/featuresActions";
import { ArtecoToast } from '../Toast/ArtecoToast';
import { fileDownloadProgress, fileIsDownloading } from "../../reducers/downloadsReducer";
import { getListeners, listenerExists } from "../../reducers/documentReducer";
import { addCustomListener, removeCustomListener } from "../../actions/documentActions";
import { CamerasPrivacyBlur } from "../../helpers/serverCredentials";
import { getAuthentication, getAuthenticationUserId } from "../../reducers/authReducer";
import { addDiagnosticsEvent } from "../../actions/eventsAction";

const loggerScope = "ClapprPlayer";
class ClapprPlayer extends React.Component {


  constructor(props) {
    super(props);

    const { channelZoomAndPan } = this.props;

    this.state = {
      metadataLoaded: false,
      mounted: false,
      isBuffering: false,
      vodCheck: false,
      isError: false,
      downloadProgress: 0,
      zoomDisabled: false,
      zoomed: channelZoomAndPan?.scale > 1,
      tempSeekTo: 0, // secondi da puntare per il seek
      progressLimitFired: false,
      }

    this.currentTime = null;
    this.currentSecondTime = null;
    this.currentServerTime = null;
    this.lastSentCurrentTime = '';

    this.playerInstance = null;
    this.timer = 0;
    this.updateCounter = 0;
    this.nodeRef = React.createRef();
    this.containerRef = React.createRef();
    this.zoomWrapperRef = React.createRef();
    
    //retry handling
    this.ERROR_TIMEOUT = 7000;
    this.LOADING_TIMER = 10000;
    this.LOADING_UPDATE_COUNT = 30;
    this.firstAudioDetectedTrue = false;
  }

  isMSESupported = () => {
    if ('MediaSource' in window || 'ManagedMediaSource' in window) {
      return true;
    } else {
      return false;
    }
  }

  fallbackToFastPlay = () => {
    const { activeLayout, channel, updateChannel } = this.props
    updateChannel(activeLayout, {
      ...channel,
      streamMode: 'webrtc'
    })
  }

  componentDidMount() {
    const { device } = this.props;

    ////console.log(">>>> MOUNT | Player for device " + device.artecoId + "mounted");
    this.setState({
      mounted: true,
    });

  }

  loadThePlayer(reason = null) {
    reason && logger(info, 'player', ">>> player " + this.props.device.descr + " Load player for " + reason);

    ////console.log(">>>>>> LOAD THE PLAYER");
    const { isPopup, channelMode, allowOnlyPIP, userPrefs, exportStatus, downloadProgress, isDownloading, t, device, forcePlayBackMode, server, isOmniaLight, channelPrivacyData, channelMetadataShown,isAudioActive,activeLayout ,isPrivacyPopUpOn} = this.props;
    const self = this;
    const checkPlayback = forcePlayBackMode === true ? true : (channelMode && channelMode.mode !== 'live') ? true : this.isPlayback();


    this.destroy();
    //when there is no event trigger in a video, add no highlight for border styling
    this.nodeRef.current && this.nodeRef.current.classList.remove('highlight');
    this.nodeRef.current && this.nodeRef.current.classList.add('no-highlight');
    // uncomment line below to enable clappr log
    // Clappr.Log.setLevel(0);

    const clapprConf = {
      autoPlay: true,
      isAudioActive:isAudioActive,
      mute: isAudioActive ? !isAudioActive : true,
      playback: {
        playInline: true
      },

      isPlayback: checkPlayback,
      noPip: isOmniaLight || (channelPrivacyData?.metadataType === "PrivacyZone"),
      allowOnlyPIP: allowOnlyPIP,
      artecoId: device.artecoId,
      userPrefs: userPrefs,
      exportStatus: exportStatus,
      downloadProgress: downloadProgress,
      isDownloading: isDownloading,
      switchToLiveAction: this.switchToLive,
      switchToInstantAction: this.switchToInstant,
      exportVideoAction: this.requestVideoExport,
      getFileAction: this.getFileAction,
      toggleAudio:this.toggleAudio,
      hlsUseNextLevel: true,
      allowUserInteraction: true,
      hideMediaControl: false,
      mediacontrol: true,
      device:device,
      isPrivacyPopUpOn:isPrivacyPopUpOn,
      plugins: {
        container: (device['is-fisheye'] && isWebGLSupported() && !(isIOS && isMobileOnly)) ? [Video360] : [],
        core: [ArtecoPlugins]
      },
      strings: {
        'en': {
          'default_error_title': t('PLAY_ERROR_NO_VIDEO'),
          'default_error_message': t('PLAY_ERROR_RETRY_MESSAGE'),
        },
        'it': {
          'default_error_title': t('PLAY_ERROR_NO_VIDEO'),
          'default_error_message': t('PLAY_ERROR_RETRY_MESSAGE'),
        },
        'es': {
          'default_error_title': t('PLAY_ERROR_NO_VIDEO'),
          'default_error_message': t('PLAY_ERROR_RETRY_MESSAGE'),
        }
      },

      events: {
        onError: function (e) {
          logger(info, 'player', ">>> player " + self.props.device.descr + " HLS player error " + e);

          logger(info, 'player', ">>> player " + self.props.device.descr + " Set timer for retry ");
          if (!self.errorTimer) {
            self.errorTimer = setTimeout(() => {
              const isMSESupported = self.isMSESupported();
              const supportsFastPlay = !!(isMSESupported && self.props.server.capabilities?.rawStream);  

              if(supportsFastPlay) {
                self.fallbackToFastPlay();
              } else {
                logger(info, 'player', ">>> player " + self.props.device.descr + " Retry loading");
                self.loadThePlayer('error (line 161) - error timer');
              }

            }, self.ERROR_TIMEOUT);
          }

          const wrapper = this.$el && this.$el[0];
          const container = wrapper && (this.$el[0].closest('.arteco-player') || this.$el[0].closest('.box-player') || this.$el[0].closest('.gridstage-player'));
          const poster = container && container.querySelector('.player-poster');
                              
          if (poster) {
            container.removeChild(poster);
          }
          self.setState(
            {
              isError: true,
            }, () => {
              self.props.playlistId && self.props.updatePlaylistStatus({
                artecoId: self.props.device.artecoId,
                playlistId: self.props.playlistId,
                playing: false,
                isError: true,
              });
            });
        },
        onPlay: function () {
          if (!self.isPlayback() && self.playerInstance.core.mediaControl.container.getPlaybackType() === 'vod') { // sono in live
            self.playerInstance.stop();
            self.props.liveErrors();
          }
          self.props.playlistId && self.props.updatePlaylistStatus({
            artecoId: self.props.device.artecoId,
            playlistId: self.props.playlistId,
            playing: true,
            isError: false,
          })

        },
        onPause: function () {
          self.props.playlistId && self.props.updatePlaylistStatus({
            artecoId: self.props.device.artecoId,
            playlistId: self.props.playlistId,
            playing: false,
            isError: false
          })
        },
        onTimeUpdate: this.onTimeUpdate,
        onReady: function () {

          //fullscreen function override                              
          const currentZoomRef = self.zoomWrapperRef;

          this.core.toggleFullscreen = function () {

            if (currentZoomRef) {
              self.resetTransform(currentZoomRef.current, true);
            }

            let container = this.$el[0].closest('.arteco-player') || this.$el[0].closest('.box-player') || this.$el[0].closest('.gridstage-player');
                     
            //custom full screen

            //popup case
            const popupContainer = document.querySelector('.popup-content');
            const popupOverlay = document.querySelector('.popup-overlay');

            //chrome ipad (maledetto) exception                  
            if (isChrome && popupContainer) {
              popupContainer.classList.add('is-chrome');
            }
            if (isIOS && popupContainer) {
              popupContainer.classList.add('is-ios');
            }

            if (container.classList.contains('custom-fullscreen')) {
              if (popupContainer) {
                popupOverlay.classList.remove('custom-fullscreen');
                popupContainer.classList.remove('custom-fullscreen');
              }
              self.props.setCameraInFullscreen(null);
              self.setState({
                isFullscreen: false
              })
            } else {
              if (popupContainer) {
                popupOverlay.classList.add('custom-fullscreen');
                popupContainer.classList.add('custom-fullscreen');
              }
              self.props.setCameraInFullscreen(self.props.device.artecoId);
              self.setState({
                isFullscreen: true
              })
            }

          }

          this.core.activePlayback.listenTo(
            this.core.activePlayback,
            Clappr.Events.PLAYBACK_ENDED,
            function (e) {
              //console.log('>>>>>> PLAYBACK_ENDED');
              logger(info, loggerScope, "video finito  self.props.source " + self.props.source)
              if (self.props.reachedEndOfVideo && self.props.source != undefined) {
                // invio l'orario in formato currentServerTime (YYYYMMDDhhmmss) e in formato secondi (180s...)
                self.props.reachedEndOfVideo(self.currentServerTime, self.currentSecondTime);
              }
            }
          )


          this.core.activeContainer.listenTo(
            this.core.activeContainer,
            Clappr.Events.CONTAINER_STATE_BUFFERFULL,
            function (e) {
              //console.log('>>>>>> CONTAINER_STATE_BUFFERFULL');
                if (self.state.isBuffering) {
                self.setState({
                  isBuffering: false,
                })
              }
            }
          )

          let currentPlaybackType = null;

          self.playerInstance.on(Clappr.Events.PLAYER_TIMEUPDATE, () => {
            const newPlaybackType = this.core.activePlayback._playbackType;
            if (newPlaybackType !== currentPlaybackType) {
              currentPlaybackType = newPlaybackType;
              if (newPlaybackType === 'live') {
                //console.log('>>>>>> Switched to live stream');
              } else {
                //console.log('>>>>>> Switched to VOD');

                //sposto i controlli per evitare lo zoom e che non siano oscurati dalla privacydisplay
                const controls = self.containerRef.current?.querySelector('.media-control');
                const container = self.containerRef.current?.closest('.arteco-player') || self.containerRef.current?.closest('.box-player')  || self.containerRef.current?.closest('.gridstage-player');
                (controls && container) && container.appendChild(controls); 
                //console.log('>>>>>> appendo controlli al container');
              }
            }
          });

          this.core.activeContainer.listenTo(
            this.core.activeContainer,
            Clappr.Events.CONTAINER_STATE_BUFFERING,
            function (e) {
              // spostamento spinner affinchè non sia offuscato dalla privacy
              const container = self.containerRef.current?.closest('.arteco-player') || self.containerRef.current?.closest('.box-player')  || self.containerRef.current?.closest('.gridstage-player');
              const spinnerBounce = self.containerRef.current?.querySelector('.spinner-three-bounce');
              (spinnerBounce && container) && container.appendChild(spinnerBounce); 

              //console.log('>>>>>> CONTAINER_STATE_BUFFERING');
              if (!self.state.isBuffering) {
                self.setState({
                  isBuffering: true,
                })
              }
            }
          )

          this.core.activePlayback.listenTo(
            this.core.activePlayback,
            Clappr.Events.PLAYBACK_PLAY,
            function (e) {
              //console.log('>>>>>> PLAYBACK_PLAY');
              const { vodCheck, tempSeekTo } = self.state;
              const { updatePlaylistStatus, playlistId, isTranscoding } = self.props;
              const mediaType = self.playerInstance && self.playerInstance.core.mediaControl.container.getPlaybackType(); 
             
              if (!isTranscoding) {
                if (self.isPlayback() && !vodCheck && mediaType === 'vod') {
                  logger(info, loggerScope, "Passing to VOD mode ***** ");                  
                  if (self.props.timelineMode == 'pause') {
                    self.pausePlayer(); // player impostato in 'pausa' nel momento in cui arrivano frame e la modalità è 'pausa'
                  }
                  self.setState({
                    vodCheck: true,
                  }, () => {
                    playlistId && updatePlaylistStatus({
                      artecoId: self.props.device.artecoId,
                      playlistId: self.props.playlistId,
                      mode: 'vod',
                      isError: false,
                    });
                  })
                }
              }
              // forzo sempre il comportamento come se fosse rec
              if (isTranscoding) {
                if (self.isPlayback() && !vodCheck) {
                  // quando arriva ed è transcoding, muovi al punto giusto
                  self.setState({
                    vodCheck: true,
                  }, () => {
                    playlistId && updatePlaylistStatus({
                      artecoId: self.props.device.artecoId,
                      playlistId: self.props.playlistId,
                      mode: 'vod',
                      isError: false,
                    });
                  })

                  if (tempSeekTo) {
                    self.seekPlayer(tempSeekTo);
                    logger(info, loggerScope, "Temp seek to " + tempSeekTo)
                  }
                }
              }

              //sposto i controlli per evitare lo zoom e che non siano oscurati dalla privacydisplay
              const controls = this.$el[0]?.parentElement?.parentElement?.querySelector('.media-control');
              const container = this.$el[0].closest('.arteco-player') || this.$el[0].closest('.box-player')  || this.$el[0].closest('.gridstage-player');
              (controls && container) && container.appendChild(controls); 
              //console.log('>>>>>> appendo controlli al container');
            }
          )



          this.core.activePlayback.listenTo(
            this.core.activePlayback,
            Clappr.Events.PLAYBACK_ERROR,
            function (e) {
              if (!self.state.isError) {
                self.setState(
                  {
                    isError: true,
                  }, () => {
                    //console.log('>>>>>> PLAYBACK_ERROR');
                    // spostamento errori affinchè non siano offuscati dalla privacy
                    const container = self.containerRef.current.closest('.arteco-player') || self.containerRef.current.closest('.box-player')  || self.containerRef.current.closest('.gridstage-player');
                    const errScreen = self.containerRef.current.querySelector('.player-error-screen');
                    (errScreen && container) && container.appendChild(errScreen); 
                    
                    self.props.playlistId && self.props.updatePlaylistStatus({
                      artecoId: self.props.device.artecoId,
                      playlistId: self.props.playlistId,
                      playing: false,
                      isError: true,
                    });
                  }
                )
              }
            }
          )

          // modifica ai controlli per poter gestire lo zoom (i controlli rimangono 'fuori' dallo zoom)
          this.core.listenTo(
            this.core,
            Clappr.Events.CORE_READY,
            function (e) {
              //console.log('>>>>>> CORE_READY');
               const poster = this.$el[0]?.parentElement?.parentElement?.querySelector('.player-poster');
               const container = this.$el[0].closest('.arteco-player') || this.$el[0].closest('.box-player') || this.$el[0].closest('.gridstage-player');
              if (poster) {
                container && container.appendChild(poster);
              }
            }
          )

          this.core.activePlayback.listenTo(
            this.core.activePlayback,
            Clappr.Events.PLAYBACK_LOADEDMETADATA,
            function (e) {
              //console.log('>>>>>> PLAYBACK_LOADEDMETADATA');
              self.setState({
                metadataLoaded: true,
               
              })
            }
          )

          //disable pinch to toggle fullscreen 
          //commented for now becouse on iphone have an error for dblTapHandler
          // if(this.core.mediaControl.container.dblTapHandler ){
          //   this.core.mediaControl.container.dblTapHandler = null;
          // } 

          // disable click to pause
          var plugin = this.getPlugin('click_to_pause');
          plugin && plugin.disable();
          
        },
      },

    }

    if (server.nodeServer) {
      clapprConf.playback = {
        ...clapprConf.playback,
        hlsjsConfig: {
          xhrSetup: function (xhr, url) {
            xhr.setRequestHeader('Authorization', `Bearer ${server.access_token}`);
          }
        }
      }
    }


    this.playerInstance = new Clappr.Player(clapprConf);
    const playerRef = this.playerInstance;

    playerRef.triggerCustomError = (message) => {
      const errorEvent = {
        code: 'custom timeout',
        message: message,
        type: Clappr.Events.PLAYER_ERROR
      };
      playerRef.trigger(Clappr.Events.PLAYER_ERROR, errorEvent);
    };

    if (!this.loadingTimer) {
      logger(info, 'player', ">>> player " + self.props.device.descr + " Set loading timer");
      this.loadingTimer = setTimeout(() => {
        playerRef.triggerCustomError('Timeout HLS error');
        // if(isIOS && !this.isPlayback()) {
        //   window.location.reload();
        // }
        
      }, this.LOADING_TIMER);
    }

    const popupMode = activeLayout && activeLayout.popupMode?.active

    this.playerInstance?.attachTo(this.nodeRef.current); // istanzio il player senza però dargli ancora nessuna sorgente 'in pasto'
    if (popupMode && !this.isPlayback()) {
      let foundChannel;
      if (activeLayout) foundChannel = activeLayout.channels.find(channel => channel.artecoId == device.artecoId);

      if (this.nodeRef && this.nodeRef.current) {
        if (foundChannel && foundChannel.clicked == false) {
          this.nodeRef.current.classList.remove('no-highlight')
          this.nodeRef.current.classList.add('highlight')
        }
      }
    }


    this.playerInstance.on(Clappr.Events.PLAYER_ERROR, err => {
      logger(info, 'player', ">>> player " + self.props.device.descr + " HLS player error ");

      if (err.code !== '') {

        const errCodes = document.querySelectorAll(`.player-error-screen__code`)
        errCodes.forEach(errCode => {
          if (this.nodeRef.current && this.nodeRef.current.contains(errCode)) {
            if (err.code.indexOf('manifest') !== -1) {
              errCode.innerHTML = this.props.t('PLAY_ERROR_SERVER_CONNECTION')
              errCode.classList.add('text-danger')
            }
            if (err.code.indexOf('hls:3') !== -1) {
              errCode.innerHTML = this.props.t('PLAY_ERROR_INTERNET_CONNECTION')
              errCode.classList.add('text-danger')
            }
          }
        })

        this.errorTimer = setTimeout(() => {
          //logger(info, 'player', ">>> Clappr player RELOAD " + this.props.device.descr + " Update counter " + self.updateCounter + " - " + this.updateCounter);           
          const isMSESupported = this.isMSESupported();
          const supportsFastPlay = !!(isMSESupported && self.props.server.capabilities?.rawStream);  

          if(supportsFastPlay) {
            self.fallbackToFastPlay();
          } else {
            this.loadThePlayer('player error (line 468) ' + err.code);
          }
        }, this.ERROR_TIMEOUT);
      }
    })


    if (!this.state.listenersAdded) {
      this.setState({
        listenersAdded: true
      }, () => {

        if (!this.props.keyDownListenerExist) {
          this.props.addCustomListener("keydown", e => {
            if (window.fullscreenElement == undefined) {
              // un video player non è fullscreen, evito fare cose
              return;
            }
          })
        }

        if (!this.props.playerControlListenerExist) {
          this.props.addCustomListener(customEvents.playerControl, this.playerExternalControl);
        }

        if (!this.props.KeyDownLiveCameraListenerExist) {
          this.props.addCustomListener(customEvents.onKeyDownLiveCamera, this.onKeyDown);
        }

        this.nodeRef.current && this.nodeRef.current.addEventListener('dblclick', function(e) {  
          e.preventDefault();
          e.stopPropagation()
        }, true);
      })
    }


     //setAudio

    let foundChannel;
    if (activeLayout) foundChannel = activeLayout.channels.find(channel => channel.artecoId == device.artecoId);
    if(foundChannel || isPopup){
      const volumeVal = isAudioActive ? 100:0;
      this.playerInstance?.setVolume(volumeVal);
    }else {
      this.playerInstance?.setVolume(0);
    }   
    
    this.loadAndPlay();


    logger(info, loggerScope, "Caricamento player " + reason);



  }

  playPlayer = () => {
    this.playerInstance?.play();
  }

  pausePlayer = () => {
    this.playerInstance?.pause();
  }

  seekPlayer = (seekTo) => {
    const { timelineMode } = this.props;

    this.playerInstance.seek(seekTo);
    if (timelineMode == 'pause') {
      this.pausePlayer();
    }

  }

  playerExternalControl = (event) => {

    const { device } = this.props
    const { tempSeekTo } = this.state;

    const eventDetail = event.detail || event;
    const eventCmd = eventDetail.command;
    const eventArtecoId = eventDetail.artecoId;
    const seekValueData = eventDetail.seekTo;
    const timeToManage = eventDetail.timeToManage;

    try {
      if (eventArtecoId && eventArtecoId == device.artecoId) {
        // comando speficio per una telecamera
        if (eventCmd == 'pause') {
          this.pausePlayer();
        }
        if (eventCmd == 'play') {
          this.playPlayer();
        }
        if (eventCmd == 'seek') {
          this.setState({
            tempSeekTo: seekValueData,
          }, () => {
            this.seekPlayer(seekValueData);
          })
        }
      }

      // comando generico per tutte le telecamere
      if (eventCmd == 'refresh') {
        if (this.isPlayback()) {
          this.playPlayer();
          this.pausePlayer();
        } else {
          this.playPlayer();
        }
      }
      if (eventCmd == 'pauseAll') {
        this.pausePlayer();
      }
      if (eventCmd == 'playAll') {
        this.playPlayer();
      }
      if (eventCmd == 'seekAll') {
        // se il seekValue == undefined allora il seek viene fatto secondo l'ultimo dato di seek memorizzato nel player
        // questo serve perchè con il nuovo player il seekback non è pi a 0 ma è al tempo impostato dal tempo impostato nello state del player
        if (seekValueData == undefined) {
          this.seekPlayer(tempSeekTo);
        } else {
          this.seekPlayer(seekValueData);
        }
      }

      if (eventCmd == 'manageTime') {
        // aggiungo un tempo in secondi al player
        if (timeToManage) {
          this.seekPlayer(this.currentSecondTime + timeToManage);
        }
      }
    } catch (err) {
      logger(error, "Clappr", err);
    }

  }

  toggleAudio = () => {
    
    const {  isAudioActive,activeLayout,device,updateLayout} = this.props;

    const updatedLayout = {
      ...activeLayout,
      channels: activeLayout.channels.map((element) => {
        if (element.artecoId !== device.artecoId) {
          return { ...element, isAudioActive: false }
        } else {
          return { ...element, isAudioActive: !isAudioActive }
        }
      })
    };

    updateLayout(updatedLayout);
  
  }


  truncate = (numero, cifreDecimali) => {
    let moltiplicatore = Math.pow(10, cifreDecimali);
    return Math.floor(numero * moltiplicatore) / moltiplicatore;
  }

  sendClockUpdates = (eCurrent, from, device) => {
    const eCurrentRounded = this.truncate(eCurrent, 1);
    if (this.lastSentCurrentTime !== eCurrentRounded) {
      let serverTimeSeconds = from ? addInterval(from, `00:00:${eCurrent.toString().padStart(2, '0')}`) : undefined;

      let millisecond = Math.round((eCurrentRounded % 1) * 1000).toString().padEnd(3, '0');
      let serverTimeMillis = `${serverTimeSeconds}${millisecond}`;

      const clockEvent = new CustomEvent("playerClock", {
        detail: {
          currentServerTime: serverTimeMillis,
          originalTime: eCurrent,
          artecoId: device.artecoId
        }
      });


      document.dispatchEvent(clockEvent);
      this.lastSentCurrentTime = eCurrentRounded;
    }
  }

  onTimeUpdate = (e) => {

    const { autoFetchOnEnd, playlist, instantInterval, device, allowBackToLive, requestNextPlaylist, maxVideoDuration, reachedEndOfVideo, secondsBeforeRequest } = this.props;
    const { vodCheck, isError, isBuffering, progressLimitFired, maxPlaylistLimitReached } = this.state;
    let from = (instantInterval) ? instantInterval.from : undefined;
    const core =  this.playerInstance.core;
    const bind = this;

    if (playlist && playlist.actualInfo && playlist.actualInfo.actualFrom) {
      from = playlist.actualInfo.actualFrom
    }

    const eCurrent = Math.floor(e.current);


    if (from) {
      this.sendClockUpdates(e.current, from, device);
    }


    //logger(loggerScope, "eCurrent = " + eCurrent + ", vodCheck = " + vodCheck + ", mediaType = " + mediaType);

    if (this.loadingTimer) {
      if (this.isPlayback()) {

        if (this.loadingTimer) {
          clearTimeout(this.loadingTimer);
          this.loadingTimer = null;
          this.updateCounter = 0;
        }

        if (this.errorTimer) {
          clearTimeout(this.errorTimer);
          this.errorTimer = null;
        }
      }

      if (this.updateCounter > this.LOADING_UPDATE_COUNT) {
        logger(info, 'player', ">>> Clappr player " + this.props.device.descr + " Clear loading timer - player loaded");
        clearTimeout(this.loadingTimer);
        clearTimeout(this.errorTimer);
        this.errorTimer = null;
        this.loadingTimer = null;
        this.updateCounter = 0;
      } else {
        //logger(info, 'player', ">>> Clappr player " + this.props.device.descr + " Update counter " + this.updateCounter);   
        this.updateCounter++;
      }
    }

    if(!this.firstAudioDetectedTrue ){
      //check if audio is active  and hide btn if no audio track 
      const container = core.$el[0].closest('.arteco-player') || core.$el[0].closest('.box-player')  || core.$el[0].closest('.gridstage-player');
      const controls = container && container.querySelector('.media-control');
      const audiobtn = controls && controls.querySelector('.media-control-right-panel').querySelector('.audio-player-trigger');

      if(audiobtn){
        let hasAudio = false;
        // Prima verifica _hls
        if (this.playerInstance.core.activePlayback._hls) {
          hasAudio = this.playerInstance.core.activePlayback._hls.abrController.fragCurrent?._elementaryStreams.audio;
        }
      
        // Se _hls non è disponibile, utilizza l'API MediaElement
        if (!hasAudio) {
          hasAudio = this.playerInstance.core.activePlayback.el.audioTracks && this.playerInstance.core.activePlayback.el.audioTracks.length > 0;
        }            
        
        if(!hasAudio){
          audiobtn.classList.add("hidden");
        }else{
          this.firstAudioDetectedTrue = true;

          if ( audiobtn.classList.contains('hidden')) {
            audiobtn.classList.remove("hidden");
          }  
        }
      }
      
    }

    if (!this.isPlayback()) {
      return;
    }


    if (eCurrent != this.currentSecondTime) {
      const secondsBefore = secondsBeforeRequest ? secondsBeforeRequest : 10; // secondi prima della nuova richiesta
      let displayTime = from ? addInterval(from, `00:00:${eCurrent.toString().padStart(2, '0')}`) : undefined;

      if (eCurrent >= ((maxVideoDuration / 1000)) && !maxPlaylistLimitReached && autoFetchOnEnd == true) {
        // controllo della durata massima del video
        this.setState({
          maxPlaylistLimitReached: true,
        }, () => {
          this.pausePlayer();
          reachedEndOfVideo(this.currentServerTime);
        })
      }

      if (eCurrent >= (((maxVideoDuration / 1000)) - secondsBefore) && !progressLimitFired && autoFetchOnEnd == true) {
        // controllo se mancano secondsBefore prima di arrivare alla fine del video
        this.setState({
          progressLimitFired: true,
        }, () => {
          requestNextPlaylist(this.currentServerTime);
        })

      }

      this.currentTime = serverDateToFormattedDate(displayTime, true);
      this.currentServerTime = displayTime;

      // l'evento di cambio orario viene inviato solo se è passato almeno 1 secondo, altrimenti verrebbe inviato decine di volte al secondo
      const event = new CustomEvent(customEvents.updatePlayersTime, {
        'detail': {
          currentTime: serverDateToUnixDate(displayTime),
          displayTime: serverDateToFormattedDate(displayTime, true),
          artecoId: device.artecoId,
          descr: device.descr,
          isError: isError,
          vodCheck: vodCheck,
          isBuffering: isBuffering,
          currentServerTime: displayTime
        }

      });

      //if (!allowBackToLive) {
      if(this.isPlayback()) {
        // questo 'blocco' serve per evitare che vengano inviati troppi eventi quanto questo non server.
        // qui ci entro solo se sono in modalità playback ( e non event playback) e se è cambiato l'orario per almeno 1 secondo
        //MA COSA MINCHIA C'ENTRA CON ALLOWBACKTOLIVE????????
        document.dispatchEvent(event);
      }


      // if (eCurrent > 0 && !vodCheck && mediaType === 'vod') {
      //   logger(loggerScope, "Passing to VOD mode ***** ");
      //   if (this.props.timelineMode == 'pause') {
      //     this.pausePlayer(); // player impostato in 'pausa' nel momento in cui arrivano frame e la modalità è 'pausa'
      //   }

      //   this.setState({
      //     vodCheck: true,
      //   }, () => {

      //     playlistId && updatePlaylistStatus({
      //       artecoId: this.props.device.artecoId,
      //       playlistId: this.props.playlistId,
      //       mode: 'vod',
      //       isError: false,
      //     });

      //   })
      // }
    
      this.currentSecondTime = eCurrent;

    }

  }

  getFileAction = () => {
    const { exportPath, auth, userPrefs, getFile } = this.props;

    getFile(exportPath, auth, userPrefs);
  }

  chechIfThereIsPrivacy = (metadataHistory) => {
    if(!metadataHistory || metadataHistory.length === 0){
      return false;
    }else{
      const hasPrivacyZone = metadataHistory.find(meta => meta.metadata.find(meta => meta.metadataType === 'PrivacyZone'));
      return hasPrivacyZone ;
    }
  }

  doExport = (exportFeaturesPickerPayload = null) => {
    const { playlistId, device, source, auth, exportVideo, userPrefs, server, metadataHistory, playlist, exportPrivacySupported, t, exportTimeStampWatermarkSupported, addDiagnosticsEvent } = this.props;

    //GA TRACK
    gtag('event', 'export_video');

    const hasPrivacy = (exportFeaturesPickerPayload && exportFeaturesPickerPayload.privacy);
    const isUnlocked = (exportFeaturesPickerPayload && !exportFeaturesPickerPayload.privacy);
    const privacyData = hasPrivacy && this.chechIfThereIsPrivacy(metadataHistory) ? metadataHistory : [];

    let exportRequest = {
      playlistId: playlistId,
      artecoId: device.artecoId,
      serverCodename: server.codeName,
      recPath: source,
      owner: auth.user.id,
      ownerName: auth.user.name,
      token: server.access_token,
      actualFrom: playlist.actualInfo ? playlist.actualInfo?.actualFrom : playlist.from,
      actualTo: playlist.actualInfo ? playlist.actualInfo?.actualTo : playlist.to,
      unlocked: isUnlocked,
      privacy: hasPrivacy,

    }

    if (exportFeaturesPickerPayload && exportFeaturesPickerPayload.privacy) {
      exportRequest = {
        ...exportRequest,
        privacyData: privacyData,
      }
    }

    if (exportTimeStampWatermarkSupported) {
      exportRequest = {
        ...exportRequest,
        showWatermark: exportFeaturesPickerPayload.watermark ? exportFeaturesPickerPayload.watermark : false,
        showTimeStamp: exportFeaturesPickerPayload.showTimeStamp ? exportFeaturesPickerPayload.showTimeStamp : false,
        timestampPositionX: exportFeaturesPickerPayload.timestampPositionX ? exportFeaturesPickerPayload.timestampPositionX : "",
        timestampPositionY: exportFeaturesPickerPayload.timestampPositionY ? exportFeaturesPickerPayload.timestampPositionY : "",
        timestampColor: exportFeaturesPickerPayload.timestampColor ? exportFeaturesPickerPayload.timestampColor : "",
        timestampBoxEnabled: exportFeaturesPickerPayload.timestampBoxEnabled ? exportFeaturesPickerPayload.timestampBoxEnabled : "0",
        timestampBoxColor: exportFeaturesPickerPayload.timestampBoxColor ? exportFeaturesPickerPayload.timestampBoxColor : "",
        additionalText: exportFeaturesPickerPayload.addCameraName ? `${playlist.descr} ` : '',
        timestampFontSize: exportFeaturesPickerPayload.timestampFontSize ? exportFeaturesPickerPayload.timestampFontSize : "",
        serverTimeZone: playlist.serverTimeZone ? playlist.serverTimeZone : "",
      }
    }

    if (hasPrivacy && !exportPrivacySupported) {
      ArtecoToast("error", t('EXPORT_PRIVACY_NOT_SUPPORTED'));
      addDiagnosticsEvent({
        params: "EXPORT_PRIVACY_NOT_SUPPORTED",
        email: this.props.auth.user.email,
        serverCodename: server.codeName,
        category: 'system'
      })

    } else {
      exportVideo(exportRequest, userPrefs);
    }
  }

  hasPrivacy = (metadataHistory) => {
    const hasPrivacy = metadataHistory && metadataHistory.length > 0 &&  metadataHistory.find(meta => meta.metadata.find(meta => meta.metadataType === 'PrivacyZone'));
    return hasPrivacy;
  }
  requestVideoExport = () => {

    const { showExportFeaturesPopup, metadataHistory, device, exportTimeStampWatermarkSupported } = this.props;

    if (exportTimeStampWatermarkSupported) {
      showExportFeaturesPopup(true, this.doExport, [device]);
      return;
    }

    if ( this.hasPrivacy(metadataHistory)) {
      showExportFeaturesPopup(true, this.doExport, [device])
    } else {
      this.doExport();
    }
  }

  switchToInstant = (Minutes, Seconds) => {
    const { device, setChannelMode } = this.props;

    //temp
    const hourDelay = 0;
    const instantFrom = intervalOperations(getCurrentServerTime(), `0${hourDelay}:${Math.floor(Minutes).toString().padStart(2, '0')}:${Math.floor(Seconds).toString().padStart(2, '0')}`, 'subtract');
    const instantTo = intervalOperations(getCurrentServerTime(), `0${hourDelay}:00:00`, 'subtract');

    const mode = {
      mode: `instant`,
      from: instantFrom,
      to: instantTo
    }

    //GA TRACK
    gtag('event', `instant_player_${Minutes}_minutes_${Seconds}_seconds`);
    setChannelMode(mode, device.artecoId)
  }

  switchToLive = () => {
    const { device, setChannelMode } = this.props;

    const mode = {
      mode: `live`,
      from: undefined,
      to: undefined
    }

    setChannelMode(mode, device.artecoId)
  }

  isPlayback = () => {
    const { playlistId } = this.props;
    const response = (playlistId != undefined);
    return response;
  }

  isAllowBackToLive = () => {
    const { allowBackToLive } = this.props;
    return allowBackToLive;
  }

  componentWillUnmount() {
    const { playlistId, device, updatePlaylistStatus, removeCustomListener, KeyDownLiveCameraListenerExist, playerControlListenerExist, listeners } = this.props;    

    this.playerInstance && this.playerInstance.setVolume(0); // mutato player quando viene smontato
    this.destroy();

    if (this.props.KeyDownLiveCameraListenerExist) {
      this.props.removeCustomListener(customEvents.onKeyDownLiveCamera, this.onKeyDown, listeners);
    }

    if (this.props.playerControlListenerExist) {
      this.props.removeCustomListener(customEvents.playerControl, this.playerExternalControl, listeners);
    }

    //timers
    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer);
      this.loadingTimer = null;
    }
    if (this.errorTimer) {
      clearTimeout(this.errorTimer);
      this.errorTimer = null;
    }
    if (this.highlightTimer) {
      clearTimeout(this.highlightTimer);
      this.highlightTimer = null;
    }
    if (this.selectedTimer) {
      clearTimeout(this.selectedTimer);
      this.selectedTimer = null;
    }

    //refs
    this.nodeRef.current = null;
    this.containerRef.current = null;
    this.zoomWrapperRef.current = null;

    //  nell'unmout del componente provvedo a comunicare che la playlist NON è più in play e NON ha più errori
    playlistId && updatePlaylistStatus({
      artecoId: device.artecoId,
      playlistId: playlistId,
      playing: false,
      isError: false,
      mode: 'live',
    });

    ////console.log(">>>> UNMOUNT | Player for device " + device.artecoId + "unmounted");
  }

  privacyHasChanged(nextProps, props) {
    const priv1 = JSON.stringify(nextProps.channelPrivacyData);
    const priv2 = JSON.stringify(props.channelPrivacyData);
    return priv1 !== priv2;
  }

  shouldComponentRender() {
    const { device } = this.props;
    const { mounted } = this.state

    if (!mounted) return false;
    if (!device) return false;
    return true;
  }

  // shouldComponentUpdate(nextProps, nextState) {


  //   if (
  //     nextProps.source !== this.props.source ||
  //     nextProps.server?.access_token !== this.props.server?.access_token ||
  //     nextProps.channelMode?.mode !== this.props.channelMode?.mode ||
  //     nextProps.eventNum !== this.props.eventNum ||
  //     nextProps.shouldIZoom !== this.props.shouldIZoom ||
  //     nextProps.isTranscoding !== this.props.isTranscoding ||
  //     nextProps.exportStatus !== this.props.exportStatus ||
  //     nextProps.downloadProgress !== this.props.downloadProgress ||
  //     nextProps.isDownloading !== this.props.isDownloading ||
  //     nextProps.activeLayout?.layoutMode !== this.props.activeLayout?.layoutMode ||
  //     nextProps.activeLayout?._id !== this.props.activeLayout?._id ||
  //     nextProps.channelZoomAndPan?.scale !== this.props.channelZoomAndPan?.scale ||
  //     // nextProps.device !== this.props.device ||
  //     nextProps.device?.enabled !== this.props.device?.enabled ||
  //     nextProps.device.running !== this.props.device.running ||
  //     nextState.zoomDisabled !== this.state.zoomDisabled ||
  //     nextState.zoomed !== this.state.zoomed ||
  //     nextState.isError !== this.state.isError ||
  //     nextState.mounted !== this.state.mounted ||
  //     nextState.vodCheck !== this.state.vodCheck ||
  //     nextState.isBuffering !== this.state.isBuffering ||
  //     nextState.isFullscreen !== this.state.isFullscreen ||
  //     this.privacyHasChanged(nextProps, this.props)
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }

  componentDidUpdate(prevProps, prevState) {
    const { shouldIZoom, zoomDone, source, isPopup, activeLayout, device, server, auth , isAudioActive} = this.props;
    const { isFullscreen } = this.state;

    if (prevState.isFullscreen !== isFullscreen) {
      const event = new CustomEvent(customEvents.isFullScreen, {
        detail: {
          isFullScreen: isFullscreen,
        },
      });
      document.dispatchEvent(event);
    }

    ////console.log(">>>> UDATE | Player for device " + device.artecoId + "updated");

    if ((prevProps.eventNum !== this.props.eventNum) && !this.isPlayback()) {
      if (this.nodeRef && this.nodeRef.current) this.nodeRef.current.classList.remove('no-highlight');
      if (this.nodeRef && this.nodeRef.current) this.nodeRef.current.classList.add('highlight');

      let foundChannel;
      if (activeLayout) foundChannel = activeLayout.channels.find(channel => channel.artecoId == device.artecoId);
      // If channel is in layout restart the counter
      if (activeLayout && activeLayout.popupMode && activeLayout.popupMode.active) {
        if (foundChannel && foundChannel.clicked == false) {
          this.timer = activeLayout.popupMode.time
          const timeEvent = new CustomEvent(device.artecoId)
          document.dispatchEvent(timeEvent)
        }
      }

      this.highlightTimer = setTimeout(() => {
        if (foundChannel && foundChannel.clicked == false && activeLayout.popupMode) {
          this.nodeRef.current && this.nodeRef.current.classList.remove('no-highlight');
        } else {
          this.nodeRef.current && this.nodeRef.current.classList.remove('highlight');
          this.nodeRef.current && this.nodeRef.current.classList.add('no-highlight');
        }
      }, 10000)
    }

    if (this.props.error != undefined && prevProps.error !== this.props.error) {
      logger(info, 'player', ">>> player " + this.props.device.descr + " HLS player error " + JSON.stringify(this.props.error));
      if (!this.state.isError) {
        this.setState(
          {
            isError: true,
          }, () => {
            this.props.playlistId && this.props.updatePlaylistStatus({
              artecoId: device.artecoId,
              playlistId: this.props.playlistId,
              playing: false,
              isError: true,
            });
          }
        )
      }

    }

    if (shouldIZoom && this.nodeRef.current) {
      this.nodeRef.current.parentNode.parentNode.scrollTop = this.nodeRef.current.offsetTop;
      this.nodeRef.current.classList.add('located');
      this.selectedTimer = setTimeout(() => {
        this.nodeRef.current && this.nodeRef.current.classList.remove('located');
        zoomDone();
      }, 2000)
    }


    if (prevProps.exportStatus !== this.props.exportStatus) {
      this.playerInstance.core._options.exportStatus = this.props.exportStatus;
      this.playerInstance.core.mediaControl.trigger(Events.MEDIACONTROL_RENDERED)
    }

    if ((prevProps.downloadProgress !== this.props.downloadProgress) || (prevProps.isDownloading !== this.props.isDownloading)) {
      this.playerInstance.core._options.downloadProgress = this.props.downloadProgress;
      this.playerInstance.core._options.isDownloading = this.props.isDownloading;
      this.playerInstance.core.mediaControl.trigger(Events.MEDIACONTROL_RENDERED)
    }

    if (prevState.mounted !== this.state.mounted && this.state.mounted) {

      this.loadThePlayer('mounted');
    }
    if ((prevProps.auth.loading !== auth.loading)) {
      this.loadThePlayer('auth loading');

    }

    if (
      prevProps.source !== source ||
      prevProps.server?.access_token !== server?.access_token ||
      this.privacyHasChanged(this.props, prevProps)
    ) {

      // cambio di una sorgente, devo distruggere e ricreare !
      this.setState({
        vodCheck: false,
      });

      this.loadThePlayer('access token changed or privacy changed');

      // qui aggiorno la playlist nel caso di un play fatto da un instant, necessario per aggiornare le playlist allo stato di default
      // altrimenti la funzion allowPauseCameras darebbe risultati sbagliati in quanto al cambio di una sorgente alcune playlist risulterebbero
      // ancora in play quando realmente non lo sono

      prevProps.playlistId && this.props.updatePlaylistStatus({
        artecoId: device.artecoId,
        playlistId: prevProps.playlistId,
        playing: false,
        isError: false,
        mode: 'live',
      })

    }

    //zoom reset
    if (!isPopup) {
      if (
        prevState.zoomDisabled !== this.state.zoomDisabled ||
        prevState.zoomed !== this.state.zoomed ||
        prevProps.channelZoomAndPan?.scale !== this.props.channelZoomAndPan?.scale
      ) {
        this.zoomWrapperRef.current && this.resetTransform(this.zoomWrapperRef.current);
      }
    }

      //set audio
    if(
       prevProps.isAudioActive !== this.props.isAudioActive
      || device.artecoId !== prevProps.device.artecoId 
    ){
      if(this.playerInstance ){
      this.playerInstance.core._options.isAudioActive = isAudioActive;
      this.playerInstance.core.mediaControl.trigger(Events.MEDIACONTROL_RENDERED)
      const volumeVal = isAudioActive ? 100:0;
      this.playerInstance.setVolume(volumeVal);
      }
    }

  }

  loadAndPlay = () => {
    const { device, playlistId, updatePlaylistStatus, source, timelineMode, server } = this.props;
    const { tempSeekTo } = this.state;


    if (source == undefined) {

      return;
    }

    if (this.playerInstance == undefined) {

      return;
    }

    this.playerInstance.stop();

    this.playerInstance.core._options.isPlayback = this.isPlayback(); // forzo il cambiamento del props figlio del componente
    this.playerInstance.core._options.exportStatus = this.props.exportStatus;
    this.playerInstance.core._options.downloadProgress = this.props.downloadProgress;
    this.playerInstance.core._options.isDownloading = this.props.isDownloading;
    this.playerInstance.core._options.isAudioActive = this.props.isAudioActive;
    // fake video with oudio track 'https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8'
    let newSource = source;
    this.playerInstance.load(newSource); // carico la sorgente

    if (tempSeekTo) {
      this.seekPlayer(tempSeekTo); // seek ad un determinato istante temporale
    }



    this.setState({
      progressLimitFired: false, // nuovo video, limite non raggiunto
      maxPlaylistLimitReached: false,
    })

    playlistId && updatePlaylistStatus({
      artecoId: device.artecoId,
      playlistId: playlistId,
      playing: false,
      isError: false,
      mode: 'live',
    })

  }

  onZoom = () => {
    const { activeLayout, device, setLayoutZoomAndPan, isPopup } = this.props;

    if (!activeLayout) { return };
    if (!this.zoomWrapperRef.current) { return };

    const env = window.location.pathname;
    const currentScale = (this.zoomWrapperRef.current.state.scale <= 1) ? {
      positionX: 0,
      positionY: 0,
      previousScale: 1,
      scale: 1
    } : this.zoomWrapperRef.current.state;
    const zoomSetting = (env === appPaths.recordings) ?
      { "rec": currentScale } :
      { "live": currentScale };

    const zoomChannels = activeLayout.channels.map(channel => {
      if (channel.artecoId !== device.artecoId) return channel;


      return {
        ...channel,
        zoomAndPan: zoomSetting
      }
    })

    const layoutZoomAndPan = {
      ...activeLayout,
      channels: zoomChannels
    }

    if (!isPopup) {
      setLayoutZoomAndPan(layoutZoomAndPan);
    }
    this.setState({
      zoomed: zoomSetting.live?.scale > 1
    });
  }

  resetTransform = (zoomWrapper, zoomOut = false) => {
    const { activeLayout, device, setLayoutZoomAndPan, isPopup } = this.props;

    if (!activeLayout) { return };

    const env = window.location.pathname;
    const zoomSetting = (env === appPaths.recordings) ? {
      "rec": {
        positionX: zoomOut ? 0 : zoomWrapper.instance.props.initialPositionX,
        positionY: zoomOut ? 0 : zoomWrapper.instance.props.initialPositionY,
        scale: zoomOut ? 1 : zoomWrapper.instance.props.initialScale,
        previousScale: zoomOut ? 1 : zoomWrapper.instance.props.initialScale
      }
    } : {
      "live": {
        positionX: zoomOut ? 0 : zoomWrapper.instance.props.initialPositionX,
        positionY: zoomOut ? 0 : zoomWrapper.instance.props.initialPositionY,
        scale: zoomOut ? 1 : zoomWrapper.instance.props.initialScale,
        previousScale: zoomOut ? 1 : zoomWrapper.instance.props.initialScale
      }
    }

    const zoomChannels = activeLayout.channels.map(channel => {
      if (channel.artecoId !== device.artecoId) return channel;

      return {
        ...channel,
        zoomAndPan: zoomSetting
      }
    })

    const layoutZoomAndPan = {
      ...activeLayout,
      channels: zoomChannels
    }

    if (!isPopup) {
      setLayoutZoomAndPan(layoutZoomAndPan);
    }

    this.setState({
      zoomed: zoomWrapper.instance.props.initialScale > 1
    }, () => {
      zoomWrapper.resetTransform();
    })
  }


  onKeyDown = (event) => {
    // controlli tastiera full screen in live (quindi controlla il registrato di un instant player)

    const detail = event.detail;

    let doc = window.document;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) { return; } // solo se sono in fullscreen
    if (this.currentSecondTime == null) { return; } // non posso controllare un tempo nullo

    let seekToSecond = (this.currentSecondTime + 1); // devo aggiugnere 1 perchè il 'currentSecondTime' è

    switch (detail) {
      case "left":
        seekToSecond = seekToSecond - 5;
        break;
      case "right":
        seekToSecond = seekToSecond + 5;
        break;
      case "space":
        if (!this.playerInstance.core.activePlayback.el.paused) {
          this.pausePlayer();
        } else {
          this.playPlayer();
        }
        break;
      default:
        break;
    }
    if (seekToSecond >= 0) {
      this.seekPlayer(Math.floor(seekToSecond));
    }
  }

  resetZoom = () => {
    this.zoomWrapperRef.current && this.resetTransform(this.zoomWrapperRef.current, true);
  }

  simulateEndOfVideo = () => {
    const { reachedEndOfVideo } = this.props;
    reachedEndOfVideo(this.currentServerTime, this.currentSecondTime);
  }

  simulateProgressReached = () => {
    const { requestNextPlaylist } = this.props;
    requestNextPlaylist(this.currentServerTime);
  }

  destroy = () => {
    ////console.log(">>>>>> DESTROY THE PLAYER");
    if (this.playerInstance) {
      this.playerInstance.destroy();
      this.playerInstance = null;
    }
  }

  handleRightClick(e,shouldIBlur){
    if(shouldIBlur){
      e.preventDefault();
    }
    return true
  }

  render() {

    const { isError, zoomDisabled, zoomed, isBuffering, isFullscreen, metadataLoaded, mounted } = this.state;

    const { metadataHistory, isTranscoding, t, activeLayout, channelZoomAndPan, isPopup, device,
      source, playerClass, channelMode, allowBackToLive, hideContextMenu, disableZoom, forcePlayBackMode, instantInterval, playlistId,downloadProgress, isDownloading, channelPrivacyShown, channelMetadataShown, server, hidePrivacy, onvifMetadataHistory, isWaitingMeta, origin, marker, serverIsConnecting, isAudioActive ,isPrivacyPopUpOn} = this.props;

    if (!this.shouldComponentRender()) {
      return <>Nothing to render</>
    }

////console.log(">>>>>> RENDER THE PLAYER");
    ////console.log(">>>> RENDER | Player for device " + device.artecoId + "rendered (mounted: " + mounted + ")");

    const isInPlayBack = (forcePlayBackMode == true || this.isPlayback()) ? 'playback' : (channelMode ? channelMode.mode : 'live');
    const clapprClass = `clappr ${isInPlayBack}`;
    const playerId = `player_${device.artecoId}`;
    const protectedPlayerClass = playerClass ? playerClass : '';
    const eventHighlight = this.nodeRef.current && this.nodeRef.current.classList.contains('highlight') ? 'highlight' : '';
    const isDebug = false; //put it true only if you want to see the debug info on the player
    const debugClass = isDebug ? 'debug' : '';
    const bufferingClass = isBuffering ? 'buffering' : '';
    const fullScreenClass = isFullscreen ? 'custom-fullscreen' : '';
    const isConnectingClass = serverIsConnecting ? 'connecting' : '';
    const iphoneClass = (isIOS && isMobileOnly) ? 'iphone' : '';
    const shouldAddMetaLayer = (isInPlayBack === 'playback') ? (channelMetadataShown !== false) && (!!onvifMetadataHistory || isWaitingMeta) : false;
   
    //privacy
    let privacyClass = ( (isInPlayBack === 'playback' && this.hasPrivacy(metadataHistory) && !hidePrivacy) || (device.privacyPlgEnabled && !hidePrivacy)) ? 'privacy-on': '';
    let shouldIBlur = false;
    
    if(privacyClass === 'privacy-on'){
      const hasPrivacyCredential = isCredentialEnabled(CamerasPrivacyBlur,device);
      shouldIBlur = !hasPrivacyCredential || (hasPrivacyCredential && channelPrivacyShown)
    }
    
    shouldIBlur = shouldIBlur || isPrivacyPopUpOn;
     
    return (
      <div className={`arteco-player ${isConnectingClass} ${eventHighlight} ${debugClass} ${isInPlayBack} ${protectedPlayerClass} ${bufferingClass} ${fullScreenClass} ${privacyClass} ${iphoneClass} `}>
        {isDebug && <ClapprNerdStats
          playlistId={playlistId}
          device={device}
          simulateEndOfVideo={this.simulateEndOfVideo}
          simulateProgressReached={this.simulateProgressReached}
          instantInterval={instantInterval}
          source={source}
        />}

        <div ref={this.containerRef} className={`clappr ${isInPlayBack} ${protectedPlayerClass}`} onContextMenu={(e) => { this.handleRightClick(e,shouldIBlur)}}>

          <ArtecoPlayerTimeBadge
            isTranscoding={isTranscoding}
            artecoId={device.artecoId}
            hideContextMenu={hideContextMenu}
            isPlayback={this.isPlayback()}
            zoomed={zoomed}
            channelZoomAndPan={channelZoomAndPan}
            resetZoom={this.resetZoom}
            origin={origin}
            marker={marker}
          />


          {(this.isPlayback() && !this.state.vodCheck) && (
            <div className="waiting-spinner">
              <div className="spinner-container">
                <Spinner />
              </div>
            </div>
          )}

          <TransformWrapper
            ref={this.zoomWrapperRef}
            disabled={zoomDisabled || disableZoom}
            panning={{
              disabled: activeLayout?.layoutMode === 'free' || !zoomed,
            }}
            onZoomStop={this.onZoom}
            onPanningStop={this.onZoom}
            initialPositionX={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.positionX : 0}
            initialPositionY={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.positionY : 0}
            initialScale={(channelZoomAndPan && !isPopup) ? channelZoomAndPan.scale : 1}
          >
            <TransformComponent>
              {
                ((shouldAddMetaLayer) && this.playerInstance && this.nodeRef.current && !shouldIBlur) &&
                <ResizableContainer>
                  <OnvifMetaDisplay
                    metadataLoaded={metadataLoaded}
                    videoWidth={this.playerInstance.core.activePlayback.el.videoWidth}
                    videoHeight={this.playerInstance.core.activePlayback.el.videoHeight}
                    nodeRef={this.nodeRef.current}
                    onvifMetadataHistory={onvifMetadataHistory}
                    video={this.playerInstance.core.activePlayback.el}
                    traceBorder={true}
                    currentServerTime={this.currentServerTime}
                    liveOrRec={isInPlayBack}
                    artecoId={device.artecoId}
                    shouldAddMetaLayer={shouldAddMetaLayer}
                    server={server}
                    device={device}
                    isPopup={isPopup}
                  />
                </ResizableContainer>
              }
              <div id={playerId} ref={this.nodeRef} className={clapprClass}>
                {(downloadProgress > 0 && channelMode?.mode === 'instant' && isDownloading) && (
                  <span className="badge badge-warning download-progress">Downloading: {`${downloadProgress}%`}</span>
                )}
                {(isError && allowBackToLive && this.isPlayback()) && (
                  <div className="back-to-live-error">
                    <button className="back-to-live btn" onClick={this.switchToLive}>{t('BACK_LIVE_LB')}</button>
                  </div>
                )}
              </div>
            </TransformComponent>
          </TransformWrapper>
          { 
            (shouldIBlur && this.playerInstance && this.nodeRef.current) &&
            <PrivacyDisplay shouldAddPrivacyLayer={shouldIBlur} />
          } 
          </div > 
        </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  const userId = getAuthenticationUserId(state);
  const isOmniaLight = OmniaLight(state);
  const userPrefs = getUserPrefs(state, userId);
  const ownArtecoId = ownProps.device.artecoId;
  const server = getParentServerByArtecoId(state, ownProps.device.artecoId);
  const serverIsConnecting = server && serverIsLoggingIn(server);
  const exportPath = getExportPath(state, ownProps.playlistId);
  const keyDownListenerExist = listenerExists(state, 'keydown');
  const playerControlListenerExist = listenerExists(state, customEvents.playerControl);
  const KeyDownLiveCameraListenerExist = listenerExists(state, customEvents.onKeyDownLiveCamera);
  const listeners = getListeners(state);
  const exportTimeStampWatermarkSupported = isTimestampAndWatermarkSupported(state);
  const isAudioActive = getIsAudioActiveByArtecoId(state,ownProps.device.artecoId);
  const channel = getChannel(state, ownProps.device.artecoId);   
  

  return {
    isOmniaLight,
    auth: getAuthentication(state),
    userPrefs,
    eventNum: getEventsNumForDevice(state, ownArtecoId),
    shouldIZoom: deviceShouldZoom(state, ownArtecoId),
    channelMode: getChannelMode(state, ownArtecoId),
    channelPrivacyData: getPrivacyData(state, ownArtecoId),
    channelPrivacyShown: getChannelPrivacyShown(state, ownArtecoId),
    channelMetadataShown: getChannelMetadataShown(state, ownArtecoId),
    channelZoomAndPan: getChannelZoomAndPan(state, ownArtecoId, window.location.pathname),
    error: getPlaylistError(state, ownArtecoId),
    exportStatus: getExportStatus(state, ownProps.playlistId),
    exportPath,
    activeLayout: getActiveLayout(state),
    server,
    exportPrivacySupported: isExportPrivacySupported(state),
    downloadProgress: fileDownloadProgress(state, exportPath),
    isDownloading: fileIsDownloading(state, exportPath),
    isWaitingMeta: getMetaPending(state, ownArtecoId),
    server,
    keyDownListenerExist,
    playerControlListenerExist,
    KeyDownLiveCameraListenerExist,
    listeners,
    exportTimeStampWatermarkSupported,
    serverIsConnecting,
    isAudioActive,
    channel,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    updatePlaylistStatus: (payload) => dispatch(updatePlaylistStatus(payload)),
    zoomDone: () => dispatch(zoomDone()),
    setChannelMode: (mode, artecoId) => dispatch(setChannelMode(mode, artecoId)),
    exportVideo: (exportRequest, userPrefs) => dispatch(exportVideo(exportRequest, userPrefs)),
    showExportFeaturesPopup: (show, callback, devices) => dispatch(showExportFeaturesPopup(show, callback, devices)),
    updateDownloadList: (path, userId, userPrefs) => dispatch(updateDownloadList(path, userId, userPrefs)),
    setLayoutZoomAndPan: (layoutZoomAndPan) => dispatch(setLayoutZoomAndPan(layoutZoomAndPan)),
    getFile: (path, auth, userPrefs) => dispatch(getFile(path, auth, userPrefs)),
    addCustomListener: (event, cb) => dispatch(addCustomListener(event, cb)),
    removeCustomListener: (event, cb, listeners) => dispatch(removeCustomListener(event, cb, listeners)),
    updateLayout: (layout) => dispatch(updateLayout(layout)),
    addDiagnosticsEvent: (event) => dispatch(addDiagnosticsEvent(event)),
    updateChannel: (layout, updatedChannel) => dispatch(updateChannel(layout, updatedChannel)),
    setCameraInFullscreen: (state) => dispatch(setCameraInFullscreen(state)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ClapprPlayer))