import React, { Component } from 'react';
import { connect } from "react-redux";

//actions
import { getAllDevices, getConnectedServers, getConnectedServersCodenames, getLinkedPeripherals, getLoginQueue, getServerFetchesComplete, isLinkedPeripheralsShow, shouldEnableNewEventFilter } from "../reducers/serversReducer";
import { forceLogin, notifyDevice, setServerOffline, forceLoginAll, addNewChannel,addNewChannelPending,addNewChannelError, setServerBackOnline, updateServerTimeMisMatch, disconnectServer, completeLogin } from '../actions/serverActions';

//helpers
import { updateSocketStatus } from '../actions/serverActions';
import ArtecoServer from './ArtecoServer';


import { getUserPrefs } from '../reducers/userPrefsReducer';
import { eventMatchsFilters, processEvents } from '../helpers/event';
import { eventsBatchUpdate, addEvents, getDiagnosticsEventsByServer, addDiagnosticsEvent } from '../actions/eventsAction';
import {updateRecordingEvts} from '../actions/recordingsActions';
import { externalCommands, socketEvents } from '../config/eventsAndStorage';
import { ARTECO_CAM_CODE, ARTECO_PER_CODE, LOGIN_SUCCESS } from '../actions/types';
import fetchEvents from '../actions/fetchEvents';
import { getActiveLayout, getActiveLayoutCamsWithPrivacy, getActiveLayoutChannelsIds, getActiveLayoutPeripherals, getActiveLayoutPeripheralsIds, getActiveLayoutPTZs, getActiveLayoutServerCodenames, getPinsInSight } from '../reducers/layoutsReducer';
import { wsGetGetPeripheralPayload, wsGetPrivacyDataPayload, wsGetPtzInfoPayload } from '../components/dashboard/wsActionsPayloads/wsPayloads';
import { info, logger } from '../helpers/logger';

import { Howl } from 'howler';
import eventAudio from '../components/dashboard/Chat/Sounds/event.mp3';
import { amIOffline } from '../reducers/networkStatusReducer';
import { CHANNEL_PROFILES, groupDevicesByCodename, isConnected, onlyPresentPeripherals } from '../helpers/server';
import { getViewMode } from '../reducers/eventsReducer';
import { refreshPlayers } from '../components/ArtecoPlayer/ArtecoPlayerMethods';
import checkIsPwa from 'check-is-pwa';
import { updateIntegrationStatus, updateIntegrationsList } from '../actions/integrationsAction';
import { processIntegrations } from '../helpers/integrations';
import { getAuthentication } from '../reducers/authReducer';
import { getFilterSelected } from '../reducers/filtersReducer';
import { ArtecoToast } from '../components/Toast/ArtecoToast';
import { withTranslation } from 'react-i18next';

const eventSound = new Howl({
  src: [eventAudio],
  loop: false,
  preload: true
})

class ServerConnectionManager extends Component {
  constructor(props) {
    super(props);

    this.MAX_RECONNECTION_ATTEMPTS = 20;
    this.RECONNECTION_CHECK_TIMEOUT = 30000;
    this.connectionCounts = {};
    this.openConnections = [];    

    this.receivingEvents = {};    
    this.receivingIntegrations = {};    

    this.tabInFocus = true;
    this.reconnectionDone = false;

    this.autoLogin = process.env.REACT_APP_AUTOLOGIN  === 'true' || false;

    this.LONG_RECONNECTION_TIMEOUT = 30 * 60 * 1000; //30 minutes
  }

  componentDidMount() {
    const { forceLoginAll, auth } = this.props
    this.checkConnections();

    //autoLogin
    // setTimeout(() => {
    //   forceLoginAll(auth.user.id)
    // }, 3000)

    //Document listeners
    //OVERINGENEERING - DI FATTO FANNO LA STESSA COSA
    document.addEventListener(externalCommands.kseniaSendCommand, this.externalCommandsListener);

    document.addEventListener(socketEvents.controlPeripheral, this.peripheralListener);

    document.addEventListener(socketEvents.eventManagement, this.artecoEventsListener);

    document.addEventListener(socketEvents.controlPTZ, this.ptzListener);

    document.addEventListener(socketEvents.configureChannel, this.channelListener);
    document.addEventListener(socketEvents.configureCreateChannel, this.createChannelListener);

    const self = this;
    const isPWA = checkIsPwa();

    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState === 'visible') {
        // La scheda è tornata attiva                
        refreshPlayers();        
        if(isPWA) {     
          window.location.reload();          
        } else {
          //reconnect sockets after focus lost
          self.refreshWebsockets();
        }

      } else {
        self.tabInFocus = false; //lasciamo perchè può essere utile in futuro
      }
    });

    
  }
  componentWillUnmount() {
    document.removeEventListener(socketEvents.configureCreateChannel, this.createChannelListener);

    document.removeEventListener(externalCommands.kseniaSendCommand, this.externalCommandsListener);
    document.removeEventListener(socketEvents.controlPeripheral, this.peripheralListener);
    document.removeEventListener(socketEvents.eventManagement, this.artecoEventsListener);
    document.removeEventListener(socketEvents.controlPTZ, this.ptzListener);
    document.removeEventListener(socketEvents.configureChannel, this.channelListener);
    document.removeEventListener(socketEvents.configureCreateChannel, this.createChannelListener);
  }

  socketConnected = (artecoServer) => {
   
    if (!artecoServer) return false;    
    return artecoServer.socketConnected();
  }

  isConnecting = (artecoServer) => {
    if (!artecoServer) return false;
    return artecoServer.isConnecting();
  }

  someLocalCheckChange(oldServers, newServers) {

    if (newServers.length === 0) return 0;

    var check = oldServers.map((el, index) => { return el.isLocal !== newServers[index].isLocal ? 1 : 0 })
    var hasDiff = check.reduce((a, b) => a + b, 0)
    return hasDiff;
  }

  someTokenChange(oldServers, newServers) {

    if (newServers.length === 0) return 0;

    var check = oldServers.map((el, index) => { return el.access_token !== newServers[index].access_token ? 1 : 0 })
    var hasDiff = check.reduce((a, b) => a + b, 0)

    return hasDiff;
  }

  someSessionChange(oldServers, newServers) {

    if (newServers.length === 0) return 0;

    var check = oldServers.map((el, index) => { return el.sessionId !== newServers[index].sessionId ? 1 : 0 })
    var hasDiff = check.reduce((a, b) => a + b, 0)
    return hasDiff;
  }

  serversChanged(prevProps) {
    return (
      prevProps.servers.length !== this.props.servers.length ||
      prevProps.fetchedServers !== this.props.fetchedServers ||
      (prevProps.servers.length === this.props.servers.length && this.someLocalCheckChange(this.props.servers, prevProps.servers)) ||
      (prevProps.servers.length === this.props.servers.length && this.someTokenChange(this.props.servers, prevProps.servers)) ||
      (prevProps.servers.length === this.props.servers.length && this.someSessionChange(this.props.servers, prevProps.servers))
    )
  }

  peripheralsAreDifferent(oldPeripherals, newPeripherals) {
    const oldIds = oldPeripherals.map(per => per.artecoId).sort();
    const newIds = newPeripherals.map(per => per.artecoId).sort();

    return JSON.stringify(oldIds) !== JSON.stringify(newIds);
  }

  serverRefreshed(prevProps) {
    return (
      prevProps.servers.length === this.props.servers.length && this.someTokenChange(this.props.servers, prevProps.servers) ||
      prevProps.servers.length === this.props.servers.length && this.someSessionChange(this.props.servers, prevProps.servers)
    )
  }

  peripheralsChanged(prevProps) {
    return (
      prevProps.peripherals.length !== this.props.peripherals.length ||
      ((prevProps.peripherals.length === this.props.peripherals.length) && this.peripheralsAreDifferent(this.props.peripherals, prevProps.peripherals)) ||
      prevProps.pinsInSight !== this.props.pinsInSight
    )
  }

  ptzChanged(prevProps) {
    return (
      prevProps.ptzs.length !== this.props.ptzs.length      
    )
  }

  camsWithPrivacyChanged(prevProps) {
    return (
      prevProps.camsWithPrivacy.length !== this.props.camsWithPrivacy.length      
    )
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.auth.user.id !== this.props.auth.user.id ||
      nextProps.servers.length !== this.props.servers.length ||
      nextProps.fetchedServers !== this.props.fetchedServers ||
      (nextProps.servers.length === this.props.servers.length && this.someLocalCheckChange(this.props.servers, nextProps.servers)) ||
      (nextProps.servers.length === this.props.servers.length && this.someTokenChange(this.props.servers, nextProps.servers)) ||
      (nextProps.servers.length === this.props.servers.length && this.someSessionChange(this.props.servers, nextProps.servers)) ||
      nextProps.peripherals.length !== this.props.peripherals.length || 
      nextProps.pinsInSight !== this.props.pinsInSight ||
      nextProps.ptzs.length !== this.props.ptzs.length ||
      nextProps.camsWithPrivacy.length !== this.props.camsWithPrivacy.length ||
      nextProps.isOffline !== this.props.isOffline || 
      nextProps.isGetLinkedPeripherals !== this.props.isGetLinkedPeripherals
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if(this.serversChanged(prevProps) || this.peripheralsChanged(prevProps) || this.ptzChanged(prevProps)) {
      this.checkConnections();
    }

    if(this.serverRefreshed(prevProps) || this.peripheralsChanged(prevProps)) {
      this.checkPeripherals();
    }

    if(this.serverRefreshed(prevProps) || this.ptzChanged(prevProps)) {
      this.checkPTZs();
    }

    if(this.serverRefreshed(prevProps) || this.camsWithPrivacyChanged(prevProps)) {
      this.checkCamsWithPrivacy();
    }

    if(prevProps.auth.user.id && !this.props.auth.user.id) {
      logger(info, "user", "user logged out");
      this.closeAllConnections();
    }

    if(prevProps.isOffline !== this.props.isOffline) {
      if(this.props.isOffline) {
        logger(info, "user", "application offline");
        this.closeAllConnections();
      } else {
        this.checkConnections();
        logger(info, "user", "application back online");
      }
    }
  }

  refreshServerConnections = () => {
    const { servers, forceLogin } = this.props;    

    if (servers.length > 0) {
      servers.map(server => {
        forceLogin({_id: server._id})
      })
    }
  }

  refreshWebsockets = () => {
    const { servers, ServerConnections } = this.props; 
    const self = this; 
    if (servers.length > 0) {
      servers.map(server => {
        const serverToReconnect = ServerConnections[server._id] ? ServerConnections[server._id].serverRef : null;
        serverToReconnect && self.setUpConnection(serverToReconnect);
      })
    }
  }


  checkConnections = () => {
    const { servers, ServerConnections } = this.props;
    
    if (servers.length > 0) {
      servers.map(server => {
        this.setUpConnection(server);

        //debug
        // setTimeout(() => {
        //   this.abortConnection(server._id)
        // }, 10000)
      })
    }

    //check for sockets to be closed
    const sockets = Object.keys(ServerConnections);

    if (sockets.length > 0) {
      sockets.map(serverId => {
        const server = servers.find(server => server._id === serverId);

        if(!server) {
          //console.log(">>>>>> Server : already removed");
        }
        if (!server || !isConnected(server)) {
          this.shutDownConnection(serverId, server?.codeName);
        }
      })
    }    
  }  

  closeAllConnections = () => {
    const { servers, ServerConnections } = this.props;
    const sockets = Object.keys(ServerConnections);

    if (sockets.length > 0) {
      sockets.map(serverId => {
        const server = servers.find(server => server._id === serverId);

        if (server && isConnected(server)) {
          this.shutDownConnection(serverId, server?.codeName);
        }
      })
    }  

    document.removeEventListener(externalCommands.kseniaSendCommand, this.externalCommandsListener);
    document.removeEventListener(socketEvents.controlPeripheral, this.peripheralListener);
    document.removeEventListener(socketEvents.eventManagement, this.artecoEventsListener);
    document.removeEventListener(socketEvents.controlPTZ, this.ptzListener);
    document.removeEventListener(socketEvents.configureChannel, this.channelListener);
    document.removeEventListener(socketEvents.configureCreateChannel, this.createChannelListener);

  }

  externalCommandsListener = (event) => {
    this.sendCommands(event);
  }
  peripheralListener = (event) => {
    this.setChangeInPeripheral(event);
  }
  ptzListener = (event) => {
    this.controlPTZ(event);
  }
  artecoEventsListener = (event) => {
    this.sendLiveEvent(event);
  }
  channelListener = (event) => {
    this.configureChannel(event);
  }
  createChannelListener = (event) => {
   this.configureCreateChannel(event)
  }

  queryServerEvents = (server, userPrefs) => {
    const { ServerConnections, updateServerTimeMisMatch, t, addDiagnosticsEvent, auth } = this.props;
    if(!server) {
      logger(info, 'events', ">>> server null error fetching last events");
      return false;
    }
    if(!this.socketConnected(ServerConnections[server._id])) {
      logger(info, 'events', ">>> server " + server.name + " socket disconnected error fetching last events");
      return false;
    }

    if(server && this.socketConnected(ServerConnections[server._id])) {
      server
      .getLastEvents(userPrefs)
      .then((events) => {    
        const queueElement = this.props.loginQueue.find(queueElement => queueElement.codeName === server.codeName);
        if(queueElement && queueElement.status === "disconnected") {
          //the server has been disconnected while waiting for the events
          return false;
        }

        logger(info, 'socket', ">>> server " + server.name + " got last events"); 
        this.updateEvents(events, server.serverRef, 'query');
        const timeMismatch = events.data.root.result?.warn === "clientSrvTimeMismatch" ? true : false;
        const serverTimeMismatchError = `${server.name && server.name.replace('Server: ', '')}: ${t(`SERVER_TIME_MISMATCH_ERROR`)}`;
        timeMismatch && ArtecoToast("warning", serverTimeMismatchError);
        timeMismatch && addDiagnosticsEvent({
          params: "SERVER_TIME_MISMATCH_ERROR",
          email: auth.user.email,
          serverCodename: server.codeName,
          category: "system",
        });
        updateServerTimeMisMatch(server._id, timeMismatch)
      })
      .catch(error => {
        logger(info, 'events', ">>> server " + server.name + " error fetching last events");
        //debugger;
      })
    }
  }

  getServerIntegrations = (server) => {
    const { ServerConnections } = this.props;
    if(!server) {
      logger(info, 'events', ">>> server null error fetching integrations");
      return false;
    }
    if(!this.socketConnected(ServerConnections[server._id])) {
      logger(info, 'events', ">>> server " + server.name + " socket disconnected error fetching integrations");
      return false;
    }

    if(server && this.socketConnected(ServerConnections[server._id])) {
      server
      .getIntegrations()
      .then(res => {     
        const answer = res.data;
        
        if(res.status === 200 && answer.statusCode === 200) {  
          const integrations = answer.data;
          this.updateIntegrationsList(integrations, server.serverRef, 'query');
          
        } else {
          logger(info, 'events', ">>> server " + server.name + " error in the integrations response");  
        }     
        logger(info, 'socket', ">>> server " + server.name + " got active integrations");                 
      })
      .catch(error => {
        logger(info, 'events', ">>> server " + server.name + " error fetching integrations");
        //debugger;
      })
    }
  }

  setUpConnection = (server) => {
    const { ServerConnections, updateSocketStatus, forceLogin, userPrefs, servers, notifyDevice, auth, addNewChannel,addNewChannelError, setServerBackOnline, disconnectServer, completeLogin, getDiagnosticsEventsByServer } = this.props;           
    
    //remove wrong (my/lan) connections
    if (ServerConnections[server._id] && !this.socketConnected(ServerConnections[server._id]) && !this.isConnecting(ServerConnections[server._id])) {      
      ServerConnections[server._id].closeConnection();
      
      updateSocketStatus(server._id, server.codeName, false);
      delete ServerConnections[ServerConnections[server._id]];
    }
    
    const self = this;
    if (!this.socketConnected(ServerConnections[server._id])&& !this.isConnecting(ServerConnections[server._id])) {
      
      ServerConnections[server._id] = new ArtecoServer(server);
      logger(info, 'socket', ">>> server " + server.name + " (" + server._id + ") Arteco Server Created");
      
      ServerConnections[server._id].connectSocket();

        //----------------------//
        //-----SOCKET OPEN-----//
        //--------------------//

        //fetch events from DB

        ServerConnections[server._id].addSocketListener('open', (e) => {
          logger(info, 'socket', ">>> server " + server.name + " socket open");
          updateSocketStatus(server._id, server.codeName, true);          


          //-----EVENTS-----
            this.receivingEvents[server._id] = true;
            this.queryServerEvents(ServerConnections[server._id], userPrefs);
            getDiagnosticsEventsByServer(auth.user.email, server.codeName, userPrefs.timeOfRetrieval );

            ///------EVENTS---
            fetchEvents(auth.user.id, [server]);
            ///------EVENTS---

            const completeConnections = servers.filter(server => this.socketConnected(ServerConnections[server._id]));   
            logger(info, 'socket', ">>> server" + completeConnections.length + " connected");         

            setServerBackOnline(ServerConnections[server._id]);

            //qui controlliamo se la richiesta di login è ancora in coda (quindi valida) o per caso è stata annullata da un'altra sorgente
            const queueElement = self.props.loginQueue.find(queueElement => queueElement.codeName === server.codeName);
           // console.log(">>>>>> Server : " + server.name + " - LOGIN SUCCESS - " ,queueElement.status);
            if(queueElement && (queueElement.status === "connecting"  || queueElement.status === "connected")) {
              completeLogin(server.codeName);
            } else {
              if(queueElement) {
                //console.log(">>>>>> Server : " + server.name + " - login finito, ma nel frattempo era stata fatta un'altra richiesta di disconnessione, ri-disconnetto",queueElement);
                 self.props.disconnectServer(server._id, server.codeName);       
                 this.shutDownConnection(server._id, server.codeName);      
              }
            }

            
            //-----EVENTS-----
            
            //-----INTEGRATIONS-----
            this.receivingIntegrations[server._id] = true;
            if(server.capabilities && server.capabilities.integrationApi) {              
              this.getServerIntegrations(ServerConnections[server._id]);
            }
            //-----INTEGRATIONS-----
            
            
            this.initialCheck(server.codeName);            
        });

        //----------------------//
        //-----SOCKET OPEN-----//
        //--------------------//



        //----------------------//
        //-----LIVE SOCKET-----//
        //--------------------//
      
        ServerConnections[server._id].addSocketListener('message', (e) => {
          let message = {};
          try {
            message = JSON.parse(e.data);
          } catch (e) {
            //console.log(e);
          }       
          
          const messageRoot = message.root;

          if(!messageRoot) return false;
          
          ///------EVENTS------//
          if(messageRoot.hasOwnProperty('events')) {
            if(this.props.viewMode === 'stop') {            
              if(this.receivingEvents[server._id] ) { 
                logger(info, 'events', ">>> server " + ServerConnections[server._id].name + " drop events");
                this.receivingEvents[server._id] = false;
              }  
            } 


            if(this.props.viewMode !== 'stop') {
              if(!this.receivingEvents[server._id] ) {
                this.receivingEvents[server._id]  = true;
                logger(info, 'events', ">>> server " + ServerConnections[server._id].name + " query events");
                this.queryServerEvents(ServerConnections[server._id], this.props.userPrefs);
              }
              this.updateEvents(message, ServerConnections[server._id].serverRef, 'live');
              if(this.props.userPrefs && this.props.userPrefs.audioEvts) {
                //limit the sound to the events that match the event panel filters 
                const eventToCheck = messageRoot?.events?.event[0];
                const activeLayoutIds = this.props.enableNewEventFilter ? [...this.props.activeLayoutPeripheralsIds, ...this.props.activeLayoutChannelsIds] : this.props.activeLayoutChannelsIds;
                const eventMatchesFilters= eventToCheck &&  eventMatchsFilters(eventToCheck,this.props.selectedFilters, this.props.serverDevices, activeLayoutIds,  true, undefined, [], true, [], this.props.enableNewEventFilter);
                if(eventMatchesFilters ){
                  eventSound.play(); 
                }           
              }
            }
          }
          ///------EVENTS------//
          
          ///------PERIPHERALS------//
          if(messageRoot.hasOwnProperty('peripheral')) {

            const mainNode = messageRoot.peripheral.event;
            if (mainNode.ctx == "changedPeripheral" || mainNode.ctx == "getPeripheral") {
              const artecoId = `${mainNode.serverCodename}_${ARTECO_PER_CODE}_${mainNode.hDev}`;

              const found = this.props.peripherals.find(peripheral => peripheral.artecoId === artecoId);

              if(found) {

                const payload = {
                  artecoId: artecoId,
                  status: mainNode.state,
                  type: mainNode.ctx
                }                            
                notifyDevice(payload);

              }

              if(!found) {
                //check for everywhere

                let pinFound = null;
                let parentPeripheral = null;

                this.props.peripherals.map(peripheral => {
                    const pins = peripheral.visiblePins;
                    const pin = pins && Array.isArray(pins) && pins.find(pin => parseInt(pin.id) === mainNode.hDev);
                
                    if(pin) {
                      parentPeripheral = peripheral;
                      pinFound = pin;
                    }
                })

                if(pinFound) {
                  const payload = {
                    artecoId: parentPeripheral.artecoId,
                    childId: parseInt(pinFound.id),
                    status: mainNode.state,
                    type: mainNode.ctx
                  }                            
                  notifyDevice(payload);
                }

              }

            }
            ///------PERIPHERALS------//
          } else {

            //forward messages to devices
            const lane = messageRoot.lane;

            const data = messageRoot.data;
            if(!data) return false;

            const ctx = data.ctx;

            const artecoId = `${data.serverCodename}_${ARTECO_CAM_CODE}_${data.chId}`;

            const payload = {
              artecoId: artecoId,
              ctx: ctx,
              sequenceStatus: data.sequenceStatus,
              sequenceId: data.sequenceId,
              presetStatus: data.presetStatus,
              presetId: data.presetId,
              status: data.status,
              lane: lane,
              action: data.action,
              requestedAction: data.requestedAction,
            }                 
            
            //PTZ Command
            if(
              ctx === "ptzInfoCommand"
            ) {
              notifyDevice(payload);
            }
            
            //PTZ Sequences/Presets
            if(
              ctx === "ptzInfoSequence" || ctx === "ptzInfoPreset"              
            ) {
              /*
              Sequences status:
              0: delete sequence
              1: add/save sequence
              2: run sequence
              3: delete all sequences
              4: stop sequence

              Presets status:
              0: delete preset
              1: add preset
              2: goto preset
              3: delete all presets
              */

              if(
                data.sequenceStatus === 2 ||
                data.sequenceStatus === 4 ||
                data.presetStatus === 2
              ) {
                notifyDevice(payload)
              }

              //if sequences/preset list has been modified, refresh login to that server (it's quicker)
              if(
                data.sequenceStatus === 0 ||
                data.sequenceStatus === 1 ||
                data.sequenceStatus === 3 ||
                data.presetStatus === 0 ||
                data.presetStatus === 1
              ) {
                forceLogin({ _id: server._id });
              }
            }

            //PTZ Real time sequence status
            if(ctx === "ptzInfoPresetSequence") {
              const ptzList = data.dome_config;
              if(Array.isArray(ptzList) && ptzList.length > 0) {
                ptzList.map(ptz => {

                  if(ptz.activeSequenceId) {                    

                    const artecoId = `${data.serverCodename}_${ARTECO_CAM_CODE}_${ptz.chId}`;

                    const payload = {
                      lane: lane, 
                      artecoId: artecoId,
                      ctx: "ptzInfoSequence",
                      sequenceStatus: 2,
                      sequenceId: ptz.activeSequenceId,
                    } 
                    
                    notifyDevice(payload);
                  }
                })
              }
            }

            if(
              ctx === "chlSetConfig"
            ) {

              //TODO Check errori
              const artecoId = `${data.serverCodename}_${ARTECO_CAM_CODE}_${data.chId}`;


              const payload = {
                lane: lane, 
                artecoId: artecoId,
                ctx: "chlSetConfig",
                serverCodename: data.serverCodename,
                channel: data.channel
              } 

              if(data.status === "fieldsError") {
                payload.status = "error"
              }

              notifyDevice(payload);
            }
             // update and add property in camera
            if (ctx === "chInfo") {
              const artecoId = `${data.serverCodename}_${ARTECO_CAM_CODE}_${data.chId}`;
              let payload = {
                lane,
                artecoId,
                serverCodename: data.serverCodename,
              };

              switch (data.state) {
                case "chStarted":
                  payload = {
                    ...payload,
                    ctx: "chStarted",
                    streams: data.streams,
                    profiles: CHANNEL_PROFILES(data.streams),
                    running: 1,
                    chId: data.chId,
                  };
                  break;
                case "chStopped":
                  payload = {
                    ...payload,
                    ctx: "chStopped",
                    running: 0,
                    chId: data.chId,
                  };
                  break;
                case "chConfigChanged":
                case "chAdded":
                  payload = {
                    ...payload,
                    ctx: data.state,
                    channel: data.channel,
                  };
                  break;
                case "chRemoved":
                  payload = {
                    ...payload,
                    ctx: "chRemoved",
                    chId: data.chId,
                  };
                  break;
                default:
                  payload={}
                  break;
              }
              payload && notifyDevice(payload);
            }

            //create a camera
            if(
              ctx === "chAddNew"
            ) {
              //TODO Check errori

              if(data.status === "fieldsError" ||
               data.status === "srvNumNhLicenseFull" || 
               data.status === "unknowSrcType" || 
               data.state === "atcSrcTypeMissing" || 
               data.state === "genericError" ||
               data.state === "chlApplyConfigError") {

                addNewChannelError({errorStatus : data.status});

              }else{
                const artecoId = `${data.serverCodename}_${ARTECO_CAM_CODE}_${data.channel.id}`;

                const payload = {
                  lane: lane, 
                  artecoId: artecoId,
                  ctx: "chAddNew",
                  serverCodename: data.serverCodename,
                  channel: data.channel
                }
                addNewChannel(payload)
              }
            }

            if(
              ctx === "getPrivacyLive" ||
              ctx === "chMetadataInfo"
            ) {

              //TODO Check errori
              const artecoId = `${data.serverCodename}_${ARTECO_CAM_CODE}_${data.chId}`;
              const privacyData = data.metadata && data.metadata.find(meta => meta.metadataType === "PrivacyZone") || {};

              const payload = {
                lane: lane, 
                artecoId: artecoId,
                ctx: "getPrivacyLive",
                serverCodename: data.serverCodename,
                channel: {
                  id: data.chId,
                  privacyData: privacyData,
                  privacyPlgEnabled: Object.keys(privacyData).length !== 0 ? 1 : 0,
                }
              } 

              if(data.status === "fieldsError") {
                payload.status = "error"
              }

              notifyDevice(payload);
            }

          }
        })

        //----------------------//
        //-----LIVE SOCKET-----//
        //--------------------//




        //----------------------//
        //-----SOCKET CLOSE----//
        //--------------------//
        ServerConnections[server._id].addSocketListener('close', (e) => {  
          logger(info, 'socket', ">>> server " + server?.name + " socket closed - message received CODE: " + e.code);

          if (ServerConnections[server._id] !== null && ServerConnections[server._id] != 'closed' && !this.isConnecting(ServerConnections[server._id])) {

            //console.log(`>>: Socket closed for ${ServerConnections[server._id].name} - ${ServerConnections[server._id]}`);

              //unauthorized 
              if (ServerConnections[server._id].nodeServer && e.code === 1014) {
  
               setTimeout(() => {
                if (!this.connectionCounts[server._id] || (this.connectionCounts[server._id] < this.MAX_RECONNECTION_ATTEMPTS)) {
                  this.connectionCounts[server._id] = this.connectionCounts[server._id] ? this.connectionCounts[server._id] + 1 : 1;
                  logger(info, 'socket - closed', ">>> server " + server.name + " session expired 1014: > auto login - attempt " + this.connectionCounts[server._id] + " / " + this.MAX_RECONNECTION_ATTEMPTS);
                  
                  //tag server as disconnected while waiting for a new attempt
                  updateSocketStatus(server._id , server.codeName, false);
                  forceLogin({ _id: server._id });
                } else {
                  logger(info, 'socket - closed', ">>> server " + server.name + " session expired 1014: > too much attempts - abort connection + setup lonng reconnection");
                  this.abortConnection(server._id, server?.codeName);
                  this.setUpLongTermReconnection(server);
        
                }
                
              }, this.RECONNECTION_CHECK_TIMEOUT);
              }
              

              //error 1006
              const self = this;
              if (ServerConnections[server._id] && (!(ServerConnections[server._id].nodeServer) || e.code === 1006 || e.code === 1000)){
  
               logger(info, 'socket - closed', ">>> server " + server.name + " Error "+e.code+"");
                setTimeout(function () {
                  if(!self.connectionCounts[server._id] || (self.connectionCounts[server._id] < self.MAX_RECONNECTION_ATTEMPTS)) {
                    self.connectionCounts[server._id] = self.connectionCounts[server._id] ? self.connectionCounts[server._id] + 1 : 1;
                    logger(info, 'socket - closed', ">>> server " + server.name + " ERROR "+e.code+": > retry connection - attempt " + self.connectionCounts[server._id] + " / " + self.MAX_RECONNECTION_ATTEMPTS);
  
                    const serverToReconnect = ServerConnections[server._id] ? ServerConnections[server._id].serverRef : null;
                    serverToReconnect && self.setUpConnection(serverToReconnect);
                  } else {
                    logger(info, 'socket - closed', ">>> server " + server.name + " ERROR "+e.code+": > too much attempts - abort connection + setup long reconnection");
                    self.abortConnection(server._id, server?.codeName);
                    self.setUpLongTermReconnection(server);
                  }
                }, this.RECONNECTION_CHECK_TIMEOUT);
               
              }

            //error 1005 (connection close on timeout from ArtecoServer.js)     
            if (ServerConnections[server._id] && e.code === 1005) {
              logger(info, 'socket - closed', ">>> server " + server.name + " Error 1005 - Auto close");
              
              //tag server as disconnected while waiting for a new attempt
              updateSocketStatus(server._id , server.codeName, false);

              this.reconnectionDone = true; //lo lascio perchè può essere utile per ottimizzazioni
              forceLogin({ _id: server._id });
              // if(self.props.activeLayoutServers && self.props.activeLayoutServers.includes(server.codeName)) {                
              //   logger(info, 'socket', ">>> server " + server.name + " ERROR 1005: > refresh connection");
              // } else {
              //   logger(info, 'socket', ">>> server " + server.name + " ERROR 1005: > nothing on the active layout");
              // }
              
            }

            if(!e.code){
              logger(info, 'socket', ">>> server " + server.name + " socket close no code - reload");   
              window.location.reload();
            }
          }
        });
        //----------------------//
        //-----SOCKET CLOSE----//
        //--------------------//




        //----------------------//
        //-----SOCKET ERROR----//
        //--------------------//

        const self = this;
        ServerConnections[server._id].addSocketListener('error', (e) => {
          logger(info, 'socket - error listener', ">>> server " + server.name + " Error - abort connection");   
          
          this.abortConnection(server._id, server?.codeName);
          
          const updatedServer = self.props.servers.find(server => server._id === server._id);          
          this.setUpReconnection(updatedServer);
        });

        //----------------------//
        //-----SOCKET ERROR----//
        //--------------------//
    }

  }


  setUpLongTermReconnection = (server) =>{
    const { ServerConnections,updateSocketStatus, forceLogin} = this.props; 
      const self = this;

      setTimeout(() => {
        if(!ServerConnections[server._id] || (ServerConnections[server._id] && ServerConnections[server._id].socketClosed())) {  
          this.connectionCounts[server._id] = 0;  
          logger(info, 'socket', `>>> server ${server.name}: > long-term retry connection after 30 minutes`);
          
          updateSocketStatus(server._id , server.codeName, false)
          forceLogin({ _id: server._id });                   
        }
      }, self.LONG_RECONNECTION_TIMEOUT)

  }

  setUpReconnection = (server) => {
    const { ServerConnections, } = this.props;    
    
    if(server){
      logger(info, 'socket', ">>> server " + server.name + " Setup reconnection timer. Try reconnecting in " + this.RECONNECTION_CHECK_TIMEOUT + "ms");   
      setTimeout(() => {
        if(!ServerConnections[server._id] || (ServerConnections[server._id] && ServerConnections[server._id].socketClosed())) {      
          //this.connectionCounts[server._id] = 0;        
          this.setUpConnection(server);              
          logger(info, 'socket', ">>> server " + server.name + " Try reconnect after timer");       
        }
      }, this.RECONNECTION_CHECK_TIMEOUT)
    }else{
      window.location.reload();
    }
  }

  abortConnection = (serverId, serverCodeName) => {
    const { ServerConnections, updateSocketStatus, setServerOffline } = this.props;    
    if (ServerConnections[serverId]) {
      if (!ServerConnections[serverId].socketClosed()) ServerConnections[serverId].closeConnection();
      
      setServerOffline(ServerConnections[serverId]);
      //ServerConnections[serverId] = null;
      updateSocketStatus(serverId,serverCodeName, false);

      this.initialCheck();
    }
  }

  shutDownConnection = (serverId, serverCodeName) => {
    const { ServerConnections, updateSocketStatus } = this.props;
    if (ServerConnections[serverId] && this.socketConnected(ServerConnections[serverId])) {
      if (!ServerConnections[serverId].socketClosed()) ServerConnections[serverId].closeConnection();
      
      ServerConnections[serverId] = null;
      updateSocketStatus(serverId, serverCodeName, false);
      //console.log(`>>: Socket closed for ${serverId}`);

      this.initialCheck();
    }
  }

  initialCheck = (serverCodename = null) => {

    ///------PERIPHERALS---
    this.checkPeripherals(serverCodename);
    ///------PERIPHERALS---

    ///------PTZS---
    this.checkPTZs(serverCodename);
    ///------PTZS---

    ///------Privacy---
    this.checkCamsWithPrivacy(serverCodename);
    ///------Privacy---
  }

///---------------- EVENTS -------------------------//
  
  updateEvents = (eventBatch, server, source) => {   

    const { serverDevices, auth, eventsBatchUpdate, addEvents, viewMode,updateRecordingEvts, updateIntegrationStatus } = this.props

    const eventList = eventBatch.data ? eventBatch.data.root.events : eventBatch.root.events;

    if (!eventList || !Array.isArray(eventList.event) || eventList.event.length === 0) {
      //console.log(`>>: No events ${server.name} - ${server._id}`);
      return false;
    }

    const events = eventList.event;

    //console.log(`>>: ${server.name}: received ${events.length} events`);
    const visibility = (source === 'live' && viewMode === 'stop') ? 'hidden' : 'visible';
    const processedEvents = processEvents(events, server, serverDevices, auth, visibility);
    
    if(processedEvents.length) {      
      updateRecordingEvts(processedEvents)
      eventsBatchUpdate(processedEvents, this.props.userPrefs.timeOfRetrieval);
    } 

     //integrations update - just for live
     if (processedEvents.length === 1) {
      // const isIntegrationEvent = processedEvents[0] && (processedEvents[0].integrationType || processedEvents[0].eventIntegrationType);
      const isIntegrationEvent = processedEvents[0] && (processedEvents[0]?.eventBrand != "Arteco-Server");

      // if(isIntegrationEvent){
      //   console.log("Evento di integrazione brand = ",processedEvents[0]?.eventBrand)
      // }

      isIntegrationEvent && updateIntegrationStatus(processedEvents[0]);
    }


    // if (this.props.userPrefs.cloudBackup) {
    //   //console.log(`>>: ${server.name}: need to backup in cloud`);
    //   addEvents(processedEvents);
    // }
      
  }

  sendLiveEvent = (e) => {
    const { ServerConnections } = this.props;

    const payloadToSend = e.detail.payload;
    const serverId = e.detail.server_id;
    ServerConnections[serverId] && ServerConnections[serverId].sendMessage(payloadToSend);
  }

  sendCommands = (e) => {
    const { ServerConnections, forceLogin } = this.props;

    const payloadToSend = e.detail.payload;
    const serverId = e.detail.server_id;

    //after add reload integrations    
    const routesWithCallbacks = [
      '/integration/add',
      '/integration/remove'
    ];

    const needsCallback = routesWithCallbacks.some(route => payloadToSend.route.startsWith(route));

    const callbackOk = needsCallback ? () => {forceLogin({_id: serverId})} : null;
    const callbackError = needsCallback ? () => {alert("ERROR")} : null;

    ServerConnections[serverId] && ServerConnections[serverId].sendCommand(payloadToSend, callbackOk, callbackError);
  }

///---------------- EVENTS -------------------------//

///---------------- PERIPHERALS -------------------------//
checkPeripherals = (serverCodename = null) => {
  const { peripherals, groupedPeripherals, servers, ServerConnections } = this.props;

  const peripheralsToCheck = serverCodename ? groupedPeripherals[serverCodename] : peripherals;
  
  if(peripheralsToCheck && peripheralsToCheck.length > 0) {
    peripheralsToCheck.map((peripheral, index) => {
      const timeout = 5;
      // loop principale di tutte le periferiche attive nel layout      
      const payloadToSend = wsGetGetPeripheralPayload(peripheral.id);
      const hasPins = (peripheral.visiblePins && Array.isArray(peripheral.visiblePins) && Object.values(peripheral.visiblePins).length > 0);
      const parentServer = servers.find(server => server.codeName === peripheral.serverCodename);

      if(parentServer) {
        if (!hasPins) {
          setTimeout(() => {
            logger(info, 'socket', ">>> server " + parentServer.name + " get peripherals initial state"); 
            ServerConnections[parentServer._id] && ServerConnections[parentServer._id].sendMessage(payloadToSend);
            //console.log(`P >>: ${parentServer.name} peripheral ${peripheral.id}: request initial state`);
          }, timeout * index);
  
  
        } else {
          if (peripheral.visiblePins) {
            // loop degli eventuali figli
            peripheral.visiblePins.map((pin, indexPin) => {
              const payloadToSend = wsGetGetPeripheralPayload(pin.id);
              setTimeout(() => {
                logger(info, 'socket', ">>> server " + parentServer.name + " get peripheral pins initial state"); 
                ServerConnections[parentServer._id] && ServerConnections[parentServer._id].sendMessage(payloadToSend);
                //console.log(`P >>: ${parentServer.name} peripheral ${peripheral.id} > pin: ${pin.id}: request initial state`);
  
              }, timeout * indexPin);
            })
          }
        }
      }
    })
  }
}

setChangeInPeripheral = (e) => {  
  const { ServerConnections } = this.props;

  const payloadToSend = e.detail.payload;
  const serverId = e.detail.server_id;
  ServerConnections[serverId] && ServerConnections[serverId].sendMessage(payloadToSend);
}
///---------------- PERIPHERALS -------------------------//

///---------------- PTZ -------------------------//
checkPTZs = (serverCodename = null) => {  
  const { ptzs, groupedPtzs, servers, ServerConnections } = this.props;

  const ptzToCheck = serverCodename ? groupedPtzs[serverCodename] : ptzs;

  if(ptzToCheck && ptzToCheck.length > 0) {
    ptzToCheck.map(ptz => {
      
      // loop principale di tutte le periferiche attive nel layout      
      const payloadToSend = wsGetPtzInfoPayload(ptz.id);
      
      const parentServer = servers.find(server => server.codeName === ptz.serverCodename);

      if(parentServer) {
        logger(info, 'socket', ">>> server " + parentServer.name + " get PTZ initial state"); 
        ServerConnections[parentServer._id] && ServerConnections[parentServer._id].sendMessage(payloadToSend);
      }

    })
  }
}

controlPTZ = (e) => {  
  const { ServerConnections } = this.props;

  const payloadToSend = e.detail.payload;
  const serverId = e.detail.server_id;
  ServerConnections[serverId] && ServerConnections[serverId].sendMessage(payloadToSend);
}
///---------------- PTZ -------------------------//

///---------------- CHANNELS -------------------------//
configureChannel = (e) => {  
  const { ServerConnections, notifyDevice } = this.props;

  //put the spinner
  const eventPayload = JSON.parse(e.detail.payload);
  const payload = {
    lane: "manageDevices",
    ctx: "chlSettingConfig",    
    artecoId: eventPayload.data?.artecoId
  }                            
  notifyDevice(payload);
  //put the spinner

  const payloadToSend = e.detail.payload;
  const serverId = e.detail.server_id;
  ServerConnections[serverId] && ServerConnections[serverId].sendMessage(payloadToSend);
  }


checkCamsWithPrivacy = (serverCodename = null) => {  
  const { camsWithPrivacy, servers, ServerConnections } = this.props;

  const camToCheck = serverCodename ? camsWithPrivacy.filter(cam => cam.serverCodename === serverCodename) : camsWithPrivacy;

  if(camToCheck && camToCheck.length > 0) {
    camToCheck.map(cam => {
      
      // loop principale di tutte le periferiche attive nel layout      
      const payloadToSend = wsGetPrivacyDataPayload(cam.id);
      
      const parentServer = servers.find(server => server.codeName === cam.serverCodename);

      if(parentServer) {
        logger(info, 'socket', ">>> server " + parentServer.name + " get Privacy for cam : " + cam.id); 
        ServerConnections[parentServer._id] && ServerConnections[parentServer._id].sendMessage(payloadToSend);
      }

    })
  }
}

///---------------- CHANNELS -------------------------//

///---------------- CREATE NEW CHANNELS -------------------------//
  
configureCreateChannel = (e) => {
  const { ServerConnections, addNewChannelPending } = this.props;
  addNewChannelPending();
  const payloadToSend = e.detail.payload;
  const serverId = e.detail.server_id;
  ServerConnections[serverId] && ServerConnections[serverId].sendMessage(payloadToSend);
  }
///---------------- CREATE NEW CHANNELS -------------------------//

///---------------- INTEGRATIONS -------------------------//
updateIntegrationsList = (integrations, server, source) => {   

  const { updateIntegrationsList } = this.props

  // if (!integrations || !Array.isArray(integrations) || integrations.length === 0) {  
  //   console.log(`>>: No integrations ${server.name} - ${server._id}`);
  //   return false;
  // }

  //console.log(`>>: ${server.name}: received ${integrations.length} integrations`);
  const processedIntegrations = processIntegrations(integrations, server);
  updateIntegrationsList(processedIntegrations, server);  
}
///---------------- INTEGRATIONS -------------------------//




  render() {
    return (
      <></>
    )
  }
}


const mapStateToProps = (state) => {  
  const auth = getAuthentication(state);
  const servers = getConnectedServers(state);
  const activeLayoutServers = getActiveLayoutServerCodenames(state);
  const serverDevices = getAllDevices(state);
  const serverCodenames = getConnectedServersCodenames(state);
  const userPrefs = getUserPrefs(state);
  const fetchedServers = getServerFetchesComplete(state);  
  const isGetLinkedPeripherals = isLinkedPeripheralsShow(state);
  const peripherals = isGetLinkedPeripherals ?  getLinkedPeripherals(state, serverCodenames, servers) : onlyPresentPeripherals(getActiveLayoutPeripherals(state), serverCodenames, servers);
  const groupedPeripherals = groupDevicesByCodename(peripherals);
  const ptzs = getActiveLayoutPTZs(state);
  const camsWithPrivacy = getActiveLayoutCamsWithPrivacy(state);
  const groupedPtzs = groupDevicesByCodename(ptzs);
  const pinsInSight = getPinsInSight(peripherals);
  const isOffline = amIOffline(state);
  const viewMode = getViewMode(state);
  const enableNewEventFilter = shouldEnableNewEventFilter(state);
  const selectedFilters = getFilterSelected(state,enableNewEventFilter);
  const activeLayoutChannelsIds = getActiveLayoutChannelsIds(state);
  const activeLayoutPeripheralsIds =getActiveLayoutPeripheralsIds(state);
  const activeLayout = getActiveLayout(state);
  const loginQueue = getLoginQueue(state);

  return {
    auth,
    servers,
    activeLayoutServers,
    serverDevices,
    userPrefs,
    fetchedServers,    
    peripherals,
    groupedPeripherals,
    groupedPtzs,
    pinsInSight,
    isOffline,
    ptzs,
    isGetLinkedPeripherals,
    viewMode,
    camsWithPrivacy,
    selectedFilters,
    activeLayoutChannelsIds,
    activeLayoutPeripheralsIds,
    activeLayout,
    loginQueue
  }
}

export default connect(
  mapStateToProps,
  { updateSocketStatus, forceLogin, eventsBatchUpdate, addEvents, fetchEvents, notifyDevice, updateRecordingEvts, forceLoginAll, setServerOffline, setServerBackOnline, addNewChannel, addNewChannelPending,addNewChannelError, updateIntegrationsList, updateIntegrationStatus, updateServerTimeMisMatch, disconnectServer, completeLogin, getDiagnosticsEventsByServer, addDiagnosticsEvent }    
)(withTranslation()(ServerConnectionManager));