import axios from 'axios';
import {error, logger} from '../helpers/logger';

// gtag
import gtag from "ga-gtag";

import {
  SET_ERRORS,
  LAYOUT_UPDATED,
  LAYOUT_INFO,
  RESET_LAYOUT_GRID,
  SET_LAYOUT_VISUAL_MODE,
  SET_LAYOUT_LOCK,
  SHOW_ONLY_IN_LAYOUT,
  SET_CHANNEL_MODE,
  RESET_CHANNEL_MODE,
  SET_CHANNELS_MODE ,
  LAYOUT_ZOOM_UPDATED,
  LAYOUT_ADDED,  
  LAYOUTS_REMOVED,
  LAYOUTS_TOGGLED,
  NO_ACTIVE,
  LAYOUT_REFRESHED,
  LAYOUT_UPDATED_SESSION,
  LAYOUT_UPDATED_CHANNEL,
  LAYOUT_SELECT_CHANNEL,
  LAYOUT_UNSELECT_CHANNEL,
  LAYOUT_RESET_INCLUDED_CAMERAS,
  TOGGLE_COVER_MODE,
  LAYOUT_RENAME,
  UPDATE_LAYOUTS_ORDER,
  UPDATE_ACTIVE_LAYOUT_CHANNELS,
  ADD_FISHEYE_LOCK_IN_LAYOUT,
  REMOVE_FISHEYE_LOCK_IN_LAYOUT,
  SET_CAMERA_IN_FULLSCREEN,
} from './types';


export const addLayout = (newLayout, history, callback, freeRedirect = false) => dispatch => {
  const evtName = `new_layout_added`;
  gtag('event', evtName);
  const shouldRedirect = !!history;
  axios
    .post('/api/layouts/insert', newLayout)
    .then(res => {

      //save also on local storage
      if (global.localStorage) {
        global.localStorage.setItem('localLayoutSettings', true);        
      }

      //save activeLayout on session storage
      if (global.sessionStorage) {
        if(newLayout.isFromTag) { 
          global.sessionStorage.setItem('activeTagLayout', res.data._id);
        } else {          
          global.sessionStorage.setItem('activeLayout', res.data._id);
        }
      }

      const payload = {
        ...res.data,
        stored: res.data._id
      }

      if(shouldRedirect && !freeRedirect) {
        dispatch({
          type: LAYOUT_INFO,
          payload
        })
      } else {
        dispatch({
          type: LAYOUT_ADDED,
          payload
        })
      }


    })
    .then(res => {
      if(shouldRedirect) history.push(freeRedirect)
      callback && callback();
    })
    .catch(err => dispatch({
      type: SET_ERRORS,
      payload: err
    }))
}

//Change layout name
export const sendChangedLayoutNameToNode = (updatedLayout, callback) => dispatch => {
  const layout = {
    id: updatedLayout._id,
    name: updatedLayout.name,    
    owner: updatedLayout.owner
  }
  axios
    .put('/api/layouts/rename', layout)
    .then(res => {
        const payload = {
          _id: res.data._id,
          label: res.data.label
        }
        dispatch({
          type: LAYOUT_RENAME,
          payload
        })
        callback && callback();
      }
    )
    .catch(err => dispatch({
      type: SET_ERRORS,
      payload: err
    }))
}

// Popup Mode
export const setLayoutPopupMode = (layout, callback) => dispatch =>  {

  axios
    .post('/api/layouts/popup/', layout)
    .then(res => {
      dispatch({
        type: LAYOUT_UPDATED,
        payload: res.data
      })
      
      callback && callback();
    }).catch(err => dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }))
}

export const updateLayout = (updatedLayout, filterJustOnDB = true, callback = null) => dispatch => {
    const layout = {
      ...updatedLayout,
      channels: updatedLayout.channels ? updatedLayout.channels.filter(channel => channel.clicked === true) : []
    }

  axios
    .post('/api/layouts/update/', layout)
    .then(res => {
      dispatch({
        type: LAYOUT_UPDATED,
        payload: {
          ...res.data,
          channels: filterJustOnDB ? updatedLayout.channels : layout.channels
        },
      })
      callback && callback();
    })
    .catch(err => dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }))
}


export const updateLayoutSession = (updatedLayout) => dispatch => {

  dispatch({
    type: LAYOUT_UPDATED_SESSION,
    payload: updatedLayout
  })

}

export const updateChannel = (layout, updatedChannel) => dispatch => {
  axios
  .post('/api/layouts/update-channel/', {layout, channel: updatedChannel})
  .then(res => {
    dispatch({
      type: LAYOUT_UPDATED_CHANNEL,
      payload: {
        layout: layout,
        channel: updatedChannel
      }
    })
  })
  .catch(err => dispatch({
    type: SET_ERRORS,
    payload: err.response.data
  }))
}

export const updateChannelSession = (layout, updatedChannel) => dispatch => {
  dispatch({
    type: LAYOUT_UPDATED_CHANNEL,
    payload: {
      layout: layout,
      channel: updatedChannel
    }
  })
}

export const selectedStaticMap = (currentActiveMap) => dispatch => {
  axios
  .post('/api/layouts/current-active-map', currentActiveMap)
  .then(res => {
    dispatch({
      type: LAYOUT_UPDATED,
      payload: res.data
    })
  })
}


export const setLayoutVisualMode = (layout2Update) => dispatch => {
  axios
    .post('/api/layouts/update/', layout2Update)
    dispatch({
      type: SET_LAYOUT_VISUAL_MODE,
      payload: layout2Update
    })
}

export const setLayoutZoomAndPan = (layoutZoomAndPan) => dispatch => {
  dispatch({
    type: LAYOUT_ZOOM_UPDATED,
    payload: layoutZoomAndPan
  })
}


export const setLayoutLock = (layout2Update) => dispatch => {
  axios
    .post('/api/layouts/update/', layout2Update)
    dispatch({
      type: SET_LAYOUT_LOCK,
      payload: layout2Update
    })
}

function activate(where, layout2activate, dispatch, isFromTag = false, shouldRedirect, history) {
  //save active layout locally
  if (where && where === 'local') {    
    //save on local storage
    if (global.localStorage) {
      if(isFromTag) {
        global.localStorage.setItem('localTagLayoutSettings', true);
      } else {
        global.localStorage.setItem('localLayoutSettings', true);
      }
    }

    //save activeLayout on session storage
    if (global.sessionStorage) {
      if(isFromTag) {
        global.sessionStorage.setItem('activeTagLayout', layout2activate._id);
      } else {
        global.sessionStorage.setItem('activeLayout', layout2activate._id);
      }
    }
    
    const payload = {
      ...layout2activate,
      visible: true,
      isFromTag: isFromTag
    }

    dispatch({
      type: LAYOUT_INFO,
      payload
    })

    if(shouldRedirect) {
      setTimeout(() => {
        history.push(shouldRedirect);
      }, 500)
    }

  } else {

    //un layout creato da tag non può essere settato come default
    //remove local layout settings
    if (global.localStorage) {
      global.localStorage.removeItem('localLayoutSettings');
    }

    if (global.sessionStorage) {
      global.sessionStorage.removeItem('activeLayout');
    }
    //save active layout on DB
    axios
      .post('/api/layouts/activate/', layout2activate)
      .then(res => {

        const payload = {
          ...res.data,
          stored: layout2activate._id
        }
        dispatch({
          type: LAYOUT_INFO,
          payload
        })

        if(shouldRedirect) {
          history.push(shouldRedirect);
        }

      })
      .catch(err => dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      }))
    }
  
}

export const activateLayout = (layout2activate, where, shouldRedirect, history) => dispatch => {
  activate(where, layout2activate, dispatch, layout2activate.isFromTag, shouldRedirect, history);  
}

function alignLocalStorage(activeLayoutChanged, newActiveLayoutId) {
  if(!activeLayoutChanged) return false;

  if(!newActiveLayoutId) {
    if (global.localStorage) {
      global.localStorage.removeItem('localLayoutSettings');
    }
  
    //save activeLayout on session storage
    if (global.sessionStorage) {
      global.sessionStorage.removeItem('activeLayout');
    }  

    return true;
  }

  if (global.localStorage) {
    global.localStorage.setItem('localLayoutSettings', true);
  }

  //save activeLayout on session storage
  if (global.sessionStorage) {
    global.sessionStorage.setItem('activeLayout', newActiveLayoutId);
  }
  return true;

}

export const toggleLayouts = (layouts, visible, owner, activeLayoutId, forceActivation) => dispatch => { 
  axios
    .post('/api/layouts/toggle-all/', {layouts, visible, owner, activeLayoutId, isRemoving: false, forceActivation})
    .then(res => {   
      
      const {activeLayoutChanged, newActiveLayoutId} = res.data;
      alignLocalStorage(activeLayoutChanged, newActiveLayoutId);

      dispatch({
        type: LAYOUTS_TOGGLED,
        payload: res.data
      });
      return res.data;
    })
    .catch(e => {
       logger(error, "toggleLayouts",  e);
    })
}

export const removeLayouts = (layouts, visible, owner, activeLayoutId) => dispatch => {  
  axios
    .post('/api/layouts/toggle-all/', {layouts, visible, owner, activeLayoutId, isRemoving: true})
    .then(res => {   

      const {activeLayoutChanged, newActiveLayoutId} = res.data;
      alignLocalStorage(activeLayoutChanged, newActiveLayoutId);

      dispatch({
        type: LAYOUTS_REMOVED,
        payload: res.data
      });
      return res.data;
    })
    .catch(e => {
       logger(error, "removeLayouts", e, );
    })
}

export const stripElementsFromLayout = (layoutsData) => dispatch => {
  axios
    .post('/api/layouts/remove-server-elements/', layoutsData)
    .then(res => {
      dispatch({
        type: LAYOUT_INFO,
        payload: res.data
      });
      return res.data;
    })
    .catch(e => {
       logger(error, "stripElementFormLayout",e);
    })
}

export const resetLayoutGrid = (reset) => dispatch => {
  dispatch({
    type: RESET_LAYOUT_GRID,
    payload: reset
  })
}


export const showOnlyInLayout = (mode) => dispatch => {
  dispatch({
    type: SHOW_ONLY_IN_LAYOUT,
    payload: mode
  })
}

export const setChannelMode = (mode, artecoId) => dispatch => {
  dispatch({
    type: SET_CHANNEL_MODE,
    payload: {
      artecoId,
      mode
    }
  })
}

export const setChannelsMode = (mode, servers) => dispatch => {
  dispatch({
    type: SET_CHANNELS_MODE,
    payload: {      
      mode,
      servers
    }
  })
}

export const resetChannelMode = (channelMode, from , to) => dispatch => {
  dispatch({
    type: RESET_CHANNEL_MODE,
    payload: {      
      mode : {
        mode: channelMode,
        from: from,
        to: to,
      }
    }
  })
}

export const setNewOrder = (layouts) => dispatch => {
  dispatch({
    type:  UPDATE_LAYOUTS_ORDER,
    payload: layouts
  })
}


export const refreshLayout = (layout2refresh, shouldRedirect, history, userId, isNewShare, isStoppingShare) => dispatch => {
  axios
    .post('/api/layouts/get-by-id/', layout2refresh)
    .then(res => {      
      const layout = {
        ...res.data,
        visible: true,
        ownerName: layout2refresh.ownerName
      };

      if (global.localStorage) {
        global.localStorage.setItem('localLayoutSettings', true);        
      }
  
      //save activeLayout on session storage
      if (global.sessionStorage) {
        global.sessionStorage.setItem('activeLayout', layout._id);        
      }

      dispatch({
        type: LAYOUT_REFRESHED,
        payload: {
          layout: layout,
          userId: userId,
          isNewShare: isNewShare,
          isStoppingShare: isStoppingShare
        }
      })      

      if(shouldRedirect) {
        setTimeout(() => {
          history.push(shouldRedirect);
        }, 500)
      }

    })
    .catch(e => {
      //debugger;
       logger(e);
    })
}

export const selectCamera = (selectedCameraArtecoId) => dispatch => {
  dispatch({
    type: LAYOUT_SELECT_CHANNEL,
    payload: {
      camera: selectedCameraArtecoId
    }
  })
}

export const unselectCamera = (selectedCameraArtecoId) => dispatch => {
  dispatch({
    type: LAYOUT_UNSELECT_CHANNEL,
    payload: {
      camera: selectedCameraArtecoId
    }
  })
}

export const resetIncludedCameras = () => dispatch => {
  dispatch({
    type: LAYOUT_RESET_INCLUDED_CAMERAS,
  })
}

export const toggleCoverMode = (active) => dispatch => {
  //save also in local storage
  if (global.localStorage) {
    global.localStorage.setItem('coverMode', active);        
  }
  dispatch({
    type: TOGGLE_COVER_MODE,
    payload: active
  })
}

export const updateActiveLayoutChannels = (layout, channels) => {
  return async dispatch => {
    // If the layout already has the same channels, do nothing
    if (layout.channels === channels) return;

    const updatedLayout = { ...layout, channels };

    // Dispatch the update to the store
    dispatch({
      type: UPDATE_ACTIVE_LAYOUT_CHANNELS,
      payload: {
        layout: updatedLayout,
        channels
      }
    });

    // Make the API call to update the layout on the server
    try {
      await axios.post('/api/layouts/update/', updatedLayout);
    } catch (err) {
      dispatch({
        type: SET_ERRORS,
        payload: err
      });
    }
  };
};

export const lockFisheyeInLayout = (camera) =>{
  return dispatch => {
    dispatch({
      type: ADD_FISHEYE_LOCK_IN_LAYOUT,
      payload: camera
    });
  }
};

export const unlockFisheyeInLayout = (camera) =>{
  return dispatch => {
    dispatch({
      type: REMOVE_FISHEYE_LOCK_IN_LAYOUT,
      payload: camera
    });
  }
};

export const setCameraInFullscreen = (currentState) => {
  return (dispatch) => {
    dispatch({
      type: SET_CAMERA_IN_FULLSCREEN,
      payload: currentState
    });
  }
}


