import { TAG_LAST_TIMESTAMP } from '../actions/fetchEvents';
import {
  FETCH_SERVERS_PENDING,  
  FETCH_SERVERS_SUCCESS,
  FETCH_SERVERS_ERROR,
  SERVER_REMOVED,
  SERVERS_REMOVED,
  SERVER_ADDED,
  SERVER_INFO,
  SET_CURRENT_SERVER,
  FORCE_LOGIN,
  STOP_LOGIN,
  FORCE_LOGIN_CODENAME,
  FORCE_LOGOUT,
  FORCE_LOGOUT_CODENAME,
  SERVER_DISCONNECT,
  SERVER_DISCONNECT_ALL,
  FORCE_LOGIN_ALL,
  CLEAN_DONE,
  ARTECO_CAM_CODE,
  ARTECO_PER_CODE,
  CAMERA_REGEX,
  PERIPHERAL_REGEX,
  DEVICE_INFO,
  SERVER_TREE_TOGGLE,
  SERVER_TREE_TOGGLE_ALL,
  SET_SERVER_IS_LOCAL,
  SET_SERVER_IS_OFFLINE,
  FETCH_EVENTS_SUCCESS,
  SERVER_UPDATE_USER_LIST,
  SERVERS_UPDATE_USER_LIST,
  UPDATE_SERVER,
  SERVER_UPDATE_SOCKET_STATUS,
  SERVER_UPDATE_USER_POSITION,
  INIT_PERIPHERAL,
  SET_PERIPHERAL,
  PTZ_IS_CHANGING,
  CHANNEL_IS_SET,
  CHANNEL_IS_SETTING,
  LINKED_PERIPHERALS,
  CLOSE_LINKED_PERIPHERALS,
  CHANNEL_UPDATE,
  CHANNEL_ADD,
  CHANNEL_REMOVE,
  DEVICE_INFO_RESET,
  ADD_NEW_CHANNEL_SUCSESS,
  ADD_NEW_CHANNEL_PENDING,
  ADD_NEW_CHANNEL_ERROR,
  CHANNEL_ADD_PROPERTY,
  EMPTY_PASSWORD_SERVER_UDPATE,
  SERVER_UPDATED,
  FETCH_SERVERS_UPDATE,
  FILTER_SERVERS_WITHOUT_LICENCE,
  DUPLICATE_SERIAL_SERVER_REMOVE,
  SERVER_CONNECTNG,
  UPDATE_DEVICE,
  FETCH_SITES_PENDING,
  STORE_SITES,
  UPDATE_TIME_MISMATCH_SERVER,
  LOGIN_REQUEST,
  ABORT_LOGIN,
  LOGIN_SUCCESS
} from '../actions/types';

import { SERVER_EVENTS_REQUEST_COMPLETE, SERVER_EVENTS_PENDING_REQUEST, SERVER_EVENTS_REQUEST_ERROR } from '../actions/fetchEvents';

import { CIVETWEB_ENTRYPOINT } from '../config/backend';
import { info, logger } from '../helpers/logger';
import { getLanAddress } from '../helpers/network';
import { peripheralIsPresent } from './layoutsReducer';

import { isIOS, isMobileOnly } from 'react-device-detect';
import { appPaths } from '../config/paths';
import { isArtecoSerial, isConnected } from '../helpers/server';

const initialState = {  
  pendingServers: false,
  pendingSites: false,
  servers: [],
  sites: undefined,
  devices: {},
  currentServer: {},
  error: null,
  showLinkedPeripheralsPopupData: {},
  filterServersWithOutLicence : false,
  loginQueue: [],
  isDisconnecting: false
}

const isIphone = isIOS && isMobileOnly;


var indexBy = (prop, list) => list.reduce((res, item) => {
  const val = res[item[prop]] || [];

  return { ...res, [item[prop]]: val.concat(item) };
}, {});


var distinctTimes = (input) => {
  const dictionary = indexBy('serverId', input);
  const entries = Object.entries(dictionary);

  const sorted = entries.map(
    ([key, lastUpdateTime]) => lastUpdateTime.sort((a, b) => b.lastUpdateTime - a.lastUpdateTime),
  );

  const heads = sorted.map(([head]) => head);

  const headsAsObject = {};
  heads.map((event) => {
    let key = event.serverId;
    headsAsObject[key] = event.lastUpdateTime;

    return null;
  })

  return headsAsObject;
}

const MOCKED_PRIVACY_DATA = () => {
  const meta = {
    "metadataType": "PrivacyZone",
    "blockSize": 13,
    "frameWidth": 800,
    "frameHeight": 450,
    "blockMode": "BM_Pixel",
    "zones": [
      [
        {
          "pointX": 129,
          "pointY": 201
        },
        {
          "pointX": 140,
          "pointY": 49
        },
        {
          "pointX": 345,
          "pointY": 41
        },
        {
          "pointX": 321,
          "pointY": 208
        },
        {
          "pointX": 321,
          "pointY": 200
        }
      ],
      [
        {
          "pointX": 465,
          "pointY": 406
        },
        {
          "pointX": 481,
          "pointY": 268
        },
        {
          "pointX": 609,
          "pointY": 276
        },
        {
          "pointX": 591,
          "pointY": 403
        }
      ]
    ]
  };

  return meta;
}

var updateDeviceList = (server, out) => {
  if (Array.isArray(server.channels)) {

    server.channels.map(channel => {
      const artecoId = `${server.codeName}_${ARTECO_CAM_CODE}_${channel.id}`;

      //setting additional properties
      //Ready for Nginx      
      channel.hlsStream = (server.nodeServer && !isIphone) ? `${server.protocol}://${server.ip}:${server.port}/hls/${channel.id.toString().padStart(2, '0')}/Live/stream.m3u8` : `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/hls/${channel.id.toString().padStart(2, '0')}/Live/stream.m3u8`;                  
      channel.thumbnail = (server.nodeServer && !isIphone) ? `${server.protocol}://${server.ip}:${server.port}/hls/${channel.id.toString().padStart(2, '0')}/Live/snap.jpg` : `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/hls/${channel.id.toString().padStart(2, '0')}/Live/snap.jpg`;

      channel.artecoId = artecoId;
      channel.type = ARTECO_CAM_CODE;
      channel.serverCodename = server.codeName;
      channel.siteInfo = server.siteInfo;

      channel.updated = true;
      channel.error = false;

      // bug fix AST-5309,temporary fix 
      channel.privacyData = ((channel.privacyPlgEnabled && !channel.privacyData)&& !channel.privacyData )? MOCKED_PRIVACY_DATA() : undefined

      out[artecoId] = channel;

      return true;
    })

  }

  if (Array.isArray(server.peripherals)) {

    server.peripherals.map(peripheral => {
      const artecoId = `${server.codeName}_${ARTECO_PER_CODE}_${peripheral.id}`;
      peripheral.artecoId = artecoId;
      peripheral.type = ARTECO_PER_CODE;
      peripheral.serverCodename = server.codeName;

      out[artecoId] = peripheral;

      return true;
    })

  }


  if (server.name) {
    const serverArtecoId = server.codeName;

    const serverAsDevice = {
      artecoId: serverArtecoId,
      descr: server.name,
      hls: undefined,
      type: 2,
      id: undefined,
      serverCodename: serverArtecoId,
      lat: undefined,
      long: undefined,
      enabled: 1,
      capabilities: server.capabilities
    }

    serverAsDevice["retention-days"] = server["retention-days"] || 1;

    out[serverArtecoId] = serverAsDevice;
  }


  return out;
}

function checkOnLSIfServerIsLocal(serverCodename) {
  if (global.localStorage) {
    const knownHosts = JSON.parse(localStorage.getItem('knownHosts')) || [];

    if (knownHosts.length === 0) return false;

    const serverFromLS = knownHosts.find(host => host.serverCodename === serverCodename);
    return serverFromLS && serverFromLS.isLocal;
  }
}
function decorateServer(server, fetchedDevices = null, state = null, isSync = null) { 
  const serverLicenseType = getServerLicenseType(server);
  const {siteInfo} = server;

  const decoratedServer = {
    ...server,
    channels: server.channels && server.channels.sort(sortByDescr),
    peripherals: server.peripherals && server.peripherals.sort(sortByDescr),
    name: server.name || "Server: " + server.license.serial,
    root: true,
    toggled: false,
    status: (server.uptodate) ? 'online' : 'offline',
    connectionStatus: (server.uptodate) ? 'cached' : 'not-connected',
    icon: serverLicenseType.icon,
    lastDelete: server.lastDelete || 0,
    isLocal: server.codeName ? checkOnLSIfServerIsLocal(server.codeName) : false,
    licenseType: serverLicenseType.licenseType,
    username: siteInfo?.username_arteco_server,    
  }

  let currentServer = null;
  if(isSync) {    
    currentServer = getServerById(state, server._id);
    if(currentServer) { //it's an update
      decoratedServer.socketAttached = currentServer?.socketAttached;
      decoratedServer.connectionStatus = currentServer?.connectionStatus;
      decoratedServer.status = currentServer?.status;
    }
  }


  if (decoratedServer.connectionStatus !== 'not-connected' && fetchedDevices) updateDeviceList(decoratedServer, fetchedDevices);

  return decoratedServer;
}

function getServerLicenseType(server) {
  let licenseType = {
    icon: 'server',
    licenseType: server.licenseType
  }

  if(!server.licenseType || (server.licenseType.toLowerCase() !== 'demo' && server.licenseType.toLowerCase() !== 'subscription')) {
    licenseType = {
      icon: 'onprem',
      licenseType: 'full'
    }
  }

  return licenseType;  
  
}

function sortByDescr(a, b) {
  if (!a.descr || !b.descr) return 0;

  const aString = a.descr.toString().toLowerCase();
  const bString = b.descr.toString().toLowerCase();

  if (aString > bString) {
    return 1;
  }
  if (aString < bString) {
    return -1;
  }
  return 0;
}
function updateServer(newChannel, servers){
  
  const updateServers = servers.map(server => {
    if(server.codeName === newChannel.serverCodename){
     return {
      ...server,
      channels: [...server.channels, newChannel]
     }
    }
    return server
  })
  return updateServers;
}

function updateServerUserlist(servers, codeName, userList) {
  const updatedServers = servers.map((server) => {
    if (server.codeName !== codeName) return server;

    return {
      ...server,
      connectedUsers: userList,
    };
  });

  return updatedServers;
}

function updateCreateChannel(server, action){
  const newChannel = {
    ...action.payload.channel,
    serverCodename: action.payload.serverCodename,
      artecoId: action.payload.artecoId,
      hlsStream : (server.nodeServer && !isIphone) ? `${server.protocol}://${server.ip}:${server.port}/hls/${action.payload.channel.id.toString().padStart(2, '0')}/Live/stream.m3u8` : `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/hls/${action.payload.channel.id.toString().padStart(2, '0')}/Live/stream.m3u8`,                
      thumbnail : (server.nodeServer && !isIphone) ? `${server.protocol}://${server.ip}:${server.port}/hls/${action.payload.channel.id.toString().padStart(2, '0')}/Live/snap.jpg` : `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/hls/${action.payload.channel.id.toString().padStart(2, '0')}/Live/snap.jpg`,
      type : ARTECO_CAM_CODE,
      updated : true,
      error : false,
  }
  return newChannel
}

function removeDevice(obj, prop) {
  delete obj[prop];
  return obj;
}
function updateServersPassword(servers, serversEmptyPassword, password) {
  let updatedServers = JSON.parse(JSON.stringify(servers)); // Create a deep copy of the original servers list

  for (let emptyServer of serversEmptyPassword) {
    const serverToUpdate = updatedServers.find(server => server._id === emptyServer._id);
    if (serverToUpdate) {
      serverToUpdate.password = password; // Update the password
      serverToUpdate.needsRelogin = true
    }
  }

  return updatedServers;
}



function addPropertyToChannel(servers, payload) {
  // Find the server with the given ID
  const server = servers.find(server => server.codename === payload.serverCodename);

  // Find the channel with the given ID within the server
  const channel = server.channels.find(channel => channel.artecoId === payload.artecoId);

  // Add a new property to the channel object
  channel.profiles = payload.profiles;

  // Return the updated servers array
  return servers;
}

export function ServersReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_SITES:
      const sites = action.payload;      
      return {
        ...state,   
        pendingSites: false,     
        sites: sites
      }
    case SERVER_ADDED:
      let serversWithAddition = state.servers.concat(decorateServer(action.payload, null, state));
      return {
        ...state,
        servers: serversWithAddition,
      };
    case INIT_PERIPHERAL:
    case SET_PERIPHERAL:
      const { status, artecoId, childId } = action.payload;

      const initPeripherals = { ...state.devices };
      if (childId) {
        //Arteco Everywhere
        const everywhere = initPeripherals[artecoId];
        const updatedPins =
          everywhere.pins && Array.isArray(everywhere.pins)
            ? everywhere.pins.map((pin) => {
                if (pin.id !== action.payload.childId) return pin;

                return {
                  ...pin,
                  init: 1,
                  status: status,
                };
              })
            : [];

        everywhere.pins = updatedPins;

        initPeripherals[artecoId] = everywhere;
      } else {
        initPeripherals[artecoId].init = 1;
        initPeripherals[artecoId].status = status;
      }

      return {
        ...state,
        devices: initPeripherals,
      };
    case CHANNEL_IS_SETTING:
      const serverUpdatingDevices = { ...state.devices };
      serverUpdatingDevices[action.payload.artecoId].updated = false;

      return {
        ...state,
        devices: serverUpdatingDevices,
      };
    case CHANNEL_IS_SET:
      const serverUpdatedDevices = { ...state.devices };

      if (action.payload.status !== "error") {
        serverUpdatedDevices[action.payload.artecoId] = {
          ...serverUpdatedDevices[action.payload.artecoId],
          ...{ ...action.payload.channel, updated: true, error: false },
        };
      } else {
        serverUpdatedDevices[action.payload.artecoId].updated = true;
        serverUpdatedDevices[action.payload.artecoId].error = true;
      }

      return {
        ...state,
        devices: serverUpdatedDevices,
      };
    case CHANNEL_ADD_PROPERTY:
    const serverUpdatedPropertyDevices = { ...state.devices };
    serverUpdatedPropertyDevices[action.payload.artecoId] = {
      ...serverUpdatedPropertyDevices[action.payload.artecoId],
      profiles: action.payload.profiles !== undefined ? action.payload.profiles : serverUpdatedPropertyDevices[action.payload.artecoId].profiles,
      running: action.payload.running 
    }
    return {
      ...state,
      devices: serverUpdatedPropertyDevices,
    }
    case CHANNEL_UPDATE:
      const serverUpdateDevices = { ...state.devices };
      let updatedServers = state.servers.map((server) => {
        if (server.codeName === action.payload.serverCodename) {
          const updatedChannels = server.channels.map((channel) => {
            if (channel.id === action.payload.channel.id) {
              return {
                ...channel,
                ...action.payload.channel,
              };
            }
            return channel;
          });
          return {
            ...server,
            channels: updatedChannels,
          };
        }
        return server;
      });

      if (action.payload.status !== "error") {
        serverUpdateDevices[action.payload.artecoId] = {
          ...serverUpdateDevices[action.payload.artecoId],
          ...{ ...action.payload.channel, updated: true, error: false },
        };
      } else {
        serverUpdateDevices[action.payload.artecoId].updated = true;
        serverUpdateDevices[action.payload.artecoId].error = true;
      }

      return {
        ...state,
        servers: updatedServers,
        devices: serverUpdateDevices,
      };

    case CHANNEL_ADD: 
    const server = state.servers.find(server => server.codeName === action.payload.serverCodename)
    const existingChannel = server.channels.find(channel => channel.artecoId === action.payload.artecoId);
    const newChannel = updateCreateChannel(server,action)
    const updateServers = !existingChannel ? updateServer(newChannel, state.servers) : state.servers;
    return {
      ...state,
      servers: updateServers,
      devices: !existingChannel ? {...state.devices, [action.payload.artecoId]: newChannel} : state.devices,
    }
    case CHANNEL_REMOVE: 
    const newServers = state.servers.map((server) => {
      if (server.codeName === action.payload.serverCodename) {
        const newChannels = server.channels.filter(
          (channel) => channel.artecoId !== action.payload.artecoId
        );
        return { ...server, channels: newChannels };
      }
      return server;
    });
    const updateDevices = removeDevice(state.devices, action.payload.artecoId);

    return {
      ...state,
      servers: newServers,
      devices: updateDevices
    }

    case ADD_NEW_CHANNEL_PENDING:
      return{
        ...state,
        addNewChannel: {
         pending: true
        }
      }

    case ADD_NEW_CHANNEL_SUCSESS:
      const channelAction = action.payload;
      const newChannelServer = state.servers.find(server => server.codeName === channelAction.serverCodename)
      const existingChannelServer = newChannelServer.channels.find(channel => channel.artecoId === action.payload.artecoId);
      const newAddChannel =  updateCreateChannel(newChannelServer,action)
      const updateChannelServers = !existingChannelServer ? updateServer(newAddChannel, state.servers) : state.servers
      return{
        ...state,
        servers: updateChannelServers,  
        devices: !existingChannelServer ? {...state.devices, [action.payload.artecoId]: newAddChannel} : state.devices,
        addNewChannel:{
          pending: false,
          sucsess: true,
          channel: newAddChannel,
        }
      }
    case ADD_NEW_CHANNEL_ERROR: 
    return {
      ...state,
      addNewChannel:{
        pending:false,
        sucsess: false,
        errorStatus: action.payload.errorStatus
      }
    }
    
    case PTZ_IS_CHANGING:
      const {
        artecoId: ptzArtecoId,
        ctx,
        sequenceStatus,
        sequenceId,
        presetStatus,
        presetId,
        status: ptzStatus,
        action: ptzAction,
        requestedAction,
      } = action.payload;
      const updatePTZs = { ...state.devices };

      //l'unica cosa che sovrascrive lo stato di sequenze (id, status) è ctx=ptzInfoSequence. Se il contesto è diverso, lo stato delle sequenze va lasciato invariato
      const ptzUpdatedStatus = {
        ctx,
        presetStatus,
        presetId,
        ptzStatus,
        action: ptzAction,
        requestedAction,
        sequenceStatus: updatePTZs[ptzArtecoId]?.ptzStatus?.sequenceStatus,
        sequenceId: updatePTZs[ptzArtecoId]?.ptzStatus?.sequenceId,
      };

      if (ctx === "ptzInfoSequence") {
        ptzUpdatedStatus.sequenceStatus = sequenceStatus;
        ptzUpdatedStatus.sequenceId = sequenceId;
      }

      updatePTZs[ptzArtecoId].ptzStatus = ptzUpdatedStatus;

      return {
        ...state,
        devices: updatePTZs,
      };
    case SET_CURRENT_SERVER:
      return {
        ...state,
        currentServer: action.payload,
      };
    case SERVER_EVENTS_PENDING_REQUEST:
      let serverWithPendingEvents = state.servers.map((server) => {
        if (server._id !== action.payload._id) return server;

        return {
          ...server,
          eventsPending: true,
        };
      });

      return {
        ...state,
        servers: serverWithPendingEvents,
      };
    case SERVER_EVENTS_REQUEST_COMPLETE:
      let serverWithCompleteRequests = state.servers.map((server) => {
        if (server._id !== action.payload._id) return server;

        return {
          ...server,
          eventsPending: false,
        };
      });

      return {
        ...state,
        servers: serverWithCompleteRequests,
      };
    case SERVER_EVENTS_REQUEST_ERROR:
      let serverWithErrorRequests = state.servers.map((server) => {
        if (server._id !== action.payload._id) return server;

        return {
          ...server,
          eventsPending: false,
          eventsFetched: false,
          eventsError: true,
        };
      });

      return {
        ...state,
        servers: serverWithErrorRequests,
      };
    case FETCH_EVENTS_SUCCESS:
      let serverWithEvents = state.servers.map((server) => {
        if (server.codeName !== action.payload.serverCodename) return server;

        return {
          ...server,
          eventsFetched: true,
          eventsPending: false,
          eventsError: false,
        };
      });

      return {
        ...state,
        servers: serverWithEvents,
      };
    case UPDATE_SERVER:
    //case SERVER_UPDATED:

      let serverUpdated = state.servers.map((server) => {
        if (server._id !== action.payload._id) return server;
        return {
          ...server,
          ip: action.payload.ip,
          port: action.payload.port,
          username: action.payload.username,
          password: action.payload.password,
          license: action.payload.license || server.license,
          licenseType: server.licenseType,
          icon: action.payload.icon || server.icon,
          name: action.payload.name || server.name,
          needsRelogin: action.payload.needsRelogin || server.needsRelogin          
        };
      });
      return {
        ...state,
        servers: serverUpdated,
      };
    case SERVER_UPDATE_USER_LIST:
      let serverWithUsers = updateServerUserlist(state.servers, action.payload.codeName, action.payload.userList)

      return {
        ...state,
        servers: serverWithUsers,
      };      
    case SERVER_UPDATE_SOCKET_STATUS:
      let serverSocketUpdated = state.servers.map((server) => {
        if (server._id !== action.payload._id) return server;
        logger(
          info,
          "socket",
          ">>> server " +
            server.name +
            " update socket status " +
            action.payload.socketAttached
        );
        return {
          ...server,
          socketAttached: action.payload.socketAttached,
          needsRelogin: action.payload.needsRelogin
        };
      });

      const disconnectionComplete = serverSocketUpdated.filter(server => server.socketAttached);
      const allServerAreDisconnected = (disconnectionComplete && disconnectionComplete.length === 0);
      if(allServerAreDisconnected){
        //console.log(">>>>>> DISCONNECT: All servers are disconnected");
      }
      return {
        ...state,
        servers: serverSocketUpdated,
        isDisconnecting: !allServerAreDisconnected ? false : state.servers.isDisconnecting
      };
    case SERVER_CONNECTNG: 
    const connectingServers = state.servers.map((server, index) => {
      if (server._id !== action.payload._id) { 
        return server
      }
      return {
        ...server,
        connectionStatus: action.payload.connectionStatus
      }
    });
    return {
      ...state,
      servers: connectingServers
    }
    case SERVER_INFO:
      let updatedDevices = {};
      let serversWithInfo = state.servers.map((server, index) => {
        const updatedServer = {
          ...server,
        };
        if (server._id === action.payload._id) {
          const channelOrigin = action.payload.channels
            ? action.payload.channels.channel
              ? action.payload.channels.channel
              : action.payload.channels
            : [];

          //FIX single camera ERROR
          let channelNormalized = channelOrigin;
          if (!Array.isArray(channelOrigin)) {
            channelNormalized = [channelNormalized];
          }

          const peripheralOrigin = action.payload.peripherals
            ? action.payload.peripherals.peripheral
              ? action.payload.peripherals.peripheral
              : action.payload.peripherals
            : [];

          //FIX single camera ERROR + FIX PIN MISMATCH
          let peripheralNormalized = peripheralOrigin;
          if (Array.isArray(peripheralOrigin)) {
            peripheralNormalized = peripheralOrigin.map((peripheral) => {
              if (!peripheral.pins) return peripheral;

              if (peripheral.pins.pin) {
                return {
                  ...peripheral,
                  pins: peripheral.pins.pin,
                };
              }

              return peripheral;
            });
          } else {
            peripheralNormalized = [peripheralOrigin];
          }

          updatedServer.name = action.payload.descr || action.payload.name; // sometimes names are different based on the payload
          updatedServer.channels = channelNormalized.sort(sortByDescr);
          updatedServer.peripherals = peripheralNormalized.sort(sortByDescr);
          updatedServer.timezone = action.payload.timezone;
          updatedServer.status = "online";
          updatedServer.connectionStatus = "connected";
          updatedServer.offline = !!action.payload.offline;
          updatedServer.sessionId =
            action.payload.SessionId || action.payload.sessionId; // sometimes names are different based on the payload
          updatedServer.MediaSecret = action.payload.MediaSecret;
          //STORE TOKEN
          updatedServer.access_token = action.payload.access_token;
          updatedServer.nodeServer = action.payload.nodeServer;
          updatedServer.codeName =
            action.payload.codename || action.payload.codeName; // sometimes names are different based on the payload
          updatedServer.needsRelogin = !!action.payload.needsRelogin; // sometimes names are different based on the payload
          updatedServer.lastDelete = action.payload.lastDelete; // sometimes names are different based on the payload
          updatedServer.software = action.payload.software;
          updatedServer.license = action.payload.license;
          updatedServer.licenseType = action.payload.licenseType;
          updatedServer.capabilities = action.payload.capabilities;
          updatedServer.isLocal = action.payload.isLocal;
          updatedServer.icon = (action.payload.licenseType.toLowerCase() === 'demo' || action.payload.licenseType.toLowerCase() === 'subscription') ? 'server' : 'onprem'

          updateDeviceList(updatedServer, updatedDevices);
        }
        return updatedServer;
      });

      return {
        ...state,
        servers: serversWithInfo,
        devices: { ...state.devices, ...updatedDevices },
      }
    case UPDATE_DEVICE: 
    let updatedSeqeuceDevices = { ...state.devices };
    Object.keys(action.payload).forEach(key => {
      if (updatedSeqeuceDevices.hasOwnProperty(key)) {
        updatedSeqeuceDevices[key].uFollowGui = action.payload[key];
      }
    });
    return {
      ...state,
      devices: updatedSeqeuceDevices
    };
    case FETCH_SERVERS_PENDING:
      return {
        ...state,
        pendingServers: true,
      };
    case FETCH_SITES_PENDING:
      return {
        ...state,
        pendingSites: true,
      };
    case FETCH_SERVERS_SUCCESS:
      let fetchedDevices = {};            
      const {isSync} = action.payload;
      let extServers = action.payload.servers.map((server, index) => {        
        return decorateServer(server, fetchedDevices, state, isSync);
      });

      return {
        ...state,
        pendingServers: false,
        servers: extServers,
        devices: fetchedDevices,        
      };
    case FETCH_SERVERS_ERROR:
      return {
        ...state,
        pendingServers: false,
        error: action.error,
      };
      case FETCH_SERVERS_UPDATE:
      let updateWithSitesServers = []
         // Add servers from the 'NewServersList' array to the newArray
        action.payload.servers.forEach(newServer => {
        
          const existingServer = state.servers.find(server => server._id == newServer._id);
          if (existingServer) {
            Object.assign(existingServer, newServer);
            updateWithSitesServers.push(existingServer);
          } else {
            updateWithSitesServers.push(newServer);
          }
        
        });

    let fetchedSitesServersDevices = {};            

    let extSitesServers = updateWithSitesServers.map((server, index) => {
      return decorateServer(server, fetchedSitesServersDevices);

    });
    const updatedDevicesSiteInfo = { ...state.devices };
      for (const deviceId in updatedDevicesSiteInfo) {
        const deviceCodeName = updatedDevicesSiteInfo[deviceId]?.serverCodename;
        const matchedServer = extSitesServers.find((server) => server.codeName === deviceCodeName);
        if(matchedServer){
          updatedDevicesSiteInfo[deviceId].siteInfo = matchedServer.siteInfo;
        }else{
          delete updatedDevicesSiteInfo[deviceId];
        }
      }
  
      return {
        ...state,
        servers: extSitesServers,
        devices: updatedDevicesSiteInfo,
      }
    case EMPTY_PASSWORD_SERVER_UDPATE: 
    const updateServerPassword  = updateServersPassword(state.servers, action.payload.servers, action.payload.password)

    return{
      ...state,
      servers: updateServerPassword,
    }
    case SERVER_REMOVED:
    case DUPLICATE_SERIAL_SERVER_REMOVE:
      let filteredServers = state.servers.filter(
        (item) => item._id !== action.payload._id
      );
      let filteredDevices = {};
      filteredServers.forEach((server) => {
        updateDeviceList(server, filteredDevices);
      });

      return {
        ...state,
        pendingServers: false,
        error: action.error,
        deleted: action.payload,
        servers: filteredServers,
        devices: filteredDevices,
      };
    case SERVERS_REMOVED:
      let cleanedServers = state.servers.filter(
        (item) => action.payload.serverList.contains(item._id)
      );      
      let cleanedDevices = {};
      cleanedServers.forEach((server) => {
        updateDeviceList(server, cleanedDevices);
      });
      return {
        ...state,
        pendingServers: false,
        servers: cleanedDevices,
      }
    case FORCE_LOGIN:
      let serversForLogin = state.servers.map((server, index) => {
        if (server._id === action.payload._id) {
          return {
            ...server,
            needsRelogin: true,
          };
        }
        return server;
      });

      return {
        ...state,
        servers: serversForLogin
      }
    case STOP_LOGIN:
      let serversForStop = state.servers.map((server, index) => {
        if (server._id === action.payload._id) {
          return {
            ...server,
            needsRelogin: false
          }
        }
        return server;
      })

      return {
        ...state,
        servers: serversForStop
      }

    case FORCE_LOGIN_CODENAME:
      let serversForLoginCN = state.servers.map((server, index) => {
        if (server.codeName === action.payload.codeName) {
          return {
            ...server,
            needsRelogin: true,
          };
        }
        return server;
      });

      return {
        ...state,
        servers: serversForLoginCN,
      };

    case FORCE_LOGOUT:
      let serversForLogout = state.servers.map((server, index) => {
        if (server._id === action.payload._id) {
          return {
            ...server,
            needsLogout: true,
          };
        }
        return server;
      });

      return {
        ...state,
        servers: serversForLogout,
      };

    case FORCE_LOGOUT_CODENAME:
      let serversForLogoutCN = state.servers.map((server, index) => {
        if (server.codeName === action.payload.codeName) {
          return {
            ...server,
            needsLogout: true,
          };
        }
        return server;
      });

      return {
        ...state,
        servers: serversForLogoutCN,
      };

      case SERVER_DISCONNECT_ALL:
      let _filteredDisconnectedServers = state.servers.filter(
        (item) =>
          item.connectionStatus !== "not-connected"
      );
      let _filteredDisconnectedDevices = {};
      _filteredDisconnectedServers.forEach((server) => {
        updateDeviceList(server, _filteredDisconnectedDevices);
      });

      let _serversForDisconnection = state.servers.map((server, index) => {        
        //console.log(">>>>>> Server : " + server.name + " - Richiesta di disconnessione");
          return {
            ...server,
            channels: [],
            peripherals: [],
            status: "offline",
            eventsFetched: false,
            eventsPending: false,
            connectionStatus: "not-connected",
            capabilities: {},
            license: server.license,
            needsLogout: false            
          };                
      });

      //console.log(">>>>>> DISCONNECT: disconnecting start");      
      return {
        ...state,
        servers: _serversForDisconnection,
        devices: _filteredDisconnectedDevices,
        loginQueue: state.loginQueue.map((server) => {
          return {
            ...server,
            status: "disconnected",
          };
        }),
        isDisconnecting: true
      }
    case SERVER_DISCONNECT:
      let filteredDisconnectedServers = state.servers.filter(
        (item) =>
          item._id !== action.payload._id &&
          item.connectionStatus !== "not-connected"
      );
      let filteredDisconnectedDevices = {};
      filteredDisconnectedServers.forEach((server) => {
        updateDeviceList(server, filteredDisconnectedDevices);
      });

      let serversForDisconnection = state.servers.map((server, index) => {
        if (server._id === action.payload._id) {
          return {
            ...server,
            channels: [],
            peripherals: [],
            status: "offline",
            eventsFetched: false,
            eventsPending: false,
            connectionStatus: "not-connected",
            capabilities: {},
            license: server.license,
            needsLogout: false,
          };
        }
        return server;
      });

      return {
        ...state,
        servers: serversForDisconnection,
        devices: filteredDisconnectedDevices,
        loginQueue: state.loginQueue.map((server) => {
          if (server.codeName !== action.payload.codeName) return server;
          return {
            ...server,
            status: "disconnected",
          };
        })
      }
    case FORCE_LOGIN_ALL:
      let userServers = state.servers.map((server, index) => {
        if (server.owner === action.payload) {
          return {
            ...server,
            needsRelogin: true,
          };
        }
        return server;
      });

      return {
        ...state,
        servers: userServers,
      };
    case TAG_LAST_TIMESTAMP:
      let taggedServers;
      if (action.payload.length <= 0) {
        taggedServers = state.servers.map((server, index) => {
          return {
            ...server,
            lastEventTimestamp: 0,
          };
        });
      } else {
        let distinctValues = distinctTimes(action.payload);

        taggedServers = state.servers.map((server, index) => {
          if (!!distinctValues[server._id]) {
            logger(
              info,
              "serverReducer",
              "Last event timestamp for server " +
                server.name +
                ": " +
                server.lastEventTimestamp
            );
            return {
              ...server,
              lastEventTimestamp: distinctValues[server._id],
            };
          }
          return server;
        });
      }

      return {
        ...state,
        servers: taggedServers,
      };
    case CLEAN_DONE:
      let cleanServers = state.servers.map((server) => {
        if (server._id !== action.payload.serverId) return server;

        return {
          ...server,
          lastDelete: action.payload.lastDelete,
          lastDeleteCount: action.payload.deleteCount,
        };
      });

      return {
        ...state,
        servers: cleanServers,
      };
    case DEVICE_INFO:
      return {
        ...state,
        selectedDevice: action.payload.artecoId && getDeviceByArtecoId(state, action.payload.artecoId)
      }
    case DEVICE_INFO_RESET:
      return {
        ...state,
        selectedDevice: {},
      };

    case SERVER_TREE_TOGGLE:
      let serverTree = state.servers.map((server, index) => {
        if (server._id === action.payload.serverId) {
          return {
            ...server,
            serverTreeExpanded: action.payload.serverTreeExpanded,
            serverTreeExpandedRecursively:
              action.payload.serverTreeExpandedRecursively,
          };
        }
        return server;
      });

      return {
        ...state,
        servers: serverTree,
      };
    case SERVER_TREE_TOGGLE_ALL:
      let serverTreeToggled = state.servers.map((server, index) => {
        server.serverTreeExpanded = action.payload.serverTreeExpanded;
        server.serverTreeExpandedRecursively =
          action.payload.serverTreeExpandedRecursively;
        return server;
      });

      return {
        ...state,
        servers: serverTreeToggled,
      };

    case SET_SERVER_IS_LOCAL:
      let serverLocalCheck = state.servers.map((server, index) => {
        if (server._id === action.payload.serverId) {
          return {
            ...server,
            isLocal: action.payload.isLocal,
            offline: false,
          };
        }
        return server;
      });

      return {
        ...state,
        servers: serverLocalCheck,
      };

    case SET_SERVER_IS_OFFLINE:
      let serverOffline = state.servers.map((server, index) => {
        if (server._id === action.payload.serverId) {

          const accessToken = action.payload.offline ? 'expired' : (action.payload.access_token ? action.payload.access_token : server.access_token)
          
          return {
            ...server,
            access_token: accessToken,
            offline: action.payload.offline,
          };
        }
        return server;
      });

      return {
        ...state,
        servers: serverOffline,
      };
    case LINKED_PERIPHERALS:
      return {
        ...state,
        showLinkedPeripheralsPopupData: {
          ...state.showLinkedPeripheralsPopupData,
          isLinkedPeripheralPopupOpen: true,
          linkedPeripherals: action.payload,
        },
      };
    case CLOSE_LINKED_PERIPHERALS:
      return {
        ...state,
        showLinkedPeripheralsPopupData: {
          ...state.showLinkedPeripheralsPopupData,
          isLinkedPeripheralPopupOpen: false,
          linkedPeripherals: [],
        }
      }
    case FILTER_SERVERS_WITHOUT_LICENCE: 
    return {
      ...state,
      filterServersWithOutLicence: true,

    };
    case UPDATE_TIME_MISMATCH_SERVER:
      let timeMisMatchServers = state.servers.map((server, index) => {
        if (server._id === action.payload.serverId) {
          return {
            ...server,
            timeMismatch: action.payload.timeMismatch
          };
        }
        return server;
      });

      return {
        ...state,
        servers: timeMisMatchServers,
      };
    case LOGIN_REQUEST:
      //check if server is already in the login queue
      const serverInQueue = state.loginQueue.find((server) => server.codeName === action.payload.codeName);
      if (serverInQueue) return {
        ...state,
        loginQueue: state.loginQueue.map((server) => {
          if (server.codeName !== action.payload.codeName) return server;
          return {
            ...server,
            status: "connecting",
          };
        })
      };      
      
      //otherwise add it to the queue
      return {
        ...state,
        loginQueue: [...state.loginQueue, {codeName: action.payload.codeName, status: "connecting"}],
      };

    case ABORT_LOGIN:
      return {
        ...state,
        loginQueue: state.loginQueue.map((server) => {
          if (server.codeName !== action.payload.codeName) return server;
          return {
            ...server,
            status: "aborted",
          };
        })
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginQueue: state.loginQueue.map((server) => {
          if (server.codeName !== action.payload.codeName) return server;
          return {
            ...server,
            status: "connected",
          };
        })
      };
    default:
      return state;
  }
}

function serverIsLoggedOn (server) {
  return server.connectionStatus === 'connected' || server.connectionStatus === 'cached'
}

export function serverIsLoggingIn (server) {
  return server.connectionStatus === 'connecting';
}

export const getServers = state => state.servers;
export const getSites = state => {
  const sites = state.servers.sites;
  return sites;
}
export const getfilterServersWithOutLicence = state => state.servers.filterServersWithOutLicence;
export const serversWithoutLicense = state => state.servers.servers.filter((server) => {
  return !server.license && !isArtecoSerial(server.ip.replace(appPaths.PUBLIC_HOST, ""))
});
export const getConnectedServers = (state, includeConnecting = false) => {  
  const servers = state.servers.servers.filter(server => (serverIsLoggedOn(server) || (includeConnecting && serverIsLoggingIn(server))));
  return servers;
}
export const getAttachedServers = state => {
  const servers = state.servers.servers.filter(server => (serverIsLoggedOn(server) && server.socketAttached));
  return servers;
} 

export const shouldEnableNewEventFilter = (state) => {
    const servers = getConnectedServers(state)
    if (servers.length === 0) {
      return false;
    }
    // check supportedCategories for all servers
    return servers.every(server => server.capabilities?.supportedCategories);
    // return false;
}

export const getServersByCodenamesList = (state, codenamesList) => {
  return state.servers.servers.filter(server => codenamesList.includes(server.codeName));
}
export const getConnectedServersCodenames = (state, includeConnecting = false) => {

  const connectedServers = getConnectedServers(state, includeConnecting);

  return connectedServers.map(server => server.codeName);
}
export const getActiveLayoutServers = (state, activeLayout, includePeripherals = true) => {

  const serverCodenames = includePeripherals ?
    Array.from(new Set([...activeLayout.channels.map(channel => channel.serverCodename), ...activeLayout.peripherals.map(peripheral => peripheral.serverCodename)]))
    :
    Array.from(new Set(activeLayout.channels.map(channel => channel.serverCodename)));

  const activeLayoutConnectedServer = state.servers.servers.filter(server => server.connectionStatus !== 'not-connected' && serverCodenames.includes(server.codeName));
  return activeLayoutConnectedServer;
}
export const getAllDevices = state => state.servers.devices;

export const getAllDevicesIds = state => Object.keys(getAllDevices(state));

export const getIdsByCodenames = (state, codenames) => {
  const allDevices = getAllDevices(state);

  return allDevices.filter(device => codenames.includes(device.artecoId)).map(device => device._id);
}

//queries deviceList by regex
export const queryDevices = (state, query) => {
  if (!query) return [];

  let deviceIdList = Object.keys(state.servers.devices).filter((q) => query.test(q));

  if (deviceIdList.length === 0) return [];

  let deviceList = [];

  deviceIdList.forEach(id => {
    deviceList.push(state.servers.devices[id]);
  })


  return deviceList
}

//returns all channels
export const getAllChannels = state => {
  if (JSON.stringify(state.servers.devices) === '{}') return [];

  const query = new RegExp(CAMERA_REGEX, 'i');
  return queryDevices(state, query);
}

//returns all peripherals
export const getAllPeripherals = state => {
  if (JSON.stringify(state.servers.devices) === '{}') return [];

  const query = new RegExp(PERIPHERAL_REGEX, 'i');
  return queryDevices(state, query);
}

//returns all devices on a single server by serverCodeName
export const getAllDevicesByServer = (state, serverCodename) => {
  if (!serverCodename) return [];
  if (JSON.stringify(state.servers.devices) === '{}') return [];

  const query = new RegExp(serverCodename + '_', 'i');
  return queryDevices(state, query);
}

//returns all server's channels artecoId by serverCodeName
export const getAllChannelsByServer = (state, serverCodename) => {
  if (!serverCodename) return [];
  if (JSON.stringify(state.servers.devices) === '{}') return [];

  const query = new RegExp(serverCodename + CAMERA_REGEX, 'i');
  return queryDevices(state, query);
}

//returns all server's peripherals artecoId by serverCodeName
export const getAllPeripheralsByServer = (state, serverCodename) => {
  if (!serverCodename) return [];
  if (JSON.stringify(state.servers.devices) === '{}') return [];

  const query = new RegExp(serverCodename + PERIPHERAL_REGEX, 'i');
  return queryDevices(state, query);
}

//returns 2 lists (channels, peripherals)
export const splitDevices = state => {
  return {
    'channels': getAllChannels(state),
    'peripherals': getAllPeripherals(state),
  }
}

//returns 2 lists (channels, peripherals) for a single server
export const splitDevicesByServer = (state, serverCodename) => {
  return {
    'channels': getAllChannelsByServer(state, serverCodename),
    'peripherals': getAllPeripheralsByServer(state, serverCodename),
  }
}


//get deviceData by artecoId
export const getDeviceByArtecoId = (state, artecoId) => {

  //check if is a server!
  const query = new RegExp('_[1|0]_', 'i');
  const isServer = !(query.test(artecoId));

  if (isServer) {
    return getServerByCodeName(state, artecoId)
  }

  const where2Find = state.servers.devices ? state.servers.devices : state.devices;
  if (!!where2Find[artecoId] === false) {
    //debugger;
  }
  
  if(where2Find[artecoId]) {
    return {
      ...where2Find[artecoId],     
      parentServer: getParentServerByArtecoId(state, artecoId)
    }
  } else {
    return where2Find[artecoId]
  }
  
}

export const getDeviceByArtecoIdIfConnected = (state, artecoId) => {
  const server = getParentServerByArtecoId(state, artecoId);

  if (!server || server.connectionStatus === 'not-connected') return undefined;

  return getDeviceByArtecoId(state, artecoId);
}

//get server by child artecoId
export const getParentServerByArtecoId = (state, artecoId) => {

  //check if is a server!
  const query = new RegExp('_[1|0]_', 'i');
  const isServer = !(query.test(artecoId));

  if (isServer) {
    return getServerByCodeName(state, artecoId)
  }

  const serverCodename = artecoId.split("_")[0];

  return getServerByCodeName(state, serverCodename);
}

//get deviceDataArray by minimal array
// minimal array: [{artecoId, serverCodename, id, type}]
export const getDevicesByArtecoIdList = (state, artecoIdList) => {
  if (!Array.isArray(artecoIdList) || artecoIdList.length <= 0) return [];

  const deviceDataList = artecoIdList.map(device => {
    return getDeviceByArtecoId(state, device.artecoId);
  })

  //strip undefined devices from list (devices from a not-connected server)  
  return deviceDataList.filter(device => device !== undefined);
}
// get device linked Peripherals
export const getDeviceLinkedPeripherals = (device, server) => {
  const linkedPeripheralsId = device?.['linked-peripherals']?.length > 0 ? device?.['linked-peripherals']?.map(li => li?.id) : [];

  const linkedPeripherals = []
  server?.peripherals?.forEach(p => {
    if (linkedPeripheralsId?.includes(p.id)) {
      linkedPeripherals.push({
        artecoId: p.artecoId,
        id: p.id,
        serverCodename: p.serverCodename,
        type: p.type,
        visiblePins: [],
      });
    } else {
      const newPins = p?.pins?.filter(pin => linkedPeripheralsId?.includes(pin.id))

      if (newPins?.length > 0) {
        linkedPeripherals.push({
          artecoId: p.artecoId,
          id: p.id,
          serverCodename: p.serverCodename,
          type: p.type,
          visiblePins: newPins
        })
      }
    }
  })
  return linkedPeripherals;

}

export const getCodenameByArtecoId = (artecoId) => {
  const query = new RegExp('_[1|0]_', 'i');
  const isServer = !(query.test(artecoId));
  if (isServer) return artecoId;

  const codeName = artecoId.split(query);

  return codeName[0];
}


//get deviceType by artecoId
export const getDeviceTypeByArtecoId = (artecoId) => {
  var extract = artecoId.match(/_(.*)_/).pop();
  return parseInt(extract);
}

export const getSelectedDevice = state => state.servers.selectedDevice;

export const getServersCodenames = state => {
  let codenames = [];
  state.servers.servers.map((server, index) => {
    if(server.codeName) {
      codenames.push(server.codeName);
    }

    return false;
  });

  return codenames;
}
export const getServersPending = state => state.servers.pendingServers;
export const getSitesPending = state => state.servers.pendingSites;
export const getServersError = state => state.error;
export const getServerById = (state, _id) => {
  if (!_id) return {}

  return state.servers.servers ? state.servers.servers.find(server => server._id === _id) : state.servers.find(server => server._id === _id);
};

export const getServerByCodeName = (state, codeName) => {
  if (!codeName) return {}

  const query = new RegExp('_2_', 'i');
  const isArtecoId = query.test(codeName);

  let needle = codeName
  if(isArtecoId) {
    needle = codeName.split("_")[0];
  }

  const where2Find = state.servers.servers ? state.servers.servers : state.servers;
  return where2Find.find(server => server.codeName === needle);
}

function countUnderscores(str) {
  // Trova tutti gli underscore nella stringa
  const matches = str.match(/_/g);

  // Se ci sono underscore, restituisce il loro numero, altrimenti 0
  return matches ? matches.length : 0;
}

export const retrieveArtecoId = (state, candidateMatch, sendingServer) => {
  /*
  related camera types
  [SERVER_SERIAL_NUMNER]_[CAMERA_ID]
  [SERVER_CODE_NAME]_[DEVICE_TYPE]_[CAMERA_ID] (aka ArtecoId)
  [CAMERA_ID]
  */

  const candidateType = countUnderscores(candidateMatch);
  let cameraId = '';  
  const params = candidateMatch.split("_");
  let artecoId = null;
  let parentServer = null;
  switch (candidateType) {
    case 1:
      const serialNumer = params[0];
      cameraId = params[1];
      parentServer = getServerBySerialNumber(state, serialNumer);
      artecoId = parentServer ? `${parentServer.codeName}_${ARTECO_CAM_CODE}_${cameraId}` : null;
      break;
    case 2:
      const serverCodename = params[0];
      const deviceType = params[1];
      cameraId = params[2];
      parentServer = getServerByCodeName(state, serverCodename);
      artecoId = parentServer ? `${serverCodename}_${deviceType}_${cameraId}` : null;
      break;
    default:
      cameraId = params[0];
      if(sendingServer) {
        parentServer = sendingServer;
        artecoId = `${sendingServer.codeName}_${ARTECO_CAM_CODE}_${cameraId}`
      }
      break;
  }

  return {
    artecoId,
    parentServer,
    cameraId
  }
}

export const getRelatedCamera = (state, candidateMatch, sendingServer) => {
  if(!candidateMatch) return {};

  const {artecoId, parentServer, cameraId} = retrieveArtecoId(state, candidateMatch, sendingServer);

  if(!artecoId) return {};
  
  if(parentServer && !isConnected(parentServer)) {
    //if the server is not connected, output special info to allow auto login
    return {
      parentServer: parentServer,
      id: cameraId,
      outcome: 'found-offline'
    }
  }

  const relatedCamera = getDeviceByArtecoId(state, artecoId);
  if(relatedCamera) {
    return {
      relatedCamera,
      outcome: 'found'
    }
  }
  return {
    outcome: 'not-found'
  }
}

export const getServerBySerialNumber = (state, serialNumber) => {
  if (!serialNumber) return {};
  
  const isArtecoSerialNumber = isArtecoSerial(serialNumber);

  if(!isArtecoSerialNumber) return {};

  const where2Find = state.servers.servers ? state.servers.servers : state.servers;
  return where2Find.find(server => server.license?.serial?.toUpperCase() === serialNumber.toUpperCase());
};


export const connectedServerByCodename = (state, codeName) => {
  if (!codeName) return {};
  const servers = state.servers.servers ? state.servers.servers : state.servers;
  const connectedServer = servers.filter(server => { return server.status === "online" });
  // const filteredServer = connectedServer.find(server => { return server.codeName === codeName });

  return connectedServer.find(server => { return server.codeName === codeName })
}

export const getServerNameByCodeName = (state, codeName) => {
  if (!codeName) return {}
  const server = getServerByCodeName(state, codeName);
  return server.name;
}

export const getMediaSecretByCodename = (state, codeName) => {
  if (!codeName) return {}
  const server = getServerByCodeName(state, codeName);
  return server.MediaSecret;
}

export const getMediaSecrets = (state) => {
  const secrets = [];
  state.servers.servers.map(server => secrets[server.codeName] = server.MediaSecret);
  return secrets;
}

export const getMediaUri = (state, codeName, mediaUri) => { // for HLS stream
  if (!codeName) return "";
  const server = getServerByCodeName(state, codeName);
  if (!server) return undefined

  //MALEDETTI
  if(mediaUri.includes("none")) return "";

  const lanIp = getLanAddress(mediaUri);
  const MediaPath = (server.isLocal) ? lanIp : mediaUri;

  //ready for Nginx
  //return server.nodeServer ? `${MediaPath}` : `${MediaPath}?MediaSecret=${server.MediaSecret}`;
  return MediaPath ? `${MediaPath}?MediaSecret=${server.MediaSecret}` : '';

}


export const getMapBackgroundUri = (state, codeName, mediaUri) => {
  if (!codeName) return "";
  const server = connectedServerByCodename(state, codeName);
  if (!server) return undefined;
  const lanIp = getLanAddress(mediaUri);
  const MediaPath = (server.isLocal) ? lanIp : mediaUri;
  return (server) ? `${MediaPath}?MediaSecret=${server.MediaSecret}` : undefined;
}


/**
 * @deprecated Since version 30/04. Will be deleted soon. Use getDeviceByArtecoId instead.
 */
export const getDeviceByIds = (state, serverCodename, deviceId, type) => {

  const server = getServerByCodeName(state, serverCodename);

  if (!server) return {}

  const whereToSearch = type === 1 ? server.channels : server.peripherals;

  let device = whereToSearch.find(device => device.id === deviceId);

  // logger("[getDeviceByIds] serverCodename: " + serverCodename + ", deviceId: " + deviceId + ", type: " + type);

  return device || {};

}

export const checkIfNeed2Login = (state, _id) => {
  if (!_id) return false;

  let checkServer = {};
  state.servers.servers.forEach(server => {
    if (server._id === _id) {
      checkServer = {
        ...server
      }
    }
  })

  return checkServer.needsRelogin ? true : false;
};

export const checkIfNeed2Logout = (state, _id) => {
  if (!_id) return false;

  let checkServer = {};
  state.servers.servers.forEach(server => {
    if (server._id === _id) {
      checkServer = {
        ...server
      }
    }
  });

  return checkServer.needsLogout ? true : false;
};

// export const checkIfNeed2Login = (state, _id) => {
//   if (!_id) return false;

//   let server = state.servers.servers.find(server => server._id === _id);

//   return !!server.needsRelogin;
// };

/**
 * @deprecated Since version 30/04. Will be deleted soon. Use getAllDevices instead.
 */
export const getServersDevices = (state) => {

  const listOfAllDevices = [];

  state.servers.servers.map(server => {

    server.channels.map(channel => {

      listOfAllDevices.push({
        label: `${server.name} : ${channel.descr}`,
        value: channel,
        server: server
      })

      return null;

    })

    server.peripherals.map((peripheral) => {

      listOfAllDevices.push({
        label: `${server.name} : ${peripheral.descr}`,
        value: peripheral,
        server: server
      })

      return null;

    })



    return null;

  })

  return listOfAllDevices;

}

export const getSiteExpandedState = (state, site, sitesNum) => {
  if (!site || site.length <= 0) {
    return false;
  }

  //if just one site, open at landing AST-4963
  if(sitesNum === 1) {
    return true;
  }

  let isExpanded = false;

  site.map(server => {
    const serverExpandedState = getServerTreeExpandedState(state, server._id);
    if(serverExpandedState.serverTreeExpanded) isExpanded = true;
  })

  return isExpanded;

}

export const getServerTreeExpandedState = (state, serverId) => {
  const server = getServerById(state, serverId);

  return {
    serverTreeExpanded: !!server.serverTreeExpanded,
    serverTreeExpandedRecursively: !!server.serverTreeExpandedRecursively,
  }
}

export const getSessionIds = (state) => {


  var dictOfSession = {};

  state.servers.servers.forEach(server => {

    dictOfSession[server._id] = {
      sessionId: server.sessionId,
      serverCodename: server.codeName,
      serverName: server.name,
      _id: server._id
    };

  });

  return dictOfSession;

}


export const serverIsLocal = (state, serverId) => {
  return state.servers.find(server => server._id === serverId).isLocal;
}

export const getServerEventsPending = (state) => {
  var check = state.servers.servers.map((el) => { return el.eventsPending ? 1 : 0 })
  if (!Array.isArray(check) || check.length === 0) return 0;

  var hasPending = check.reduce((a, b) => a + b);

  return hasPending;
}

export const getServerFetchesComplete = (state) => {
  var check = state.servers.servers.map((el) => { return el.eventsFetched ? 1 : 0 })
  if (!Array.isArray(check) || check.length === 0) return 0;

  var hasPending = check.reduce((a, b) => a + b);

  return hasPending;
}

export const amIInited = (state, artecoId, childId = null) => {
  if (!artecoId) return 0;

  const device = state.servers.devices[artecoId];
  if (!device) return 0;

  if (childId) {
    if (!device.pins) return 0;
    return device.pins.find(pin => pin.id === childId).init || 0;
  }

  return device.init || 0;
}

export const amIUpdated = (state, artecoId) => {
  if (!artecoId) return 0;

  const device = state.servers.devices[artecoId];
  if (!device) return 0;

  return device.updated || 0;
}

export const getMystatus = (state, artecoId, childId = null) => {
  if (!artecoId) return 0;

  const device = state.servers.devices[artecoId];
  if (!device) return 0;

  if (childId) {
    return device.pins.find(pin => pin.id === childId).status || 0;
  }

  return device.status || 0;
}

export const getPTZstatus = (state, artecoId) => {
  if (!artecoId) return 0;

  const device = state.servers.devices[artecoId];
  if (!device) return 0;

  return device.ptzStatus || {
    ctx: '',
    sequenceStatus: '',
    sequenceId: '',
    presetStatus: '',
    presetId: '',
    ptzStatus: '',
    action: '',
    requestedAction: '',
  };
}

export const getPTZPresets = (state, artecoId) => {
  if (!artecoId) return 0;

  const device = state.servers.devices[artecoId];
  if (!device) return 0;

  return device.presets || [];
}

export const getPTZSequences = (state, artecoId) => {
  if (!artecoId) return 0;

  const device = state.servers.devices[artecoId];
  if (!device) return 0;

  return device.sequences || [];
}

export const isLinkedPeripheralsShow = (state) => {
  return state.servers.showLinkedPeripheralsPopupData?.isLinkedPeripheralPopupOpen ? state.servers.showLinkedPeripheralsPopupData.isLinkedPeripheralPopupOpen : false;
}

export const getLinkedPeripherals = (state, serverCodenames, servers) => state.servers.showLinkedPeripheralsPopupData.linkedPeripherals.filter(peripheral => peripheralIsPresent(serverCodenames, servers, peripheral));

// new channel
export const getNewChannel = (state) => state.servers?.addNewChannel ? state.servers.addNewChannel : {}
export const getPrivacyData = (state, artecoId) => {
  if (!artecoId) return 0;

  const device = state.servers.devices[artecoId];
  if (!device) return 0;

  return device.privacyData || {};
}

export const OmniaLight = (state) => {
  
  const servers = state.servers.servers;
  for (let i = 0; i < servers.length; i++) {
    if (servers[i].licenseType && (servers[i].licenseType !== 'subscription' && servers[i].licenseType !== 'demo')) {
      return true;
    }
  }

  return false;
}

export const serversNotAssignedToSites = (state) => {
  const servers = getServers(state);
  const unmatchedServers = servers.servers.filter(server => !server.siteInfo);

  return unmatchedServers || [];
}

export const  serversWithEmptyPassword = (state) => {
  const serversWithEmptyPasswords = state.servers.servers.filter(server => {
    return !server.password || server.password.trim() === '';
  });

  return serversWithEmptyPasswords;
}

export const isCredentialEnabled = (credential, server) => {
  if(!server){
    return false;
  }
  if(!server?.siteInfo){
    return true
  }
  if (!server.siteInfo.credentials) {
    return false; 
  }
  return !!server.siteInfo.credentials[credential];
}

export const getGroupedSites = (state) => {
  const servers = getServers(state);

  const sites = servers.servers && servers.servers.length > 0 && groupBySitename(servers.servers, 'site_name');

  return sites || [];
}

export const getSiteInfoList = (state) => {
  const sitesServers = getGroupedSites(state)
  return  (
    Object.values(sitesServers).map((servers) => (
      servers.length > 0 ? servers[0].siteInfo : null
    ))
  )
};

export function checkCamerasServerCredentials(channels, servers, credentials) {
  const matchingServers = servers.filter(server => {
    return channels.some(channel => channel.serverCodename === server.codeName);
  });

  return matchingServers.some(server => isCredentialEnabled(credentials, server));
}

export function CamerasServerIsNotCredentials(channels, servers, credentials) {
  const matchingServers = servers.filter(server => {
    return channels.some(channel => channel.camera.serverCodename === server.codeName);
  });

  return matchingServers.some(server => !isCredentialEnabled(credentials, server));
}

export function hasServerAuditingSearchCapability(servers) {
  return servers.some((server) => {
    return (
      server.capabilities?.canControlAuditing == 1 &&
      server.siteInfo?.credentials["Auditing-Search"] == 1
    );
  });
}

// export function hasNoServerAuditingControlCapability(servers) {
//   return servers.some((server) => {
//     return server.capabilities ? server.capabilities?.canControlAuditing === 0 : false  ;
//   });
// }
export function hasServerWithAuditingCapability(servers) {
  for (const server of servers) {
    if (server.capabilities && server.capabilities.canControlAuditing === 1) {
      return false; // Found a server with canControlAuditing === 1
    }
  }
  return true; // No server with canControlAuditing === 1 was found
}

export function hasNoServerAuditingUserCredential(servers) {
  return servers.some((server) => {
    return server.siteInfo?.credentials["Auditing-Search"] == 0;
  });
}


export function auditingInitialText(servers, t) {
  if (servers.length === 0) {
    return "";
  }

  if (hasServerAuditingSearchCapability(servers)) {
    return <div className='intianl-text'>{t("AUDITING_SEARCH")}</div>;
  }

  if (hasServerWithAuditingCapability(servers)) {
    return <div className='intianl-text'>{t("SERVERS_NOT_HAVE_AUDITING_CAPABILITY")}</div>;
  }

  if (hasNoServerAuditingUserCredential(servers)) {
    return <div className='intianl-text'>{t("SERVERS_NOT_HAVE_AUDITING_USER_CREDENTIAL")}</div>;
  }

  return "";
}

function groupBySitename(objectArray, property, sortOrder = 'asc') {
  const result = objectArray.reduce((acc, obj) => {
    const key = obj.siteInfo && obj.siteInfo[property];

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(obj);
    return acc;
  }, {});

  // Sorting the keys case-insensitive
  const sortedKeys = Object.keys(result).sort((a, b) => {
    return sortOrder === 'asc' ? a.localeCompare(b, undefined, { sensitivity: 'base' }) : b.localeCompare(a, undefined, { sensitivity: 'base' });
  });

  // Creating a new object with sorted keys
  const sortedResult = sortedKeys.reduce((acc, key) => {
    acc[key] = result[key];
    return acc;
  }, {});

  return sortedResult;
}

export const getChannelRunningStatus  = (serverCodeName,artecoId) =>  {

  const offlineOnlineChannelsString = localStorage.getItem("camerasOnlineOfflineStatus");
  const offlineOnlineChannels = offlineOnlineChannelsString ? JSON.parse(offlineOnlineChannelsString) : {};
  if (offlineOnlineChannels[serverCodeName] && offlineOnlineChannels[serverCodeName].hasOwnProperty(artecoId)) {
    return offlineOnlineChannels[serverCodeName][artecoId];
  } else {
    return null;
  }
}

export const someChannelsHaveMissingProfiles = (server) => {
  return server.channels.some(channel => !channel.profiles);
};

export const  isServerInSyncServers = (serverCodeName) => {
  const storedServers = localStorage.getItem('syncServers');
  
  if (storedServers) {
    const syncServers = JSON.parse(storedServers);
    return syncServers.includes(serverCodeName);
  }
  
  return false;
}

export const getSyncServers = () => {
  const storedServers = localStorage.getItem('syncServers');
  return storedServers ? JSON.parse(storedServers) : [];
}

export const getLoginQueue = (state) => state.servers.loginQueue;

export const socketsAttached = (state) => {
  return state.servers.servers.filter(server => server.socketAttached);
}

export const getNumSocketsAttached = (state) => {
  return socketsAttached(state)?.length; 
}

export const isDisconnectingAll = (state) => state.servers.isDisconnecting;

export const getChannelsWithParentServerCapabilities = (state, channels) => {
  if (!channels || channels.length === 0) return [];

  return channels.map(channel => {
    const parentServer = getServerByCodeName(state, channel.serverCodename);
    return {
      ...channel,
      parentServerCapabilities: parentServer.capabilities,
      //streamMode: parentServer.capabilities?.rawStream ? 'webrtc' : channel.streamMode,
      streamMode: channel.streamMode //lo lascio qua per quando si decideranno finalmente a fare il grande passo
    };
  });
}


