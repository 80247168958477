import React, { useState, useEffect } from 'react';
import { Maximize2 as FullscreenIcon } from 'react-feather';
import { Minimize2 } from 'react-feather';
import { ExternalLink as PipIcon } from "react-feather";
import { ReactComponent as TimerOutline } from '../../components/svgIcons/biHistory.svg';
import { getCurrentServerTime, intervalOperations } from '../../helpers/timeHelpers';
import { setChannelMode, setCameraInFullscreen } from '../../actions/layoutActions';
import { useDispatch, useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { isCredentialEnabled } from '../../reducers/serversReducer';
import { InstantPlayer } from '../../helpers/serverCredentials';

export default function PlayerControls({ videoContainer, artecoId, isFisheye, device }) {
  const dispatch = useDispatch();

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPip, setIsPip] = useState(false);
  const [ipOpen, setIpOpen] = useState(false);
  
  const canUseInstantPlayer = useSelector(state => isCredentialEnabled(InstantPlayer, device));

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
    dispatch(setCameraInFullscreen(isFullscreen? null : artecoId));
  };

  const handleInstantPlayerToggle = () => {
    setIpOpen(!ipOpen);
  };

  const handlePipToggle = async () => {
    const videoElement = videoContainer.querySelector('.raw-player');
    if (isPip) {
      document.exitPictureInPicture();
    } else {
      try {
        await videoElement.requestPictureInPicture();
      } catch (error) {
        console.error('Error entering Picture-in-Picture mode:', error);
      }
    }
  };

  const setInstant = (e) => {
    const element = e.target.closest('button');
    const seconds = element.dataset.seconds;

    const Minutes = Math.floor(seconds / 60);
    const Seconds = seconds % 60;

    const mode = {
      mode: `instant`,
      from: intervalOperations(getCurrentServerTime(), `00:${Math.floor(Minutes).toString().padStart(2, '0')}:${Math.floor(Seconds).toString().padStart(2, '0')}`, 'subtract'),
      to: getCurrentServerTime()
    }

    dispatch(setChannelMode(mode, artecoId));

  }
  
  useEffect(() => {
    if (isFullscreen) {
      videoContainer.closest('.arteco-player').classList.add('custom-fullscreen');
    } else {
      videoContainer.closest('.arteco-player').classList.remove('custom-fullscreen');
    }
  }, [isFullscreen, videoContainer]);

  useEffect(() => {
    const videoElement = videoContainer.querySelector('.raw-player');
    const handlePipChange = () => {
      setIsPip(document.pictureInPictureElement === videoElement);
    };

    videoElement.addEventListener('enterpictureinpicture', handlePipChange);
    videoElement.addEventListener('leavepictureinpicture', handlePipChange);

    return () => {
      videoElement.removeEventListener('enterpictureinpicture', handlePipChange);
      videoElement.removeEventListener('leavepictureinpicture', handlePipChange);
    };
  }, [videoContainer]);

  
  return (
    <div className='player-controls'>
      <div className={`instant-player-menu player-controls__button`}>
        {
          !!canUseInstantPlayer && (
            <>            
              <OutsideClickHandler onOutsideClick={() => {
                if (ipOpen) {
                  setIpOpen(false);
                }
              }}
              >
                <button className={`player-controls__button ip-button ${ipOpen ? 'open' : ''}`} onClick={handleInstantPlayerToggle}>
                  <TimerOutline />          
                </button> 

                <div className={`instant-player-options ${ipOpen ? 'open' : ''}`}>
                  <button onClick={setInstant} type="button" className='media-control-button media-control-icon set-instant' data-seconds="1800"  aria-label="instant player 30'">
                    <span>30'</span>
                  </button>
                  <button onClick={setInstant} type="button" className='media-control-button media-control-icon set-instant' data-seconds="300" aria-label="instant player 10'">
                    <span>5'</span>
                  </button>
                  <button onClick={setInstant} type="button" className='media-control-button media-control-icon set-instant' data-seconds="60" aria-label="instant player 5'">
                    <span>1'</span>
                  </button>
                  <button onClick={setInstant} type="button" className='media-control-button media-control-icon set-instant' data-seconds="30"  aria-label="instant player 5'">
                    <span>30''</span>
                  </button>
                  <button onClick={setInstant} type="button" className='media-control-button media-control-icon set-instant' data-seconds="15"  aria-label="instant player 5'">
                    <span>15''</span>
                  </button>
                </div>
              </OutsideClickHandler>
            </>
          )
        }
      </div>
      {/* <button className='player-controls__button profiles-selector'>
        <span className='icon'></span>
      </button> */}
      {
        !isFisheye && (          
          <button className='player-controls__button pip-button' onClick={handlePipToggle}>
            <PipIcon />
          </button>
        )
      }
      <button className='player-controls__button fs-button' onClick={handleFullscreenToggle}>
        {isFullscreen ? <Minimize2 /> : <FullscreenIcon />}
      </button>            
    </div>
  );
}